import React, {useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams, /*useNavigate*/ } from 'react-router-dom';
import { strings } from "./../../services/Localization";
import { checkLogin, SignInForward } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import PageContent from "../PageContent";
// import { dialog } from '../../components/Common';
import { formatDeveui, deviceStatusSymbol, deviceStatusTooltip, dateTimeString } from '../../utils/filters';
import { IDevice, IUser } from "src/dassTypes";
import { appBaseUrl, getAppBase, nstBaseUrl } from '../../utils/consts';
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { faRefresh }  from '@fortawesome/pro-solid-svg-icons';
import{ DEFAULT_RECORD_LIMIT, DEVEUI_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { ActionType, ColumnType, DataTableOption } from "src/components/Common/DataTable/DataTypes";

type IRowType = IDevice;
declare const constants;


interface IAdminDevicesState {
    loggedUser: IUser;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh: boolean;

}

const AdminDevices: React.FC<{}> = () =>  {

    const location = useLocation();
    const isNst = String(location.pathname).indexOf('/nst') >= 0;
    const baseUrl = isNst ? nstBaseUrl : appBaseUrl;
    const navigate = useNavigate();
    const AppContextObj =  useContext(AppContext);

    let { id, tabname } = useParams();
   
    const stateInit = {
        loggedUser: null,
        editUserId:'',
        pageTitle: strings.FIND_DEVICES_TITLE,
        showAlertModal: false,
        breadCrumbArr: [{label:strings.FIND, url:''},{label:strings.NAV_ADMIN_DEVICES, url:''}],
        refresh:false
    };
    
    const[state, setState] = useState<IAdminDevicesState>(stateInit)
    
    // const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {

            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            }else {
                window.location.href = appBaseUrl + "/signin";
            }
        }
    },[])


    
    const signInForward = async (userid: string) => {
        try {
            const response = await SignInForward(userid, baseUrl)
            AppContextObj.updateUser(response);
            AppContextObj.setNavBarState(prevState => {
                return {...prevState, forwardedSignIn: true, originalUser: AppContextObj.oldUser.userid}
            })
            return response;
        } catch (e)  {
            console.log(e.message);
        }
	};
    
    const getActions = () => {
        // const user = AppContextObj.user;

        const actions: ActionType<IRowType>[] = [
            {
                type: "action",
                text: "Impersonate owner",
                icon: faArrowRight ,
                action: (device) => signInForward(device.ownerid),
                visible: (device) => AppContextObj.user.userid != device?.ownerid ? true : false
            }
        ]

        return actions;
    }

    const detailPageNav = async ( navigate, tab, deveui, row) => {

        const prevPageUrl =  String(location.pathname);
        if (AppContextObj.user.userid !== row.ownerid) {
                // Here as per the implementation when user is able to find device he will able to impersonate too
                signInForward(row.ownerid).then((success) => {
                    if(success){
                        navigate(`${getAppBase()}/my-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl}})
                    }
                })
            // }
        } else {
            navigate(`${getAppBase()}/my-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl}})
        }
        
    }

    const getTableOptions = () => {
		
        const actions = getActions();

		const columns: ColumnType<IRowType>[] = [
            {
                key: "deveui",
                type: "text",
                title: strings.DEVEUI,
                filterable: true,
                inputValidation:DEVEUI_INPUT_VALIDATION,
                filterField: "search_deveui",
                filterType: "text",
                newCellWidth: "225px",
                filterParams: {
                    mapper: (x) => x && x.replace(new RegExp("-|:", 'g'), "")
                },
                sortable: false,
                sortKey: "sort_by_deveui",
                copyLink: true,
                render: (x) => formatDeveui(x.deveui)
            }, {
                key: "device_status",
                title: strings.STATUS,
                type: "icon_with_tooltip",
                filterable: false,
                newCellWidth: "100px",
                render_icon: (row) => deviceStatusSymbol(row),
                render_tooltip: (row) => deviceStatusTooltip(row),                dataAlign:'center',
            }, {
                key: "comment",
                title: strings.COMMENT,
                detailPageNav: (row) =>  detailPageNav(navigate, "device-detail", row.deveui, row ),
                type: "text",
                filterable: false,
                detailLink: true,
                filterField: "search_comment",
                filterType: "text",
                sortable: false,
                sortKey: "sort_by_comment",
                filterParams: {
                    mapper: (x) => x || undefined
                },
                newCellWidth: "400px",
            }, {
                key: "last_reception",
                title: strings.LAST_SEEN,
                type: "text",
                render: x => dateTimeString(x.last_reception),
                cellWidth: 34,
                customClass: 'font-monospace nowarp',
                newCellWidth: "200px",
    
            }, {
                key: "ownerid",
                title: strings.OWNER,
                type: "text"
            }
        ];

        const options: DataTableOption<IRowType> = {
            url: '/uiapi/rest/nodes',
            query_param: {all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', search_all_customers: true, search_all_tenants:true, search_all_users:true},
            serial_number: false,
            id_field: 'deveui',
            oboe_path: 'pages.*',
            available_key: 'deveui',
            columns,
            isDisplayMobile:true,            
            actions,
        }

        return options;
	}

    const refreshTable = () => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
        
    }
    
    const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
        ]

        return pageButtons;
    }

    if (constants.enable_profiles !== true) {
        return null;
    }else {
        return (<PageContent
            name={`admin-devices`} 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Devices`} 
            dataTableOption={getTableOptions()} 
            refresh={state.refresh}>
        </PageContent>)
      
    }   
        
}


export default AdminDevices;