import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import MainState from "./context/MainState";

import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./style/orbiwise/style-old.css";
//import "./style/orbiwise/dassui.css";
import "./style/orbiwise/style.css";

ReactDOM.render(
  <React.StrictMode>
     <MainState>
       <BrowserRouter>
        <App />
      </BrowserRouter>
    </MainState>
  </React.StrictMode>,
  document.getElementById("root"),
);
