import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
//import { Outlet  } from 'react-router-dom';

import { faRefresh, faAdd }  from '@fortawesome/free-solid-svg-icons';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog, /*DataTable, PageHeader, DetailPageContainer, , DataTableWrapper*/ } from '../../components/Common';

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";

import { TAB_TAG_INFO, TAB_TAG_ADD, TAB_TAG_CATEGORY_ADD, TAB_TAG_CATEGORY_DELETE } from '../../datatypes/tabsconstants'

//import { AlertModal } from "./../../components/Modals/AlertModal";

import { appBaseUrl, getAppBase } from '../../utils/consts';

import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";

import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import PageContent from "../PageContent";
declare const constants;


interface ITag {
    tagName: string;
    description: string;
    tenantId:string;
    tagid: number;
    color: string;
    category: {
        name: string;
        tenantId: string;
        tagcategoryid: number;
    };
}




type FilterOptionType = {
    label: string;
    value: string;
}

interface IGatewayTagsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    categoryEnum: FilterOptionType[];
}

interface IGatewayTagsProps {
}




const GatewayTags: React.FC<IGatewayTagsProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);



    const stateInit: IGatewayTagsStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: "Gateway Tags",
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},{label: "GatewayTags", url:''}],
        refresh:false,
        categoryEnum: [],
    };

    const [state, setState] = useState<IGatewayTagsStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])


    useEffect(() => {

        async function getCategories() {

            try {
                const catreq = await GenericDassQuery("/rest/omc/tagcategories?limit=2000");
                const enumfilter: FilterOptionType[] = [];
                for (const cat of catreq.data) {
                    enumfilter.push({ label: cat.name, value: cat.name });
                }
                setState(prevState => { return {...prevState, categoryEnum: enumfilter }})
            } catch (e) {
                console.log(e.message);
            }

        }
        getCategories();

    }, []);



    const optionFetcher = async () => {
            
        let pageUrl = `/rest/omc/tagcategories`;

        let tagcategories  = await GenericDassQuery(pageUrl, { method: "GET" });

        let options = tagcategories.data.map(ele => {return  {label: ele.name,value: ele.name}} )
        
        return options;
    }

    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            
            navigate(`${getAppBase()}/gateway-tags/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/gateway-tags`}})
        }
    }

    const detailPageAdd = ( navigate, tab) => {
        navigate(`${getAppBase()}/gateway-tags/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/gateway-tags`}})
    }

    const detailPageAddTagCategory = ( navigate, tab) => {
        navigate(`${getAppBase()}/gateway-tag-category/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/gateway-tags`}})
    }
    const detailPageDeleteTagCategory = ( navigate, tab) => {
        navigate(`${getAppBase()}/gateway-tag-category/delete/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/gateway-tags`}})
    }


    const deleteTag = async ( tag: ITag ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a gateway tag",
            description: "Delete '" + tag.tagName + "'?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/tags/" + tag.tagid, {
                    method: "DELETE",
                });
                toast.success(`Tag ${tag.tagName} succesfully deleted`);
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }

    const deleteTags = async (selectedRows: ITag[]) => {
        
        const tagsids = selectedRows.map((row) => {
            return row['tagid'];
        })

        if(tagsids && tagsids.length > 0) {
            const confirmDialogeSettings = {
                title:  `You are about to delete ${tagsids.length} tags`,
                description:'',
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(tagsids.map((tagsid) => {
                        return GenericDassQuery("/rest/omc/tags/" + tagsid, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable();
                        toast.success("Tags succesfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }



    const  getActions = () => {

        const actions: ActionType[] = [
            {
                type: "action",
                text: "Delete tag",
                icon: faTrashAlt,
                action: (tag) => deleteTag(tag),
                visible: (tag) => true,
            }
        ];
        const bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: "Delete tags",
                icon: faTrashAlt,
                action: (selectedRows) => { deleteTags(selectedRows) }
            }
        ];

        return { actions, bulkActions };
    }


    const  initDataTable = () => {

        let actions;
        var pageButtons;

        actions = getActions();

        const columns: ColumnType[] = [
            {
                key: "tagName",
                title: "Name",
                type: "text",

                sortKey: "sort_by_tag_name",
                sortable: true,

                filterable: true,
                filterField: 'search_tag_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                detailPageNav: (row) => detailPageNav(navigate, TAB_TAG_INFO, row.tagid, row),
                render: (tag: ITag) => { 
                    // let res: JSX.Element[] = [];
                    return (<div className="ow-nst-gateway-tag-color" style={{backgroundColor: tag.color}}>{tag.tagName}</div>) as any;
                }
            },

            {
                key: "description",
                title: "Description",
                type: "text",

                sortKey: "sort_by_description",
                sortable: true,

                filterable: true,
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                }
            },

            {
                key: "category",
                title: "Category",
                type: "text",
                filterable: true,
                filterField: 'search_tag_category',
                filterType: "multiselect",
                filterParams: {
                    optionFetcher:() => optionFetcher(),
                    data: [],
                    mapper: x => x,
                },

                render: (tag: ITag) => tag.category?.name || "",

            },
        ];


        let options: OptionType = {
            url:'/uiapi/rest/omc/tags',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'tenantId',
            oboe_path: 'pages.*',
            available_key: 'tenantId'
        }

        return {...actions, columns:columns, ...options, ...pageButtons };
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: "Tag",
                action: () => detailPageAdd(navigate, TAB_TAG_ADD),
                type: 'button_with_icon',
                icon: faAdd
            },
            {
                title: "Tag Category",
                action: () => detailPageAddTagCategory(navigate, TAB_TAG_CATEGORY_ADD),
                type: 'button_with_icon',
                icon: faAdd
            },
            {
                title: "Tag Category",
                action: () => detailPageDeleteTagCategory(navigate, TAB_TAG_CATEGORY_DELETE),
                type: 'button_with_icon',
                icon: faTrashAlt
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            }
        ]

        return pageButtons;
    }

    
    return (<PageContent
        name="gateway-tags" 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Tags`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

    

}

export default GatewayTags;