
import { strings } from "../../services/Localization";
import React, { useEffect } from "react";
import * as ReactDOMServer from 'react-dom/server';
import { DataTable } from '../../components/Common';

import confirmedUpink from '../../../resources/images/confirmed_uplink.png'
import unconfirmedUpink from '../../../resources/images/unconfirmed_uplink.png'
import confirmedDownlink from '../../../resources/images/confirmed_downlink.png'
import unconfirmedDownlink from '../../../resources/images/unconfirmed_downlink.png'

declare const constants;

import  { dateTimeString, formatPayload, getVisibleActions  } from '../../utils/filters';

import  { getDecodedLorawanMsgAsText  } from '../../utils/lorawanMsgDecoder';

import { PageButtonType } from '../../datatypes/datatypes';

import { faPaperPlane , faTrashAlt, faRefresh, faFileExport, faListCheck, faUp, faDown, faLock, faLockOpen,
		  //faMapLocation
		 } from '@fortawesome/pro-regular-svg-icons'
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "src/components/Common/DataTable/DataTypes";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import { IDevice, IUser } from "../../dassTypes";

import { GenericDassQuery } from "../../services/BasicDassQueries";

import { SpinnerHide, SpinnerShow, dialog } from "../../components/Common";

import { toast } from "./../../utils/Toaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface IRowType {
	timestamp: string;
	payload: string;
	fcnt: number;
	port: number;
	decrypted: boolean;
	id: number;

	mac_msg: string;
	confirmed: boolean;

	data: string;
	dataFrame: string;
	decoded: object;

	// uplink only
	rssi: number;
	snr: number;

	altitude: number;
	latitude: number;
	longitude: number;
	freq: number;

	// downlink only
	transmissionStatus: number;
	errorcode: number;

}



function renderDecodedPayloadTo(decodedObj: object): JSX.Element {

	if (!decodedObj) { return null; }
	const newDecodedObj = {};

	Object.keys(decodedObj).forEach((key) => {
		if (decodedObj[key] != null){
			newDecodedObj[key] = decodedObj[key];
		}
	});

	// finding the key with most characters
	let longestKeyLength = null;
	Object.keys(newDecodedObj).forEach((key) => {

		if (!longestKeyLength) {
			longestKeyLength = key.length;
		}
		else if (key.length > longestKeyLength) {
			longestKeyLength = key.length;
		}
	});

	let html = "";
	Object.keys(newDecodedObj).forEach(function (key) {

		var keyString = key;
		var keyLength = keyString.length;
		for (var i = keyLength; i < longestKeyLength; i++) {
			keyString += ' ';
		}

		var result;
		if (typeof newDecodedObj[key] === "object") {
			var jsonStringify = JSON.stringify(newDecodedObj[key]).replace(/,/g, ", ");
			var strReplace = jsonStringify.replace(/\"([^(\")"]+)\":/g,"$1:").slice(1, -1);
			result = strReplace.replace(/\"|'|null| _/gi, "");
		} else {
			result = newDecodedObj[key];
		}
		html += '<div class="row"><div class="col-md-12 key-container">' + keyString + ' = ' + result + '</div></div>';
	});

	return <div className="column-inside-container container" dangerouslySetInnerHTML={{__html: html}} />;
}



const can_view_mac_msg = true;

interface DeviceDataState {
	device: IDevice;
	exportInit: number;
	user: IUser;
	groups: any;
	apps: any;
	refresh: boolean;
}

const DeviceViewDataTable:React.FC<{ deveui: string, user: IUser }> = ( props ) =>  {
	const  deveui = props.deveui;

	const initState = {
		exportInit: 0,
		device: null,
		user: props.user,
		groups: [],
		apps: [],
		refresh: false
	}

	const [state, setState] = React.useState<DeviceDataState>(initState);
    
	const  renderMacMsg = (row: IRowType) => {

		try {
			if (state.device == null) { return ""; }

			const bin = atob(row.mac_msg);
			let hex = "";
			for (let i = 0; i < bin.length; i++) {
				const c = bin.charCodeAt(i) & 0xff;
				hex += (c >> 4).toString(16) + (c & 0x0f).toString(16)
			}

			return getDecodedLorawanMsgAsText(hex, true, state.device.MACVersion, state.device.RFRegion,state.device.RegParamsRevision);
		} catch (e) {
			return "Unable to decode MAC message";
		}
	}


    const  getActions = () => {

		const actions: ActionType[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKET,
			icon: faTrashAlt,
			action: (packet) => deletePayload(packet),
			visible: () => !state.user?._readonly
		}, {
			type: "action",
			text: strings.RE_PUSH_PAYLOAD,
			icon: faPaperPlane,
			action: (packet) => rePushPayloadUL(packet),
			visible: (payload) => payload?.rssi != null && !state.user?._readonly
		}];

		const bulkActions: BulkActionType[] = [{
			type: "action",
			text: strings.DATA_DELETE_PACKETS,
			icon: faTrashAlt,
			action: (packets) => deletePayloads(packets),
			visible: () => state.user && !state.user?._readonly
		}];

		return {actions, bulkActions };
    }


	const  getDeviceDetails = async () => {
		try {
			const req = await GenericDassQuery(`/rest/nodes/${deveui}?all=true`);
			if (req.status === 200) { 
				state.device = req.data ;
				setState(prevState => {
					return {...prevState, device:req.data}
				})
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		getDeviceDetails();
	},[]);

	const toggleOptions = [
        { name: <FontAwesomeIcon icon={faUp} />, value: 'uplink', searchValue: "uplink" },
        { name: <FontAwesomeIcon icon={faDown} />, value: 'downlink' , searchValue: "downlink"},
	];

	const renderDirectionShort = (direction: boolean, confirmed: boolean) => {
		const icon = direction ? (confirmed ? confirmedUpink : unconfirmedUpink) : (confirmed ? confirmedDownlink : unconfirmedDownlink); 
		return ReactDOMServer.renderToString(<div className="d-flex justify-content-center"><img src={icon} width="25px" /></div>) 
	}
	const renderDirectionLong = (direction: boolean, confirmed: boolean) => {
		return (confirmed ? "Confirmed-" : "Unconfirmed-") + (direction ? "Uplink" : "Downlink");
	}


	const  getColumns = () => {
		const columns: ColumnType<IRowType>[] = [
			{
				key: "direction",
				dbKey: "timestamp",
				title: strings.DIRECTION_SHORT,
				type: "text_with_tooltip",
				filterable: true,
				filterType: 'boolean_toggle',
				filterField: 'payload_type',
				toggleOptions:toggleOptions,
				htmlRender: (row) => renderDirectionShort(row.rssi != null, row.confirmed),
				render_tooltip: (row) => renderDirectionLong(row.rssi != null, row.confirmed),

				dataAlign: 'center',
				cellWidth: 3,
				newCellWidth: "75px",
			},
			
			{
				key: "timestamp",
				dbKey: "timestamp",
				title: strings.TIME,
				type: "text",
				render: (x) => dateTimeString(x.timestamp),
				filterable: true,
				filterField: "date",
				filterType: 'daterange',
				filterParams: {
					startField: "from_date",
					endField: "to_date",
//					mapper: (x) => x && x.format()
				},
				sortable: true,
				newCellWidth: "170px",
				sortKey: "sort_by_timestamp",
				cellWidth: "nowrap",
				customClass: "text-nowrap"
			},

			{
				key: "fcnt",
				title: strings.FCNT,
				type: "text",
				filterable: false,
				filterType: 'text',
				filterField: 'fcnt',
				filterParams: {
					mapper: (x) => x || undefined
				},
				render: (x) => x.fcnt != undefined ? x.fcnt.toString() : '',
				dataAlign: 'center',
				cellWidth: 3,
				newCellWidth: '70px',
			},

			{
				key: "port",
				title: strings.PORT,
				type: "text",
				filterable: true,
				filterType: 'text',
				filterField: 'search_port',
				newCellWidth: "70px",
				filterWidth: "50px",
				filterParams: {
					mapper: (x) => x || undefined
				},
				render: (x) => x.port != undefined ? x.port.toString() : '',
				dataAlign: 'center',
				cellWidth: 3,
			},

			{
				key: "transmissionStatus",
				title: strings.STATUS,
				type: "text_with_tooltip",
				filterable: false,
				filterField: 'transmissionStatus',
				filterType: 'select',
				filterParams: {
					options: [
						{ label: strings.GATEWAY_STATUS_ANY, value: "" },
						{ label: strings.PACKET_STATUS_0,    value: 0  },
						{ label: strings.PACKET_STATUS_1,    value: 1  },
						{ label: strings.PACKET_STATUS_2,    value: 2  },
						{ label: strings.PACKET_STATUS_3,    value: 3  },
						{ label: strings.PACKET_STATUS_4,    value: 4  },
						{ label: strings.PACKET_STATUS_5,    value: 5  }
					]
				},
				render: (x) => (
					x.rssi && "" ||
					x.transmissionStatus == 0 && strings.PACKET_STATUS_0 ||
					(x.transmissionStatus == 1 && x.confirmed == true) && strings.PACKET_STATUS_1 ||
					(x.transmissionStatus == 1 && x.confirmed == false) && strings.PACKET_STATUS_1_1 ||
					x.transmissionStatus == 2 && strings.PACKET_STATUS_2 ||
					x.transmissionStatus == 3 && strings.PACKET_STATUS_3 ||
					x.transmissionStatus == 4 && strings.PACKET_STATUS_4 ||
					x.transmissionStatus == 5 && strings.PACKET_STATUS_5
				),
				render_tooltip: x => {
					// Error
					if (x.transmissionStatus === 4) {
						const errorCodes = ["Unknown error 0", "FCNT out of sync", "Message is too long", "Invalid JOIN session ID", "Unknown Error"];
						return errorCodes[x.errorcode] || "Unknown Error";
					}
					return null;
				},
				dataAlign: 'center',
				cellWidth: 5,
				newCellWidth: '120px',
			},

			{
				key: "mac_msg",
				title: strings.MAC_MSG,
				type: "text",
				render: (row) => {
					if(row.mac_msg && can_view_mac_msg == true) {
						return <div className="text-break">{renderMacMsg(row)}</div>
					}else {
						return ""
					}
						
				},
				enabledInColumn:false,
				// cellWidth: 50,
				// cellWidthType: '%',
				newCellWidth: '250px',
			},
			{
				key: "dr_used",
				title: strings.DATA_RATE,
				type: "text",
				filterable: false,
				filterType: 'text',
				filterField: 'dr_used',
				filterParams: {
					mapper: (x) => x || undefined
				},
				dataAlign: 'center',
				cellWidth: 8,
				newCellWidth: '100px',
			},
	
			{
				key: "rssi",
				title: strings.RSSI,
				type: "text_with_tooltip",
/*
				tooltip_props: {
					tooltipClass: "increase-popover-width",
					appendToBody: true,
					trigger: "mouseenter"
				},
*/
				render_tooltip: x => (x.snr ? 'SNR: ' + x.snr : '' ) + (((x.snr) && (x.freq)) ? ', ' : '') + (x.freq ? 'Frequency: ' + Math.round((x.freq / 1000000) * 10000) / 10000 + ' MHz': '' ),
				render: row => row.rssi != null ? row.rssi + "" : "",
				filterable: false,
				filterType: 'text',
				filterField: 'rssi',
				filterParams: {
					mapper: (x) => x || undefined
				},
				dataAlign: 'center',
				newCellWidth: '80px',
			},
			{
				key: "decrypted",
				type: "icon_with_tooltip",
				title: strings.DECRYPTED,
				newCellWidth: "150px",
				render_icon: (row) => row.decrypted ? faLockOpen : faLock,
				dataAlign: 'center',
				cellWidth: 5,
			},
			{
				key: "location",
				dbKey: "timestamp",
				title: strings.POSITION_ESTIMATES_SHORT,
				type: "text",
				render: (row) => {
					if (row.latitude != null && row.longitude != null) {
						return "(" + row.latitude + "," + row.longitude + ")"
					} else {
						return "";
					}
				},
				enabledInColumn: false,
				cellWidth: 21,
				newCellWidth: "150px",
			},			
			{
				key: "data",
				dbKey: "timestamp",
				title: strings.SHOW_DATA,
				type: "text",
				render: (x) => (x.dataFrame && formatPayload(x.dataFrame) || x.data && formatPayload(x.data)),
				enabledInColumn: true,
				// cellWidth: '35',
				// cellWidthType: '%',
				newCellWidth: '300px',
				customClass: 'font-monospace'

			},			
			{
				key: "decoded",
				title: strings.SHOW_DECODE_PAYLOAD_SHORT,
				type: "text",
				render: row => renderDecodedPayloadTo(row.decoded),
				cellWidth: 10,
				enabledInColumn:false,
				newCellWidth: "150px",
			}


        ];

		return columns;
	}

    const  getDataTableOptions = () => {

        const actions = getActions();
		const columns = getColumns();
        
        const endpoint = `uiapi/rest/export/payloads?download_filename=payloads.csv&deveuis=${deveui}` ;
        //'uiapi/rest/export/devices?download_filename=messages.csv',
        let options: DataTableOption = {
			url: `/uiapi/rest/nodes/${deveui}/payloads/all`,
            query_param: { all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', sort_by_timestamp: 'desc', mac_msg: false },
            serial_number: false,
            id_field: 'id',
            oboe_path: 'pages.*',
            available_key: 'deveui',
			allowBulkActions: true,
            defaultSortField: 'sort_by_deveui',
            defaultSortOrder: 'asc',
			columns,
			actions: actions.actions,
            bulkActions: getVisibleActions(actions.bulkActions),
			countLabel:'Messages Stored',
            exportPath: endpoint,
        }

        return options;
	}

    const  refreshTable = () => {

		setState(prevState => {
            let refresh = (prevState.refresh === true)  ? false : true;
            return {...prevState, refresh:refresh}
        })
		
	}

	const exportTable = () => {
        
        setState(prevState => {
            let exportInit = (prevState.exportInit === 1)  ? 2 : 1;
            return {...prevState, exportInit:exportInit}
        })
    }

	

	// const  exportPayloads = ( currentDevice ) => {
	// 	var queryStr = '';
	// 	if (currentDevice) {
	// 		queryStr += '&deveuis=';
	// 		queryStr += deveui;
	// 		//queryStr += "&mac_msg=" + (show_mac_msg ? "true" : "false");

	// 		// if ($scope.child.filter.date_changed) {
	// 		// 	let deviceStartDate = $scope.child.unmappedFilter.date.startDate;
	// 		// 	let deviceEndDate   = $scope.child.unmappedFilter.date.endDate;

	// 		// 	queryStr += '&payload_from_date=';
	// 		// 	queryStr += deviceStartDate.toISOString();

	// 		// 	queryStr += '&payload_to_date=';
    //         //     queryStr += deviceEndDate.toISOString();
	// 		// }
	// 	}

	// 	var url = window.location.protocol;
	// 	var tz = "&tz=" + (new Date()).getTimezoneOffset();
	// 	url += '//';
	// 	url += window.location.host;

	// 	window.location.assign(url + '/app/export_payloads.csv?download_filename=payloads.csv' + tz + queryStr);
	// }

    const  getPageButtons = () => {

		const pageButtons:PageButtonType[] = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'refresh',
                icon: faRefresh
            },
            {
                title: strings.EXPORT_DEVICE_BTN,
                action: (  ) => { exportTable() },
                type: 'button',
				icon: faFileExport
            },
            {
                title: strings.COLUMNS,
                action: () => { console.log(strings.ADD_USER) },
                type: 'column',
				icon: faListCheck
            },
          
        ]

        return pageButtons;
    }

    const deletePayload = async (packat) => {

		const confirmDialogeSettings = {
            title:  "You are about to delete a payload",
            description: "",
            actionLabel: 'Delete',
        };
		if(await dialog(confirmDialogeSettings) === true) {
			SpinnerShow();
			let url;
			if(packat.rssi != null) {
				url = `uiapi/rest/nodes/${deveui}/payloads/ul/${packat.id}`;
			}else {
				url = `uiapi/rest/nodes/${deveui}/payloads/dl/${packat.id}`;
			}

			try {
				await GenericDassQuery(url, {prefix: '/', method: "DELETE"});
				SpinnerHide();
				toast.success(`Packet Deleted`)
				refreshTable();

			} catch (e) {
				toast.error(e)
			}
	    }
	}

    const rePushPayloadUL = async ( packet ) => {
		try {
			SpinnerShow();
			
			const url = `uiapi/rest/pushmode/test/payload_ul/${deveui}/${packet.id}`

			await GenericDassQuery(url, {prefix: '/', method: "POST"});
			toast.success(`Payload re-pushed successfully`)
			SpinnerHide();
			// refreshTable();

		} catch (e) {
			toast.error(e)
  		}
	}


    const deletePayloads = async ( selectedRows: IRowType[] ) => {

		if( selectedRows && selectedRows.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the selected packet(s)`,
                description:'',
                actionLabel: 'Delete',
            };
			if (await dialog(confirmDialogeSettings) === true) {
				SpinnerShow();
				let url;
				const promises = []
				selectedRows.map((packat ) => {

					if(packat.rssi != null) {
						url = `uiapi/rest/nodes/${deveui}/payloads/ul/${packat.id}`;
					} else {
						url = `uiapi/rest/nodes/${deveui}/payloads/dl/${packat.id}`;
					}
					const prom =  GenericDassQuery(url, {prefix: '/', method: "DELETE"});
					promises.push(prom);
				})

				try {
					await Promise.all(promises) 
					toast.success(`Deleted ${selectedRows.length} packets for device`)
					SpinnerHide();
					refreshTable();
				} catch (e) { 
					toast.error(e)
				}	
			}
		}
	};

	return (<DataTable  exportInit={state.exportInit} refresh={state.refresh} name={`view-data-${deveui}`} 
						countLabel={'Messages Stored'}  dataTableOption={getDataTableOptions()} pageButtons={getPageButtons()} scope="child" />)
}


export default DeviceViewDataTable;