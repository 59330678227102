import React, { useEffect, useState, useContext } from "react";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import { dialog, dialogDescription } from '../../components/Common';
import { useNavigate, useParams  } from 'react-router-dom';
import { appBaseUrl, getAppBase } from '../../utils/consts';
import { faPlay, faStop, faAdd, faGear, faRefresh  } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IUser } from "src/dassTypes";
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption } from '../../components/Common/DataTable/DataTypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { TAB_ADD_APP, TAB_EDIT_APP, TAB_EDIT_DEFAULT_PUSH_CONFIG } from '../../datatypes/tabsconstants'
import{ DEFAULT_RECORD_LIMIT, ID_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { getVisibleActions } from "./../../utils/filters";


declare const constants;

export interface IRowType {
    accountid: string;
    app_uuid: string;
    enabled: boolean;
}

interface IMyAppsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a group
    schemaMethod: '' | "post" | "put" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    globalPushStatus: boolean; // This is change the State of Default Push Button
}


const MyApps: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);

    const stateInit = {
        loggedUser: AppContextObj.user,
        showAlertModal:false,
        pageTitle:strings[`NAV_USERS_ITEM`],
        editEntityId: '',
        schemaMethod: '',
        breadCrumbArr: [{label:strings.NAV_APPLICATIONS_ITEM, url:''}, {label:strings.MANAGE_APPLICATIONS_NAV_TITLE,url:''}],
        refresh:false,
        globalPushStatus: false
    };

    const [state, setState] = useState<IMyAppsStates>(stateInit)
    
    const navigate = useNavigate();

    let { id, tabname } = useParams();

    useEffect(() => {

        let globalPushStatus 

        async function init ()  {
            const pushStatus = await GenericDassQuery("/rest/pushmode/status", { method: "GET"})
            if(pushStatus.status === 200 && pushStatus.data) {
                globalPushStatus = pushStatus.data.enabled
            }
        } 

        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl ;
            
        } else {
            
            if(checkLogin(AppContextObj.user)) {

                init()
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user, globalPushStatus: globalPushStatus }})

            } else {
                window.location.href = appBaseUrl + "/signin";
            }
        }

    },[])


    const defaultStartStopGlobalPush = async ( ) => {
        try {
            
            if(state.globalPushStatus === true) {
                const data = await GenericDassQuery("/rest/pushmode/stop", { method: "PUT", data: { enabled: false} })
                if(data.status === 200) {
                    setState(prevState => { return {...prevState, globalPushStatus: false }})
                }
                toast.success("Application push successfully stop");
            } else {
                const globalPushdata = await GenericDassQuery("/rest/pushmode/status", { method: "GET"});
                const data = await GenericDassQuery("/rest/pushmode/start", { method: "PUT", data: globalPushdata.data })
                if(data.status === 200) {
                    setState(prevState => { return {...prevState, globalPushStatus: true }})
                }
                toast.success("Application push successfully started");
            }
        } catch (e) {
            toast.error(e.message);
        }
    }


    const deleteApp = async ( app: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete an application",
            description: "Delete '" + app.accountid + "'?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            // spinnerClass.show();
            try {
                await GenericDassQuery("/rest/applications/" + app.accountid, { method: "DELETE" });
                refreshTable();
                toast.success("Application successfully deleted");
            } catch (e) {
                toast.error(e.message);
            }
            // spinnerClass.hide();
        }

    }

    const startPush = async ( app: IRowType ) => {
        try {
            await GenericDassQuery("/rest/applications/" + app.accountid, { method: "PUT", data: { enabled: true }});
            refreshTable();
            toast.success("Application push successfully started");
        } catch (e) {
            toast.error(e.message);
        }
    }


    const stopPush = async (app: IRowType) => {
        try {
            await GenericDassQuery("/rest/applications/" + app.accountid, { method: "PUT", data: { enabled: false }});
            refreshTable();
            toast.success("Application push successfully stopped");
        } catch (e) {
            toast.error(e.message);
        }
    }
    
    const batchAppDelete = async ( selectedIds ) => {

        const accountIds = selectedIds.map((row) => {
            return row['accountid'];
        })
        if(accountIds && accountIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${accountIds.length} selected Application(s)`,
                description: await dialogDescription(accountIds) + "",
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(accountIds.map((accountId) => {
                        return GenericDassQuery("/rest/applications/" + accountId, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Applications Deleted Successfully");
                    }).catch((e) => {
                        toast.error(e.message);
                    });
                } catch (e) {
                    toast.error(e.message);
                }       
            }
        }
    }

    const batchAppStartPush =  async ( selectedIds ) => {

        const accountIds = selectedIds.map((row) => {
            return row['accountid'];
        })
        if(accountIds && accountIds.length > 0) {
            const confirmDialogeSettings = {
                title:  "Do you want to Start Push Mode for Selected Applications ?",
                description:'',
                actionLabel: 'Start Push Mode',
            };
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(accountIds.map((accountId) => {
                        return GenericDassQuery("/rest/applications/" + accountId, { method: "PUT" , data: {enabled: true}});
                    })).then((values) => {
                        refreshTable();
                        toast.success("Applications Push Mode Started Successfully");
                    }).catch((e) => {
                        toast.error(e.message);
                    });
                } catch (e) {
                    toast.error(e.message);
                }       
            }
        }
    }


    const batchAppStopPush = async ( selectedIds ) => {

        const accountIds = selectedIds.map((row) => {
            return row['accountid'];
        })
        if(accountIds && accountIds.length > 0) {
            const confirmDialogeSettings = {
                title:  "Do you want to Stop Push Mode for Selected Applications ?",
                description:'',
                actionLabel: 'Stop Push Mode',
            };
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(accountIds.map((accountId) => {
                        return GenericDassQuery("/rest/applications/" + accountId, { method: "PUT" , data: {enabled: false}});
                    })).then((values) => {
                        refreshTable();
                        toast.success("Application Push Mode Stopped Successfully");
                    }).catch((e) => {
                        toast.error(e.message);
                    });
                } catch (e) {
                    toast.error(e.message);
                }       
            }
        }
    }

    

    const addAppDialog = ( navigate, tab ) => {
        const prevPageUrl = `${getAppBase()}/my-apps`;
        navigate(`${getAppBase()}/my-apps/${tab}`, {state: {tab: tab, prevPageUrl: prevPageUrl}})
    }

    const editGlobalPush = ( navigate, tab ) => {
        const prevPageUrl = `${getAppBase()}/my-apps`;
        navigate(`${getAppBase()}/my-apps/edit/${tab}`, {state: {tab: tab, prevPageUrl: prevPageUrl}})
    }
    

    const getActions = () => {
        
        let actions:ActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.HTTP_PUSH_START,
                icon: faPlay,
                action: (app) => startPush(app)	,
                visible: (app) => (app?.enabled === false && !state.loggedUser?._readonly),
            },{
                type: "action",
                text: strings.HTTP_PUSH_STOP,
                icon: faStop,
                action: (app) => stopPush(app),
                visible: (app) => (app?.enabled === true && !state.loggedUser?._readonly),
            }, {
                type: "action",
                text: strings.MY_APPS_ACTION_DELETE,
                icon: faTrashAlt,
                action: (app) => deleteApp(app),
                visible: (app) => !state.loggedUser?._readonly,
            }
        ];

        let bulkActions:BulkActionType<IRowType>[] = [
        {
            type: "action",
            text: strings.MY_APPS_ACTION_BULK_DELETE,
            icon: faTrashAlt,
            action: ( selectedIds ) => { batchAppDelete(selectedIds) },
            visible: () => !state.loggedUser?._readonly,
        },
        {
            type: "action",
            text: strings.HTTP_PUSH_START,
            icon: faPlay,
            action: ( selectedIds ) => { batchAppStartPush(selectedIds) },
            visible: () => !state.loggedUser?._readonly,
        },{
            type: "action",
            text: strings.HTTP_PUSH_STOP,
            icon: faStop,
            action: ( selectedIds ) => { batchAppStopPush(selectedIds) },
            visible: () => !state.loggedUser?._readonly,
        }];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
    }

    
    const detailPageNav = ( navigate, tab, accountId, row) => {

        const prevPageUrl = `${getAppBase()}/my-apps`;

        navigate(`${getAppBase()}/my-apps/${accountId}/${TAB_EDIT_APP}`, {state: {row: row, prevPageUrl:prevPageUrl}})

    }

    

    const  initDataTable = () => {

        const {actions, bulkActions} = getActions();

		let columns: ColumnType<IRowType>[] = [
            {
                key: "accountid",
                type: "text",
                title: strings.APP_ID,
                filterable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterType: 'text',
                filterField: 'search_id',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, TAB_EDIT_APP, row.accountid, row ),
                customClass: 'nowarp',
                cellWidth: 25,
            }, {
                key: "enabled",
                title: strings.STATUS,
                type: "icon_with_tooltip",
                render_icon: (obj) => obj.enabled ? faPlay : faStop,
                render_tooltip: (obj) => { return obj.enabled ? 'started' : 'stopped' },
                filterable: false,
                cellWidth: 6,
                newCellWidth: "70px",
                dataAlign:'center',
            },{
                key: "app_uuid",
                type: "text",
                title: strings.UUID,
                filterable: false,
                copyLink: true,
                customClass: 'font-monospace nowarp',
                newCellWidth: "270px",
                cellWidth:100,
                cellWidthType:'%',
            }
        ];
        
        const options: DataTableOption<IRowType> = {
            url:'/uiapi/rest/applications',
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'accountid',
            oboe_path:'pages.*',
            available_key: 'accountid',
            columns,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)
        }
        return options;
	}

    const refreshTable = () => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
        
    }

    
    
    const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.MY_APPS_APP,
                action: () => addAppDialog(navigate, TAB_ADD_APP),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => !state.loggedUser?._readonly
            },
            {
                title: state.globalPushStatus ? "Stop Default Push" : "Start Default Push",
                action: () => defaultStartStopGlobalPush(),
                type: 'button_with_icon',
                icon:  state.globalPushStatus ? faStop : faPlay,
                visible: () => !state.loggedUser?._readonly
            },
            {
                title: strings.EDIT_GLOBAL_PUSH_CONFIG,
                action: () => editGlobalPush(navigate, TAB_EDIT_DEFAULT_PUSH_CONFIG),
                type: 'button',
                icon: faGear,
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ]

        return pageButtons;
    }

    return (
        <PageContent
            name="my-apps" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Applications`} 
            dataTableOption={initDataTable()} 
            refresh={state.refresh}>
        </PageContent>
    );
}


export default MyApps;