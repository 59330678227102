

declare const headerContentTypeJson;
declare const headerNoContentType;
import { appBaseUrl } from '../utils/consts';

export interface IProfileFilterColumn {
    colName: string;
    colValue: string;
}

export interface IProfileSortColumn {
    sortName: string;
    sortOrder: string;
}

export type IRequestMethod = "PUT" | "GET" | "POST" | "DELETE";
interface IGetDataOptions {
	query?: any;
	method?: IRequestMethod;
	data?: object | string;
	headers?: any;
	prefix?: string;		// for debug
}

export function objToQueryString(queryObj: {[index: string]: string }) {
    let query = "";
    for (const key of Object.keys(queryObj || {})) {
        if (queryObj[key] != null) {
            query += (query ? "&" : "?") + key + "=" + encodeURIComponent(queryObj[key] + "");
        }
    }
    return query;
}

export const getDassInfo = async () => {
	const response = await fetch("/dass_info", {
		credentials: "same-origin",
		headers: headerNoContentType,
		method: "GET",
	});

	if (response.status > 299) {
		throw { status: response.status, message: await response.text() };
	}
	return { status: response.status, data: await response.json() };
};

export const GenericDassQuery = async (dassPath: string, options?: IGetDataOptions) => {
	
	const url = (options?.prefix ? options?.prefix : "/uiapi") +
				 dassPath + objToQueryString(options && options.query);
	const headers = { ...(options && options.headers),  ...headerNoContentType };
	const body = options && options.data && (typeof options.data === "object" ? JSON.stringify(options.data) : options.data);

	if (!headers["Content-Type"] && options && options.data) {
		headers["Content-Type"] = typeof options.data === "object" ? "application/json" : "text/plain";
	}

	const response = await fetch(url, {
		credentials: "same-origin",
		headers,
		method: options && options.method || "GET",
		body,
	});

	if (response.status == 401) { // the status should be changed!!!
		window.location.href = appBaseUrl + "/signout?resignin";
	}

	const status = response.status;
	const contentType = response.headers.get("content-type");
	const json = contentType && contentType.indexOf("application/json") >= 0;
	const data = json ? await response.json() : await response.text();

	if (status > 299) {
		throw { status, message: status >= 500 ? "System unreachable" : data };
	}

	return { status, data };
};
