import React, {useState, useEffect, useContext } from "react";
import PageContent from "../PageContent";
import { faRefresh }  from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt }  from '@fortawesome/pro-regular-svg-icons';
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { appBaseUrl, getAppBase } from '../../utils/consts';
import { IUser } from "../../../src/dassTypes";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";
import{ DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { dialog } from '../../components/Common';
import { useNavigate, useParams } from 'react-router-dom';

import { TAB_AUTHORIZE_GATEWAY } from '../../datatypes/tabsconstants'

declare const constants;

interface IGatewayManageAuth {
  name:string;
  uuid:string;
  activated:boolean
}

interface IGatewayManageAuthStates {
  loggedUser: IUser | null;
  showAlertModal: boolean;
  pageTitle: string;
  editEntityId: string | null;            // The entity in this view is a gateway
  schemaMethod: "post" |"put" | "" | string;
  breadCrumbArr: BreadCrumbType[];
  refresh:boolean;
}

interface IGatewayManageAuthprops {
}


const GatewayManageAuth: React.FC<IGatewayManageAuthprops> = (props) =>  {

  const AppContextObj = useContext(AppContext);
  const navigate = useNavigate();

  const stateInit: IGatewayManageAuthStates = {
    loggedUser: null,
    showAlertModal: false,
    pageTitle: strings.GATEWAYS_TITLE,
    editEntityId: '',
    schemaMethod: "",
    breadCrumbArr: [{label: "Manage Authorization", url:''}],
    refresh:false
  };

  const [state, setState] = useState<IGatewayManageAuthStates>(stateInit)

  useEffect(() => {
    if (constants.enable_profiles !== true) {
      window.location.href = appBaseUrl;
    } else {
      if(checkLogin(AppContextObj.user)) {
        setState((prevState) => {
          return {...prevState, loggedUser: AppContextObj.user };
        });
      } else {
        window.location.href = appBaseUrl + "/signin";
      }
    }
  },[])


  const detailPageAdd = ( navigate, tab) => {
    navigate(`${getAppBase()}/gateway-manage-auth/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/gateway-manage-auth`}})
}

  const deleteGateway = async ( gateway: IGatewayManageAuth ) => {
    const confirmDialogeSettings = {
      title:  "You are about to delete a gateway",
      description: "Delete '" + gateway.uuid + "'?",
      actionLabel: 'Delete',
    };

    if ((await dialog(confirmDialogeSettings)) === true) {
      try {
        await GenericDassQuery("/rest/omc/gateways/" + gateway.uuid, {
          method: "DELETE"
        });
        refreshTable();
        toast.success("Gateway successfully deleted");
      } catch (e) {
        toast.error(e.message);
      }
    }
  }

  const deleteBulkGateways = async (selectedRows: IGatewayManageAuth[]) => {


    const gatewayIds = selectedRows.map((row) => {
      return row['uuid'];
    })

    if(gatewayIds && gatewayIds.length > 0) {
      const confirmDialogeSettings = {
        title:  `Do you want to delete the selected gateway(s)`,
        description:'',
        actionLabel: 'Delete',
      };

      if ((await dialog(confirmDialogeSettings)) === true) {
        try {
          Promise.all(
            gatewayIds.map((gatewayId) => {
              return GenericDassQuery("/rest/omc/gateways/" + gatewayId, {
                method: "DELETE",
              });
            })
          ).then((values) => {
            refreshTable();
            toast.success("Gateways successfully deleted");
          });
        } catch (e) {
          toast.error(e.message);
        }
      }
    }
  }


  const  getActions = () => {

    const actions: ActionType[] = [
      {
        type: "action",
        text: strings.DELETE_GATEWAY,
        icon: faTrashAlt,
        action: (gateway: IGatewayManageAuth)=> deleteGateway(gateway),
        visible: (gateway: IGatewayManageAuth) =>
          AppContextObj.user.can_mng_gtw || AppContextObj.user.can_add_gtw,
      },
    ];
    const bulkActions: BulkActionType[] = [
      {
        type: "action",
        text: strings.DELETE_GATEWAYS,
        icon: faTrashAlt,
        action: (gatewayIds) => deleteBulkGateways(gatewayIds)
      }
    ];

    return { actions:actions, bulkActions:bulkActions };
  }

  const  initDataTable = () => {

    let actions;
    var pageButtons;

    actions = getActions();

    const columns: ColumnType[] = [

      {
        key: "uuid",
        title: "Uuid",
        type: "text",
        inputValidation: DEFAULT_INPUT_VALIDATION,
        sortable: true,
        sortKey: 'sort_by_uuid',
        filterable: true,
        filterField: 'search_uuid',
        filterType: 'text',
        filterParams: {
          mapper: (x) => x || undefined
        },
        cellWidth: 10,
        customClass: 'font-monospace'
      },{
        key: "activated",
        type: "text",
        inputValidation: DEFAULT_INPUT_VALIDATION,
        title: "Active",
        sortable: true,
        sortKey: 'sort_activated',
        render: (row:IGatewayManageAuth) => row.activated + "",
      }
    ];

    let options: OptionType = {
      url:'/uiapi/rest/omc/gateways',
      query_param: {
        projection: "gatewayManageAuthorizationView",
        get_pages: true,
        limit: DEFAULT_RECORD_LIMIT,
        stream: 'progress',
      } as any,
      serial_number: false,
      id_field: 'uuid',
      oboe_path: 'pages.*',
      available_key: 'uuid',
      defaultSortField:"sort_by_uuid", //should be sortKey field
      defaultSortOrder:"asc",
      allowBulkActions: true,
    }

    return {...actions, columns:columns, ...options, ...pageButtons };
  }

  const refreshTable = () => {
    setState((prevState) => {
      return {...prevState, refresh:!prevState.refresh}
    })
  }

  
  const  getPageButtons = () => {

    var  pageButtons: PageButtonType[] = [
      {
        title: strings.REFRESH,
        action: () => { refreshTable();},
        type: "button",
        icon: faRefresh,
      },
      {
          title: "Authorize gateway",
          action: () => detailPageAdd(navigate, TAB_AUTHORIZE_GATEWAY),
          type: "button_with_icon",
          icon: "",
      }
    ];

    return pageButtons;
  };

  let { tabname } = useParams();
  return (
    <PageContent
        name="gateway-manage-auth"
        // id={deveui}
        tabname={tabname}
        breadCrumbArr={state.breadCrumbArr}
        countLabel={`gateway's`}
        pageButtons={getPageButtons()}
        dataTableOption={initDataTable()}
        refresh={state.refresh}
      />
  );
};

export default GatewayManageAuth;
