
import LocalizedStrings from "react-localization";
import { english } from "../localization/en";
import { chinese } from "../localization/zh";


interface ITranslate {
    [index: string]: string | number;
}

export const strings = new LocalizedStrings({
    en: english,
    zh: chinese,
});
strings.setLanguage(decodeURIComponent(document.cookie).slice(-2));

export function translate (content: string, data: ITranslate ) {

    return strings.formatString(content, data); // hack need Fromatted Object here

}