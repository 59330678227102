
import React from "react";
import { Container, FormText } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "../../services/Localization";
import { Whoami } from "../../services/Login";
import { toast } from "../../utils/Toaster";
import "./ResetPassword.css";
import { appBaseUrl } from '../../utils/consts';

import orbiwiseLogo from '../../../resources/images/orbiwise-logo-dark.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

declare const constants;

declare const headerContentTypeJson;
declare const headerNoContentType;

interface IResetPassword {
    password: string;
    confirmPassword: string;
    userid: string;
    auco: "on" | "off";
    showContent: boolean;
    passwordToken: string;
    type: "welcome" | "reset";
    pwlen: number;
    pwcnt: number;
}

export class ResetPassword extends React.Component<any, IResetPassword> {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            userid: "",
            auco: (constants.disable_autocomplete_for_credentials === true) ? "off" : "on",
            showContent: false,
            passwordToken: "",
            type: "reset",
            pwcnt: 1,
            pwlen: 8,
        };
    }

    public componentDidMount() {

        const searchObj: any = {};
        if (window.location.search) {
            for (const arg of window.location.search.slice(1).split("&")) {
                const i = arg.indexOf("=");
                if (i > 0) {
                    searchObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                }
            }
        }

        if (searchObj["password_token"]) {
            try {
                const passwordToken = searchObj["password_token"];
                const tok = JSON.parse(atob(passwordToken.split(".")[1]));

                this.setState({
                    passwordToken,
                    userid: tok.userid,
                    type: tok.type,
                    pwcnt: tok.pwcnt,
                    pwlen: tok.pwlen,
                });

                if (Date.now()/1000 + 5 * 60 > tok.exp || !tok.pwlen || !tok.type) {
                    toast.error(strings.INVALID_TOKEN, { autoClose: 8000 });
                    this.setState({ passwordToken: "" });
                }

            } catch (e) {
                toast.error(strings.INVALID_TOKEN, { autoClose: 8000 });
                this.setState({ passwordToken: "" });
            }
        }

        this.getUser();
    }

    public validateForm() {
        return this.state.userid.length > 0 && this.state.password.length > 0;
    }

    public handleChangeId = (event) => {
        this.setState({
            userid: event.target.value,
        });
    }

    public handleChangePassword = (event) => {
        this.setState({
            password: event.target.value,
        });
    }
    public handleChangeConfirmPassword = (event) => {
        this.setState({
            confirmPassword: event.target.value,
        });
    }

    public getUser = async () => {
        try {
            const response: any = await Whoami();

            if (response.user) {
                window.location.href = appBaseUrl;
            }

            if (this.state.passwordToken) {
                const resp2 = await this.postPasswordReq();

                if (resp2.status !== 200) {
                    this.setState({ passwordToken: "" });
                    toast.error(strings.INVALID_TOKEN, { autoClose: 8000 });
                }
            }

            this.setState({ showContent: true });

        } catch (e) {
            window.location.href = appBaseUrl;
        }
    }

    public handleKeyPress = (e) => {
        if(e.key === "Enter") {
            this.handleSubmit(e);
        }
    }

    public postPasswordReq = async (password?: string) => {

        const response = await fetch("/reset_password_token", {
            credentials: "same-origin",
            headers: headerContentTypeJson,
            method: "POST",
            body: JSON.stringify({
                password: password || undefined,
                password_token: this.state.passwordToken,
            }),
        });
        await response.text();
        return response;
    }


    public handleSubmit = async (event) => {

        if (this.state.passwordToken) {

            try {

                const response = await this.postPasswordReq(this.state.password);
                if (response.status === 200) {
                    window.location.href = appBaseUrl + "/SignIn";
                    return;
                }

            } catch (e) {
                 console.log(e.message);
             }

            // default error
            toast.error(strings.PASSWORD_RESET_FAILED, { autoClose: 3000 });

        } else {

            try {
                const response = await fetch("/reset_password_email", {
                    credentials: "same-origin",
                    headers: headerContentTypeJson,
                    method: "POST",
                    body: JSON.stringify({ userid: this.state.userid }),
                });
                await response.text();

                if (response.status === 200) {
                    toast.success(strings.EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE, { autoClose: 3000 });
                    window.location.href = "/";
                    return;
                }
             } catch (e) {
                 console.log(e.message);
             }

            // default error
            toast.error(strings.PASSWORD_RESET_FAILED, { autoClose: 3000 });

        }
    }

    public passwordComplyComplex() {
        const pw = this.state.password;
        return this.state.pwcnt <= (pw.match(/[a-z]/) ? 1 : 0) + (pw.match(/[A-Z]/) ? 1 : 0) +
                    (pw.match(/[0-9]/) ? 1 : 0) + (pw.match(/[- !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/) ? 1 : 0);
    }
    public passwordComplyLength() {
        return this.state.pwlen <= this.state.password.length;
    }
    public validPassword() {
        return this.state.confirmPassword === this.state.password && this.passwordComplyComplex() && this.passwordComplyLength();
    }

    public validUserid() {
        return this.state.userid.length >= 6;
    }

    public render() {
        return (

            <Container fluid className="background-login">
                <div className="d-flex align-items-center justify-content-center" style={{height: "500px"}}>
                    <Container>
                        <div className="row  login-wrapper justify-content-center">
                            <div className="col-md-4 form-signin">
                                <img className="img-responsive img_padding_bottom" src={orbiwiseLogo} />


                            {/*--------------------------------------------------------------------------
                              * Show two password fields to set the password
                              *--------------------------------------------------------------------------*/}

                            {this.state.passwordToken &&  <div className="Login">

                                <div className="form-resetpassword-message"
                                    dangerouslySetInnerHTML={{__html: strings.formatString(this.state.type === "welcome" ?
                                                strings.RESET_PASSWORD_WELCOME_USER :
                                                strings.RESET_PASSWORD_MESSAGE,
                                                { userid: this.state.userid }) as string}}>
                                </div>

                                <form autoComplete={"off"}>
                                    <div className="user-inner-addon mb-3">
                                        <input type="text" className="form-control" placeholder={strings.USER_ID}
                                            value={this.state.userid} disabled={true}>
                                        </input>
                                    </div>

                                    <div className="user-inner-addon mb-3">
                                        <input type="password" autoComplete={this.state.auco} onKeyPress={this.handleKeyPress} className="form-control" autoFocus
                                            placeholder={strings.PASSWORD} value={this.state.password}
                                            onChange={this.handleChangePassword}></input>
                                    </div>
                                    {this.state.password && (!this.passwordComplyLength() || !this.passwordComplyComplex()) && <FormText>
                                        {!this.passwordComplyLength() && (
                                            <div className="schema-modal-error-text"> 
                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                                <span className="size_sma color_text font_size_11 ms-1">
                                                    {strings.RESET_PASSWORD_TOO_SHORT}
                                                </span>
                                            </div>
                                        )}
                                         {!this.passwordComplyComplex() && (
                                          
                                          <div className="schema-modal-error-text">
                                                 <FontAwesomeIcon icon={faCircleExclamation} />
                                                <span className="size_sma color_text font_size_11 ms-1">
                                                    {strings.RESET_PASSWORD_NOT_COMPLEX}
                                                </span>
                                            </div>
                                        )}
                                   </FormText>}

                                    <div className="user-inner-addon mb-3">
                                        <input type="password" autoComplete={this.state.auco}
                                            onKeyPress={this.handleKeyPress} className="form-control"
                                            placeholder={strings.CONFIRM_PASSWORD} value={this.state.confirmPassword}
                                            onChange={this.handleChangeConfirmPassword}></input>
                                    </div>

                                    {/* Show password errors if any */}
                                    {this.state.confirmPassword && this.state.password !== this.state.confirmPassword && this.passwordComplyLength() && this.passwordComplyComplex() &&
                                        <FormText>
                                    
                                    <div className="schema-modal-error-text">
                                                 <FontAwesomeIcon icon={faCircleExclamation} />
                                            <span className="size_sma color_text font_size_11 ms-1">
                                                {strings.RESET_PASSWORD_DOSNT_MATCH}
                                            </span>
                                        </div>
                                    </FormText>}

                                    <div className="pt-2 mt-4 d-grid">
                                    <button id="btnSignInSubmit"
                                        className="btn btn-dark" type="button" onClick={this.handleSubmit}
                                        disabled={!this.validPassword()}
                                    >
                                        {strings.SET_PASSWORD}
                                    </button>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <a href="/app/SignIn" className="returnLogin a-link">
                                                {strings.RETURN_TO_LOGIN}
                                            </a>
                                        </div>
                                    </div>

                                </form>
                            </div>}


                            {/*--------------------------------------------------------------------------
                              * Show username input to be able to request reset email
                              *--------------------------------------------------------------------------*/}


                            {!this.state.passwordToken && <div className="Login">

                                <form autoComplete={this.state.auco}>

                                    <div className="form-resetpassword-message"
                                        dangerouslySetInnerHTML={{__html:strings.FORGOT_PASSWORD_INSTRUCTIONS}}>
                                    </div>

                                    <div className="user-inner-addon">
                                        <i className="glyphicon glyphicon-user"></i>
                                        <input type="text" className="form-control" placeholder={strings.USER_ID}
                                            value={this.state.userid}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChangeId} autoFocus autoComplete={this.state.auco}></input>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 d-grid mt-5">
                                         
                                    <button id="btnSignInSubmit" 
                                            className="btn  btn-dark" type="button" 
                                            onClick={this.handleSubmit}
                                          //  disabled={!this.validUserid()}
                                    >
                                        {strings.SEND_RESET_PASSWORD_EMAIL}
                                    </button>
                                    </div>
                                  
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <a href="/app/SignIn" className="returnLogin a-link">
                                                {strings.RETURN_TO_LOGIN}
                                            </a>
                                        </div>
                                    </div>

                                </form>
                            </div>}

                            </div>
                        </div>

                    </Container>
                </div>
            </Container>
        );
    }
}
