import React, { useContext, useEffect, useState } from "react";
import MapContext from '../../context/MapContext'
import { GenericDassQuery } from "../../services/BasicDassQueries";
import Select, { StylesConfig } from 'react-select';
import { useLocation, useSearchParams } from "react-router-dom";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";


interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

interface optionState {
    label: string;
    value: string;
    color?: string;
}

interface IStateTagSelect {
    data:optionState[];
    filtered:optionState[];
}

const MapTagSelect = () => {

    const MapContextObj = useContext(MapContext);

    let DataTableContextObj = useContext(DataTableContext);

    const inputFields = DataTableContextObj.searchState.inputFields;
    
    let [searchParams, setSearchParams] = useSearchParams();
   
    const [mapDataState, setState] = useState<IStateTagSelect>({data: [],   filtered: []});

    const {state} = useLocation();
    //const [options, setOptions] = useState<optionState[]>(null);

    const loadOptions = async () => {
        
        let resposne = await GenericDassQuery("/rest/omc/tags");
        
        return resposne.data;

    };

    ///load  tags and populate select
    // useEffect(() => {

    //     async function m() {

    //         let results:any = await loadOptions();

    //         let data = results.map((r, i) => {

    //             return {value: r.tagid, label: r.tagName , color: r?.color}

    //         })
            
    //         setState(prevState => { return {...prevState, data: data }});
    //     }

    //     m();
        
    // },[])

    useEffect(() =>{
        async function f() {

            let filtered:any[] = [];

            let selectedTags  = [];
            if(searchParams.get("search_tags")) {

                selectedTags = searchParams.get("search_tags").split(",");
                searchParams.get("search_tags");
                setSearchParams({search_tags:searchParams.get("search_tags")});
            } else if(inputFields && inputFields.hasOwnProperty("search_tags")) {
                    selectedTags = inputFields.search_tags.split(",");
                    searchParams.get("search_tags");
                    setSearchParams({search_tags:inputFields.search_tags});
            }
        
            const NselectedTags = selectedTags.map((item) => parseInt(item));

            let results:any = await loadOptions();

            let data = results.map((r, i) => {
                return {value: r.tagid, label: r.tagName , color: r?.color}
            })

            filtered = data.filter(option =>  {  return NselectedTags.includes(option.value) });
            setState(prevState => { return {...prevState, data: data, filtered:[...filtered] }});

            loadGateways(filtered)
            
        }
        
        f();
        
    }, [state]);    

    const loadGateways = async ( selectedOption ) => {

        var tagArr = []
        
        selectedOption.map((item) => { 
            tagArr.push(item.value);
        })

        let url;

        if(tagArr.length > 0) {
            let tags = tagArr.join(",");

            url = `/rest/omc/gateways-geo?search_tags=${tags}`;

        }else {

            url = `/rest/omc/gateways-geo?untagged=false`;

        }

        let response = await GenericDassQuery(url);
        
        MapContextObj.updateGateWays( response.data )
        
    }
    
    const handleChange = async (selectedOption, actionMeta) => {
        
        loadGateways(selectedOption);

        setState(prevState => { return {...prevState, filtered:[...selectedOption] }});

        let searchArr = selectedOption.map((row, index) => {
            return row.value;
        })
        
        let searchStr = searchArr.join(",");

        if(selectedOption && selectedOption.length == 0) {
            setSearchParams({search_tags:""});
            DataTableContextObj.updateSearchFields("search_tags", "", "")
        } else {
            setSearchParams({search_tags:searchStr});
            DataTableContextObj.updateSearchFields("search_tags", searchStr, "")
        }
        
    };

    const handleInputChange = (inputValue: any, actionMeta: any) => {
        // console.groupEnd();
    };
    
    const colourStyles: StylesConfig<ColourOption, true> = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor:  '#FFFFFF',
            color: '#000',
          };
        },
        multiValue: (styles, { data }) => {
          const color = data.color;
          return {
            ...styles,
            backgroundColor: color ? color : '#c1c1c1',
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: '#000',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: '#000'
        }),
      };
    
      
    return (
        <Select 
            value={mapDataState.filtered}
            className="col-md-12 nst-map-tag-select"
            options={mapDataState.data}
            isMulti
            onChange={handleChange}
            onInputChange={handleInputChange}
            styles={colourStyles}
        />

    );
      
}


export default MapTagSelect;