import React from 'react';
import * as ReactDOM from 'react-dom';
//import * as ReactDOMServer from 'react-dom/server';
import { useLayoutEffect, useEffect,  useState, useRef, useContext  } from 'react';

import './DataTable.css';

import DataTableSearchComponent  from './DataTable/DataTableSearchComponent';

import { GenericDassQuery } from "../../services/BasicDassQueries";

import { isMobile } from 'react-device-detect';

import NoDataImage  from './DataTable/no-data-empty.png';
import { toast } from "../../utils/Toaster";
import { 
    changeDisplayOnClassName, 
    //elipsis 
} from '../../utils/filters';

import { pageHasGlobalSearchTagControl } from '../../pages/PagesConfig';

import LazyLocaleDatePicker  from "../../components/SchemaModal/LazyLocaleDatePicker";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
  } from 'date-fns';
  
declare const headerNoContentType;

import {    
    Form, 
    Dropdown, 
    ButtonGroup, 
    ToggleButton, 
    Table, 
    Pagination, 
    Modal, 
    Button,
    OverlayTrigger, 
    Tooltip /*Row, Col, Container*/ 
} from 'react-bootstrap';

import { FaSortUp, FaSortDown } from 'react-icons/fa';

import { TiArrowSortedDown } from 'react-icons/ti';

import { IconContext  } from 'react-icons';

import 'react-date-range/dist/styles.css'; // main style file

import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker, Range,} from 'react-date-range';


import oboe from 'oboe';

import { PIXEL_IN_CHAR,
        FIRST_COL_WIDTH,
        PAGES_IN_PAGINATION,
        MAX_ACTION_ICONS,
        DEFAULT_INPUT_LENGTH,
        DEFAULT_INPUT_VALIDATION,
        DEFAULT_RECORD_LIMIT
        
    } from './DataTable/DataTableConsts';


import { SpinnerHide, 
    SpinnerShow, 
    //TotalRecordHide, 
    TotalRecordShow,
    TotalWarningMessage
    
 } from "./../Common";

import {   PageButtonsComponentDataTable } from "./../Common/DataTable/PageButtonsComponentDataTable";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAnglesLeft, faAngleLeft, faAnglesRight, faAngleRight, faEllipsisV, } from '@fortawesome/pro-regular-svg-icons';

import { faCopy } from '@fortawesome/pro-regular-svg-icons';

import {faCircleEllipsis} from '@fortawesome/pro-light-svg-icons';

import { /*ipadWidth, windowWidth, desktopWidth,*/ appBaseUrl} from '../../utils/consts';

import { /*DataTableState,*/ DataTableContext, tableSettings} from "./DataTable/DataTableState";

 import { /*DataTableState,*/ DataTableChildContext } from "./DataTable/DataTableChildState";

import { ReactSelectSearch } from "./DataTable/ReactSelectSearch";

import { useLocation, useSearchParams  } from 'react-router-dom';

import  RecordLimitDropDown from "./DataTable/RecordLimitDropDown";

import {    
    ColumnType, 
    ActionType, 
    DataTableProps,
    AlignType,
    DataTableOption,
    ToggleOptionType
} from './DataTable/DataTypes';

//type rowIdType = string | number;

import AppContext from '../../context/AppContext'
import { faChevronCircleDown } from '@fortawesome/pro-solid-svg-icons';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';



const columnCheckBox:ColumnType = {
    key: 'bulk_action_checkbox',
    type: "bulk_action_checkbox",
    title: 'Bulk Action',
    filterable: false,
    newCellWidth: "2%"
};


const columnFieldPanelCollapseIcon:ColumnType = {
    key: 'field_panel_collapse_icon',
    type: "field_panel_collapse_icon",
    title: 'Collapse Icon',
    filterable: false,
    cellWidth: 3
};

const columnActionButton:ColumnType = {
    key: 'action_button',
    type: "action_button",
    title: 'Actions',
    filterable: false,    
    newCellWidth: "100px"
}

let globalScope;

const parseSearchField = (searchStr) => {
    let searchFields = {};
    if(searchStr) {
        //?search_deveui=16&search_comment=sim
        let search = String(searchStr).replace("?",'');
        let searchArr = search.split("&");

        for(let key in searchArr) {
            let fv = searchArr[key].split("=");
            let obj = {};
            obj[fv[0]] = fv[1];
            searchFields = {...searchFields,...obj};
        }
    }
    return searchFields;
}

const DataTable: React.FC<DataTableProps>  =  React.memo(({dataTableOption, refresh, countLabel, display, pageButtons, name, exportInit, scope }) => {
    
    const AppContextObj = useContext(AppContext);
    globalScope = (scope === 'child') ? 'child' : 'main';

    let [searchParams, setSearchParams] = useSearchParams();

    let DataTableContextObj = useContext(DataTableContext);
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    

    //this code will refresh the table when user will return from detail page after updating or addin any new record
    const { state, search } = useLocation();
    let searchFields = parseSearchField(search);


    dataTableOption.columns.map((column) => {
        if(column.hasOwnProperty('defaultFilterValue')) {
            searchFields[column.filterField] = column['defaultFilterValue']
        }
    })
    
    
    
    //set extrea search_tags propery if exist in prev page
    if(dataTableOption.hasOwnProperty('extraSearchProp') || pageHasGlobalSearchTagControl(name)) {
        const extraSearchProp  = dataTableOption['extraSearchProp'];
        if(DataTableContextObj.searchState.inputFields.hasOwnProperty(extraSearchProp.key)) {
            searchFields[extraSearchProp.key] = DataTableContextObj.searchState.inputFields[extraSearchProp.key];
        }
    }
    
    

    let upatedRowkeys = []
    if(state) {
        let updatedRow = state.hasOwnProperty('updatedRow') ? state['updatedRow'] : {};
        
        if(updatedRow) {
            upatedRowkeys = Object.values(updatedRow);
        }
    }
    /// end refresh

    useEffect(() => {
        const resetStateObj = {refresh: !DataTableContextObj.searchState.refresh } 
        
        DataTableContextObj.setSearchState(prev => {
            return {...prev, ...resetStateObj }
        })        

    },[upatedRowkeys[0]])

    //refresh will fetch the data again

    const refreshTable = () => {
        if(dataTableOption.hasOwnProperty('dataType')) {
            let newData = dataTableOption.data;
            DataTableContextObj.setSearchState(prev => {
                let prevDataTableOptions = prev.dataTableOption;
                const resetStateObj = {refresh: !prev.refresh, dataTableOption:{...prevDataTableOptions, data:newData,  selectedRowIds:[]} } 
                return {...prev, ...resetStateObj }

            })
        }else {
            const resetStateObj = {refresh: !DataTableContextObj.searchState.refresh, selectedRowIds:[]} 
            DataTableContextObj.setSearchState(prev => {
                return {...prev, ...resetStateObj }
            })
        }
    }

    useEffect(() => {
        refreshTable();

    },[refresh])
    
    useEffect(() => {
        refreshTable();
    },[AppContextObj.isForwardedSignIn(), AppContextObj.navBarState.forwardedSignIn])

    useEffect(() => {
        
        const resetStateObj = {exportInit: exportInit } 
        
        DataTableContextObj.setSearchState(prev => {
            return {...prev, ...resetStateObj }
        })        

    },[exportInit])

    useEffect(() => {
        resetDefaultState();

    },[name])

    useEffect(() => {
        if(state && state?.refreshRequired && name != 'my-select-devices' && name != 'my-firmwares') {
            
            resetDefaultState();
            if(dataTableOption.hasOwnProperty('dataType')) {
                let newData = dataTableOption.data;
                DataTableContextObj.setSearchState(prev => {
                    let prevDataTableOptions = prev.dataTableOption;
                    const resetStateObj = {refresh: !prev.refresh, dataTableOption:{...prevDataTableOptions, data:newData, selectedRowIds:[]} } 
                    return {...prev, ...resetStateObj }
    
                })
            }else {
                const resetStateObj = {refresh: !DataTableContextObj.searchState.refresh , selectedRowIds:[]} 
                DataTableContextObj.setSearchState(prev => {
                    return {...prev, ...resetStateObj }
                })
            }
        }        

    },[state, name])

    const getDataFromLocalStorage = (key: string) => {
        try {
            const item = JSON.parse(localStorage.getItem(key)) || null;
            if(item) {
                return item;
            }
        } catch (e) {
            console.log(e.message, key, "'" + localStorage.getItem(key) + "'" );
        }
        return null;
    }

    const isDisplaySelectedRowsData = () => {
        if(DataTableContextObj.name === name){
            return true;
        }
        return false;
    }

    const resetDefaultState = () => {
        if(dataTableOption.hasOwnProperty('columns') && dataTableOption.columns.length > 0) {
    
            dataTableOption.columns.map((row, item) => {

                if(row.key === 'bulk_action_checkbox') {
                    bulk_action_found = true;
                }
    
                if(row.key === 'action_button') {
                    action_button_found = true;
                }
    
                if(row.key === 'field_panel_collapse_icon') {
                    field_panel_collapse_icon_found = true;
                }
    
            })
    
            //if bulkActions are exists and checkbox column does not then we need to push bulk action checkbox column at the first
            if(bulk_action_found === false  && dataTableOption.bulkActions && dataTableOption.bulkActions.length > 0 ) {
                dataTableOption.columns.unshift(columnCheckBox);
            }
            if(bulk_action_found === false  &&  dataTableOption?.isDisplayMobile){
                dataTableOption.columns.unshift(columnCheckBox);
            }

            if(bulk_action_found === false  && dataTableOption.bulkActions && dataTableOption.bulkActions.length === 0 && field_panel_collapse_icon_found === false && dataTableOption.dataType !== 'memory' ) {
                
                dataTableOption.columns.unshift(columnFieldPanelCollapseIcon);
    
            }
            
            //if Actions are exists and action column defiation is missing then we need to push action button column at the end
            if(action_button_found === false && dataTableOption?.actions?.length > 0) {
                dataTableOption.columns.push(columnActionButton);    
            }

            // let visibleColumns = dataTableOption['columns'].filter((row) => {
            //     return row.key;
            // })

            const visibleColumnsArray = dataTableOption['columns'].filter((item) => {
                
                if(item.hasOwnProperty('enabledInColumn')) {
                    return item['enabledInColumn'];
                }else {
                    return true;
                }

            })

            let selectedColumns = DataTableContextObj.searchState.visibleColumns;
            // defaultFilterValue
            let visibleColumns = visibleColumnsArray.map(function(row, index) {
                return row.key;
            })
            const menuLimits = JSON.parse(localStorage.getItem("menuLimits")) || null;
            let limit =  DEFAULT_RECORD_LIMIT;
            let stream = dataTableOption.query_param.stream;
            let isUpdate = undefined;
            if(stream !== "memory"){
                if(searchParams.get("limit")) {
                    limit = parseInt(searchParams.get("limit"));
                } else if(menuLimits) {
                    const index = menuLimits.findIndex((menu) => menu.url == location.pathname);
                    if(index != -1){
                        limit = menuLimits[index].limit
                    }
                } else {
                    limit =  DEFAULT_RECORD_LIMIT;
                }
            }
           
            if(selectedColumns && selectedColumns.length && isDisplaySelectedRowsData()){
                visibleColumns = [...selectedColumns]
            }
            let selectedRows = []
            let selectedDevices =  state?.hasOwnProperty("selectedDevices") ? state['selectedDevices'] : getDataFromLocalStorage('selectedDevices');
            let selectedFirmware =  state?.hasOwnProperty("selectedFirmware") ? state['selectedFirmware'] : getDataFromLocalStorage('selectedFirmware');
            if(name == "my-select-devices" && selectedDevices && selectedDevices.length){
                selectedRows = [...selectedDevices]
            } 
            if(visibleColumns.includes("mac_msg")){
                isUpdate = true;
            }
            if(name == "my-firmwares" && selectedFirmware && selectedFirmware.length){
                selectedRows = [...selectedFirmware]
            } 
            
            const resetStateObj = {
                dataTableOption:{
                    ...dataTableOption,
                    query_param:{...dataTableOption.query_param, mac_msg:isUpdate}
                },
                exportInit:exportInit,
                refresh:refresh,
                countLabel:countLabel,
                display:display,
                name: name,
                visibleColumnsArr:[],
                page: '',
                pageStates: [],
                limit: limit,
                searchFields: searchFields,
                allRowIds:[],
                selectedRowIds:[...selectedRows],
                inputFields:searchFields,
                smallScreen:false,
                responsiveColumns:[],
                visibleColumns: visibleColumns,
                initTableWidth:0,
                initColumnWidth:[],
                calcColumnCharLenArr:[],
                totalRecords:0,
                allowBulkActions: true,
                loading:false,
                sort: {sortField:'', sortOrder:'asc'},
                queryType:'',
                debug:false,
                scope: scope,
                searchParams,
                setSearchParams,
                allCheckboxSelected: (dataTableOption['allCheckboxSelected']) ? dataTableOption['allCheckboxSelected'] : false
            } 

            DataTableContextObj.setSearchState(prev => {
                return {...prev, ...resetStateObj }
            })
            DataTableContextObj.setBulkActionChecked(false);
        }
    }

    //chekcing if bulk action checkbox and action button is passed by users in the dataTableOptions
    
    let bulk_action_found = false;
    
    let action_button_found = false;
    
    let field_panel_collapse_icon_found = false;

    
    //let dataType = (dataTableOption.hasOwnProperty('dataType')) ?  dataTableOption['dataType'] : '';

    if(DataTableContextObj.searchState.dataTableOption.hasOwnProperty('columns') && DataTableContextObj.searchState.dataTableOption.columns.length > 0 && DataTableContextObj.searchState.name === name) {
        return (<>
            <DataTableStart refresh={refresh}  dataTableOption={dataTableOption} pageButtons={pageButtons} name={DataTableContextObj?.name} />
        </>)
    }else {
        return null;
    }
})


const DataTableStart: React.FC<DataTableProps>  = React.memo(({ dataTableOption, pageButtons, name}) => {
    
    //const [isFetching, setFetching] = useState(false);
    //const initColumnWidthRef = useRef([]);
    
    const tabelRef = useRef();
    const appContextObj = useContext(AppContext);
    let render  = false;
    if(dataTableOption.url ) {
        render = true;
    }

    //let  availHeight = 'auto' as string | undefined;
  
    // const smallScreen = windowWidth() < ipadWidth;

    useEffect(() => {
        
        function resizeHeight () {
            try {
                let  height = window.innerHeight;// + //document.getElementById("ob-data-table-main-container").offsetHeight;
                let navbarHeight = 0;
                var element:any = document.getElementById('debug-checkbox');
                if(height > 0) {

                    let elem:any = [];
                    elem = document.getElementsByClassName("besides-data-table");
                    if(element.checked) {
                        console.log(elem.length);
                    }

                    if(elem) {
                        for (var j = elem.length - 1; j >= 0; j--) {
                            if(element.checked) { console.log(elem[j].offsetHeight + " " + elem[j].id);}
                            
                            navbarHeight = navbarHeight + elem[j].offsetHeight;
                        };
                    }

                    if(document.getElementById("ob-data-table-footer")) {
                        navbarHeight = navbarHeight + document.getElementById("ob-data-table-footer").offsetHeight;
                    }
                    
                    // if(document.getElementById("page-header")) {
                    //     navbarHeight = navbarHeight + document.getElementById("page-header").offsetHeight;
                    // }

                    let totalHeightToSubs = (navbarHeight)
                
                    if(element.checked) {
                        console.log("height of window " + height);
                        console.log("Total Height To Subtract " + totalHeightToSubs);
                    }
                    //height += 5;
                    //document.getElementById("ow-data-table-container").style.height = (height - (totalHeightToSubs)) + 'px';
                    //document.getElementById("empty-data-table-row").style.height = (height - (totalHeightToSubs)) + 'px';
                }
            } catch(e) {
                //console.log(e)
            }
        }
        
        resizeHeight();

        window.addEventListener('resize', resizeHeight);
        return () => {
            window.removeEventListener('resize', resizeHeight);
        }
    },[])

    let paginationWrapper = 'pagination-wrapper';
    if(dataTableOption.hasOwnProperty('paginationWrapper') && dataTableOption['paginationWrapper'] !== '') {
        paginationWrapper = dataTableOption['paginationWrapper'];
    }

    
    let displayDebug = 'block';
    if(dataTableOption.hasOwnProperty('enableDebug')) {
        let enableDebug = dataTableOption['enableDebug'];
        if(enableDebug) {
            displayDebug = 'block';
        }else {
            displayDebug = 'none';
        }
    }
    const user = appContextObj?.user || null; 
    const impersonatedClass = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )? 'ow-data-table-container-impersonated' : 'ow-data-table-container'
    return (
        <div style={{width:'100%', height: '100%', border: '0px solid'}}>
            {
                    pageButtons && 
                    <div  className="col-lg-12 col-md-auto col-xs-12 d-flex justify-content-md-end mb-2 me-2 ">
                        <PageButtonsComponentDataTable scope={globalScope} pageButtons={ pageButtons } columns={dataTableOption.columns} />
                    </div>
            }
            <IconContext.Provider value={{ color: "dark" }} >
                <div style={{boxShadow: '0 2px 6px 0 rgba(67,89,113,.12)', display: 'flex', borderRadius: '10px', border: '1px solid rgba(0, 0, 0, 0.175)', flexDirection: 'column', height: '100%' }} id="ob-data-table-main-container" > 
                    <div   style={{height: '100%',borderRadius: '10px 10px 0px 0px', overflow :'auto', backgroundColor: '#FFFFFF' }} id="ow-data-table-container" className={impersonatedClass}> 
                        <Table ref={tabelRef} className="table-light data-table-custom mb-0" id="data-table-id" >
                            <thead  id="data-table" style={{position: 'sticky', overflow:"auto", zIndex: '999', top: '-1px' }}>
                                <DataTableRenderFilter />
                            </thead>
                            <tbody>
                            {render && <DataTableBody />}
                            </tbody>        
                        </Table> 
                    </div>
                    <div className="debug-value" id="all-extra-debug-value">

                    </div>
                    
                    <div id="ob-data-table-footer" style={{borderRadius:'0px 0px 10px 10px' }} className={`${name == "omc-devices" ? "justify-content-between" : "justify-content-end"} table-footer  d-flex flex-row  align-items-center besides-data-table`}> 
                        {name == "omc-devices" && <div id="warning-message" className='m-1 w-50'></div>}
                        <div className='d-flex flex-row align-items-top m-1 border-2 border-black'  >
                            <div className={`d-flex flex-row justify-content-center align-items-top mt-2 d-none ${displayDebug}`} >
                                <DebugCheckBox />
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-top mt-2 ms-2' >
                                {render && <RecordLimitDropDown globalScope={globalScope} />}
                            </div>

                            <div className={`d-flex flex-row justify-content-end align-items-top  mt-2 ms-2 ${paginationWrapper}`} >
                                {render && <PaginationComponentsV2 /> }
                                {/* {render && smallScreen  && <PaginationMobileComponentsV2  />} */}
                            </div> 
                            {/* <ClearFormComp /> */}
                        </div> 
                    </div>
                </div>
                </IconContext.Provider>
        </div>
    )
})

export { DataTable };


const DebugCheckBox = () => {
    
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    const toggleDebug = (e: any) => {
        e.stopPropagation();
        
        if(e.target.checked ) {
            changeDisplayOnClassName("debug-value", "block");
        }else {
            changeDisplayOnClassName("debug-value", "none");
        }

        DataTableContextObj.setSearchState(prevState => { return {...prevState, debug: e.target.checked} })
    }
    
    
    let display = (!isMobile) ? (DataTableContextObj.searchState.dataTableOption.dataType !== 'memory') ?  'flex' : 'none' : 'none';

    return (
        <div style={{display: display, justifyContent: 'space-between'}}>
            <label htmlFor="debug-checkbox">Debug</label>
            <div style={{marginLeft: 10}}>
                <Form.Check  type={`checkbox`} id={`debug-checkbox`} onChange={toggleDebug}/>
            </div>
        </div>
    )
    
}

interface ItemSortProps {
    sortOrder:string;
}

const ItemSort: React.FC<ItemSortProps> =  React.memo(( { sortOrder} ) => {
    
    if(sortOrder === '') {
        return (<div style={{display:'flex', flexDirection: "column", justifyContent :'center', alignItems :'center'}}>
                    <div style={{ position:'relative', width:'20px', height:'20px' }}>
                        <div style={{position: 'absolute'}}>
                            <FaSortUp  />
                        </div>
                        <div style={{position: 'absolute'}}>
                            <FaSortDown />
                        </div>
                    </div>
                    
                </div>)
    }else if(sortOrder === 'asc') {
        return (<div><TiArrowSortedDown  /></div>)
    }else  {
        return (<div><FaSortUp  /></div>)
    }
})

interface ColumnNameItemProps {
    column: ColumnType
}

const ColumnNameItem: React.FC<ColumnNameItemProps> = ({ column  }) => {
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    
    let sort = DataTableContextObj.searchState.sort;
    
    let sortKey = (column.sortKey) ? column.sortKey : column.key;

    let sortOrder = '';
    
    if(sort.sortField === sortKey) {
        sortOrder = sort.sortOrder;
    }else {
        sortOrder = '';
    }

    let toolTip:any = null;
    if(column.hasOwnProperty('header_tooltip')) {
        toolTip = column.header_tooltip;
    }

    const handleSort = ( order: string, column: ColumnType ) => {
            
        let newOrder = order === 'asc' ? 'desc' : 'asc';
        

        DataTableContextObj.setSearchState((prevSearchState: any) => {  
            
            return {...prevSearchState, sort:{sortOrder:newOrder, sortField:sortKey}, queryType:'sort', page:'', pageStates:[]} 

        });
        
    }

    const renderToolTip = () => {
        return (
        <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
            <Tooltip>
            {toolTip}
            </Tooltip>
        }
        >
            <div className="p-1 text-break" >
                {column.title}
            </div>
     </OverlayTrigger>)
    }

    let cursorClass = (column.sortable) ? 'pointer' : 'default';

    if(column.sortable) {
        return (<div id={column.key}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center" style={{cursor: cursorClass}} onClick={ () => handleSort(sort.sortOrder, column) }>
                    {toolTip ? renderToolTip() : <div className="p-1 text-break">
                            {column.title}
                    </div>}
                    <div className="p-1"  onClick={ () => handleSort(sort.sortField, column) }>
                        <ItemSort sortOrder={sortOrder} />
                    </div>
                </div>
            </div>
        </div>)
    }else {
        return (<div id={column.key}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center"  >
                {toolTip ? renderToolTip() : <div className="p-1 text-break">
                            {column.title}
                    </div>}
                </div>
            </div>
        </div>)
    }
   
}


interface DataTableRenderFilterProps {
      
}

const DataTableRenderFilter: React.FC<DataTableRenderFilterProps> =  React.memo(() => {
    
    let DataTableContextObj;
    let elemIds:any[] = [];
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    const searchState =  DataTableContextObj.searchState;

    const [showColumn, setShowColumn] = useState(false);

    const [columnWidths , setColumnWidths] = useState<any[]>([]);
    

    let dataTableOption = DataTableContextObj.searchState.dataTableOption;

    let hideBulkActions = dataTableOption.hasOwnProperty("hideBulkActions") ? dataTableOption['hideBulkActions'] : false;

    const filterdColumns = dataTableOption.columns.filter(item => {
        return item.type !== 'merged';
    })

    
    //let isAllFieldVisible  = DataTableContextObj.isAllFieldVisible();
    
    const toggleShowColumn = ( e ) => {
        e.stopPropagation();
        e.preventDefault();
        setShowColumn((oldShow) => {return !showColumn});
    }


    // useLayoutEffect(() => {
        
    //     let totalCellWidth = 0;

    //     filterdColumns.map(function(column, index) {
    //         if(!column.cellWidthType &&  column.cellWidth > 0) {
    //             totalCellWidth = totalCellWidth + (column.cellWidth * 8);
    //         }
    //     })

    //     //DataTableContextObj.setColumns(dataTableOption.columns);

    //     DataTableContextObj.setTotalCellWidth(totalCellWidth);

    //     // let availWidth = window.screen.availWidth;

    // },[])
    
    let display = (showColumn) ? '' : 'none';

    let totalColumns = filterdColumns.length + 1;


    useLayoutEffect(() => {
        window.addEventListener('resize', function(e) {
            setShowColumn(false);
        });

    }, [])


    useEffect(() => {
        let columnWidths = [];
        
        elemIds.map((e, index) => {

            if(document.getElementById(e)) {
                let offsetWidth = document.getElementById(e).offsetWidth;
                let cssWidth = document.getElementById(e).style.width;
                columnWidths[e] = (cssWidth !== 'auto') ? cssWidth : offsetWidth;
            }
        })

        setColumnWidths(prev => columnWidths);
    },[])

    const sr = dataTableOption.serial_number;

    let columns = filterdColumns;

    let responsiveColumns = searchState.responsiveColumns;

    //get the columns in array that are behide the right side
    let columnIndex = 0;
    var keys = Object.keys( columns );
    let columnRowArray = [];
    for(let i = 0; i < columns.length; ++i) {
        columnIndex = responsiveColumns[i];
        let v = columns[keys[columnIndex]];
        if(v) {
            columnRowArray.push(v.key);
        }
    }
   // let flexDirection = (DataTableContextObj.smallScreen) ? "flex-column" : "flex-column"
    
    let totalCellWidth = DataTableContextObj.totalCellWidth;

    let clientWidth = document.body.clientWidth;

    //const remCellWidth = (clientWidth - (totalCellWidth + FIRST_COL_WIDTH + LAST_COL_WIDTH ));// / clientWidth; //

    const remCellWidth = (clientWidth - (totalCellWidth ));// / clientWidth; //
    
    const RenderBulkActionHeaderCheckboxWrapper = () => {
        return (<div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                    <div style={{ padding: 5}}>
                        <RenderBulkActionHeaderCheckbox   />                                
                    </div>    
                </div>)
    }

    
    //renders the horizontal line of header
    const filterList = filterdColumns.map((column:ColumnType, index:number) => {

        let headerAlign: AlignType =  "align-items-start";


        if(column.filterType && column.filterType === 'boolean_toggle') {
            headerAlign = "align-items-center";
        }

        if(column.type === 'boolean') {
            headerAlign = "align-items-center";
        }

        if(column.hasOwnProperty("dataAlign")) {
            if(column.dataAlign === 'center') {
                headerAlign =  "align-items-center"
            }else if(column.dataAlign === 'right') {
                headerAlign =  "align-items-end";
            } else if(column.dataAlign === 'left') {
                headerAlign =  "align-items-start";
            }
        }

        let pos = searchState.visibleColumns.indexOf(column.key);
        //let pos2 = columnRowArray.indexOf(column.key);
        
        let chrWidth = getCalcCharWidth(column, remCellWidth  );
        let modal = DataTableContextObj.modal;
        
        //if(pos > -1 && pos2 === -1) {
        if(pos > -1) {
            //let hidden = (pos2 === -1) ? "" : "d-none";
            if(elemIds.indexOf(column.key) == -1) {
                elemIds.push(column.key)
            }
            
            let savedColWidth = 0;

            if(columnWidths[column.key] && isMobile === false) {
                savedColWidth = columnWidths[column.key];
            }
            
            if(!modal && column.type === 'bulk_action_checkbox' ) {

                if( DataTableContextObj.searchState.dataTableOption?.bulkActions?.length > 0 || isMobile) {
                    
                    let width = (savedColWidth > 0) ?  savedColWidth : (chrWidth.width === 'auto') ? FIRST_COL_WIDTH +  'px' : chrWidth.width;

                    width = column?.newCellWidth || "auto";
                    return (<th align="center" scope="col"  key={index} id={column.key} className={`${column.key} tableHeader first_column`} style={{width : width, minWidth: width, maxWidth: width}}>
                        <div>
                            {DataTableContextObj.searchState?.allowBulkActions && DataTableContextObj.searchState?.dataTableOption.bulkActions?.length > 0 && RenderBulkActionHeaderCheckboxWrapper()}
                            <div style={{ padding: 10, display: isMobile ? 'block': 'none'}} id="RenderFilterPanelIcon">
                                <RenderFilterPanelIcon showColumn={showColumn} toggleShowColumn={toggleShowColumn} />
                            </div>
                            <p className="debug-value" id={`debug-${column.key}`}></p>
                        </div>
                    </th>) 
                }else {
                    return null;
                }
                
            }else if(column.type === 'field_panel_collapse_icon' && dataTableOption.dataType !== 'memory') {
                if( DataTableContextObj.searchState.dataTableOption?.bulkActions?.length > 0) {
                    let width = column?.newCellWidth || "auto";

                    return (<th scope="col" align="center"  key={index} id={column.key} className={`${column.key} tableHeader first_column`} style={{minWidth: width, maxWidth: width, width:width}}>
                            <div  style={{ padding: 10, display: isMobile ? 'block': 'none'}} id="RenderFilterPanelIcon">
                                <RenderFilterPanelIcon showColumn={showColumn} toggleShowColumn={toggleShowColumn} />
                            </div>
                            <p className="debug-value" id={`debug-${column.key}`}></p>
                        </th>)
                }else {
                    return null;
                }

            } else if(column.type === 'action_button' && hideBulkActions === false) {
                
                const actionButtonWidth = isMobile ? "70px" : "100px";
                return (<th id={column.key} key={index} className={`${column.key} last_column tableHeader `} style={{width: actionButtonWidth, minWidth: actionButtonWidth, maxWidth: actionButtonWidth}}>
                                <div className='p-1' style={{ textAlign: 'center',fontSize: "24px"}}>
                                <FontAwesomeIcon icon={faCircleEllipsis} />
                                </div>  
                            <p className="debug-value" id={`debug-${column.key}`}></p>
                        </th>)
            }else if(column.key === 'action_button'  && hideBulkActions === true ) {
                return null
            } else if (hideBulkActions === true && modal && column.key === 'bulk_action_checkbox') {
                return <th><div></div></th>
            } else {
                let width = column?.newCellWidth || "auto";
                return (
                    <th  key={index} id={column.key} className={`${column.key} tableHeader `} style={{width:width, minWidth: width, maxWidth: width, whiteSpace: (chrWidth.whiteSpace === 'nowrap') ? 'nowrap' : 'normal', minHeight: '40px'}}>
                        <div  style={{ whiteSpace: (chrWidth.whiteSpace === 'nowrap') ? 'nowrap' : 'normal', overflow:'hidden'}} className={`${headerAlign} d-flex flex-column w-100`}> 
                            <ColumnNameItem column={column} />
                            {column.filterable && <SearchField column={column} />}
                        </div>
                        <p className="debug-value" id={`debug-${column.key}`}></p>
                    </th>

                    
                )
            }
        }else {
            return null;
        }
    })

    const PanelfilterList = filterdColumns.map((column:ColumnType, index:number) => {

        let pos = searchState.visibleColumns.indexOf(column.key);
        //let pos2 = columnRowArray.indexOf(column.key);

        //if(pos > -1 && pos2 > -1 && column.filterable) {
        if(pos > -1 && column.filterable) {
            return (
                <tr key={index} style={{marginTop:10, backgroundColor: '#FFFFFF'}} id={`filter-${column.key}`}>
                    <td style={{padding:10}} >
                        <ColumnNameItem column={column} />
                    </td>    
                    <td style={{padding:10}} align="left">
                        <SearchField column={column} />
                    </td>
                </tr>
            )
        }else {
            return null;
        }
    })
   
    //calculate the total of the column width if given in pixel

    useEffect(() =>  {
        //this function will call when user will resize the window
        const resizeListner = (e) =>  {
            DataTableContextObj.resizeCalJs( dataTableOption.columns );

        }

        //var doit;
        window.addEventListener('resize', resizeListner);

        //calculate the total of the column width if given in pixel
        // setInitTableWidth(dataTableOption.columns);

        //remove resizelistern when un mount
        return () => window.removeEventListener('resize', resizeListner);

    },[])

   

    return (<>
            <tr key="1" className="thead-tr-class" style={{top: '-1px'}}>
                {sr && <th key="sr"></th>}
                {filterList}
            </tr>
            <tr  key="2" style={{display: display, backgroundColor:'#ffffff'}} >
                <td colSpan={totalColumns} >
                    <table>
                        <tbody>
                            {PanelfilterList}   
                        </tbody>
                    </table>
                </td>
            </tr>
            </>
        )
    

})


interface RenderBulkActionHeaderCheckboxProps {

}

const RenderBulkActionHeaderCheckbox: React.FC<RenderBulkActionHeaderCheckboxProps> =  React.memo(() => {

    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    const [allChecked, setAllChecked] = useState(false);
    
    const allRowIds = DataTableContextObj.searchState.allRowIds;
    const selectedRowIds = DataTableContextObj.searchState.selectedRowIds
    const idField = DataTableContextObj.searchState.dataTableOption.hasOwnProperty('id_field') ? DataTableContextObj.searchState.dataTableOption.id_field : null;

    const setBulkActionCheckedHandler = (checked: boolean) => {
        //when bulk action is checked 
        if(checked === true) {
            DataTableContextObj.setBulkActionChecked(prechecked => checked);
            const selectedRowsData = allRowIds.filter(element => selectedRowIds.findIndex((row) => row[idField] == element[idField]) == -1);
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...selectedRowIds,...selectedRowsData] } });
        }else {
            const selectedRowsData = selectedRowIds.filter(element => allRowIds.findIndex((row) => row[idField] == element[idField]) == -1);
            DataTableContextObj.setBulkActionChecked(prechecked => checked);
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...selectedRowsData] } });
        }
    }



    const selectAll = (e: any) => {
        e.stopPropagation();
        setAllChecked(e.target.checked)
        setBulkActionCheckedHandler(e.target.checked);
    }

    useEffect(() => {
        if(selectedRowIds && selectedRowIds.length && allRowIds.length <= selectedRowIds.length) {
            const idField = DataTableContextObj.searchState.dataTableOption.hasOwnProperty('id_field') ? DataTableContextObj.searchState.dataTableOption.id_field : null;
            if(idField) {
                const selectedRowsData = allRowIds.filter(element => selectedRowIds.findIndex((row) => row[idField] == element[idField]) != -1);
                if(selectedRowsData && selectedRowsData.length && allRowIds.length == selectedRowsData.length) {
                    setAllChecked(true);
                } else {
                    setAllChecked(false);
                }
            }else {

                setAllChecked(false);
            }
        } else if (selectedRowIds && selectedRowIds.length == 0) {
            setAllChecked(false);
        } else {
            setAllChecked(false);
        }
    },[selectedRowIds, allRowIds]);

    useEffect(() => {
        setAllChecked(DataTableContextObj.searchState.allCheckboxSelected)
        setBulkActionCheckedHandler(DataTableContextObj.searchState.allCheckboxSelected);
    },[])
    
    return (
        <Form.Check  type={`checkbox`} checked={allChecked} id={`default-checkbox`} onChange={selectAll}/>
    )
})


interface  SearchFieldProps {
    column: ColumnType;
    //searchHandler: (fieldName: string, fieldValue: string, column:columnType) => void;
}

const SearchField: React.FC<SearchFieldProps> =  React.memo(( { column } ) => {
    
    if(column.filterable  === false) {
        return null;
    }
    let filterWidth = column?.filterWidth || "auto"
    return (
        <div className="d-flex flex-row mb-1" style={{width: filterWidth, maxWidth: "180px", minWidth: filterWidth}}>
            {column.filterType === 'text' && <SearchFieldInput column={column} /> }
            {column.filterType === 'select' && <SearchFieldSelect  column={column} /> }
            {column.filterType === 'boolean_toggle' && <SearchBooleanFieldToggle  column={column} /> }
            {column.filterType === 'daterange' && <DateRangeRender  column={column} /> }
            {column.filterType === 'multiselect' && <ReactSelectSearch  column={column} scope={globalScope} /> }
            {column.filterType === 'date' && <DatePickerField column={column} scope={globalScope} /> }
            
        </div>
    )
})


interface DatePickerFieldProps {
    column: ColumnType;
    scope:string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ( props ) => {

    const column = props.column;
    
    //let filterField = (column.filterField) ? column.filterField : '';

    // fix me add place holder
    const [selDate, setSelDate] = useState(null);

    let DataTableContextObj;
    
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    

    const handleChange = ( date ) => {
        
        setSelDate(prevDate => date)

        let filterField = (column.filterField) ? column.filterField : '';

        DataTableContextObj.updateInputField(filterField, date, column);
        
        DataTableContextObj.updateSearchFields(filterField, date, column, true);
    }

    
    return (
      <LazyLocaleDatePicker placeholderText='Select' selected={selDate} onChange={(date:Date) => handleChange(date)} />
    );
}

interface DateRangeRenderProps {
    column: ColumnType;
    //searchHandler: (fieldName: string, fieldValue: any, column:columnType) => void;
}

interface DateRangeRenderState {
    show:boolean;
    emptyDate: Range[];
    selectedDate:Range[];
    dateRangeStr:string;
}

const DateRangeRender: React.FC<DateRangeRenderProps> = ({ column }) => {

    const emptyDate = {
        startDate: column.defaultFilterValue ? new Date(column?.defaultFilterValue[0]?.startDate) : new Date(),
        endDate: column.defaultFilterValue ? new Date(column?.defaultFilterValue[0]?.endDate) : new Date(),
        key: 'selection'
    }
    
    const initState = {
        show:false,
        emptyDate: [emptyDate],
        selectedDate:[emptyDate],
        dateRangeStr: ''
    }

    const [state, setState] = useState<DateRangeRenderState>(initState);

    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    //let urlValue = getUrlValue(DataTableContextObj, column.filterField);
 
    //Sun May 08 2022 00:13:10 GMT+0530 (India Standard Time)
    //Sun May 0 0000 00:00:00 GMT+0530 (India Standard Time)
    

      const setCalenderToggle = () => {

            setState(prevState => { 
                return {...prevState, show: !state.show}
            });
      }

      const handleClose = () => {

        setState(prevState => { 
            return {...prevState, show: false}
        });
      }

      /**
       * endDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        key: "selection"
        startDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        [[Prototype]]: Object
       * 
      */
    const setSelectedDateHandler = ( item ) => {
            
            let filterField = (column.filterField) ? column.filterField : '';
            DataTableContextObj.updateInputField(filterField, item, column);
            
            setState(prevState => { 
                return {...prevState, selectedDate: item}
            });
           
    }

    const applySearch = () => {
        
        let filterField = (column.filterField) ? column.filterField : '';
    
        //searchHandler(filterField, selectedDate, column);
        let selectedDate = DataTableContextObj.getFieldValue(column);
    
        DataTableContextObj.updateSearchFields(filterField, selectedDate, column);
        //DataTableContextObj.updateInputField(filterField, selectedDate, column)
        let startDate = new Date(String(state.selectedDate[0].startDate));

        let endDate = new Date(String(state.selectedDate[0].endDate));
        //2022-09-16T00%3A00%3A00.000Z
        // let dateRangeStr = format(startDate, 'yyyy-MM-dd') + " - " +  format(endDate, 'yyyy-MM-dd');
        let dateRangeStr =  startDate.toLocaleDateString() + " - " +  endDate.toLocaleDateString();

        setState(prevState => { 
            return {...prevState, show: !state.show, dateRangeStr: dateRangeStr}
        });

    }

    const clearSearch = () => {
        
        let filterField = (column.filterField) ? column.filterField : '';
        
        //searchHandler(filterField, selectedDate, column);
        //DataTableContextObj.updateInputField(filterField, null, column)
        DataTableContextObj.updateSearchFields(filterField, [], column);
        //DataTableContextObj.updateInputField(filterField, emptyDate, column);

        setState(prevState => {
            return {...prevState, show:!state.show, selectedDate:[emptyDate], dateRangeStr:''}
        });

    }
      
    
      
      //from_date=2021-12-16T00:00:00%2B05:30
      //2021-12-27 12:52:00 GMT+5:30 - 2021-12-30 12:52:00 GMT+5:30     

      ///format(new Date(2014, 1, 11), 'yyyy-MM-dd h:I:SS Z');
      //const formatPattern = 'yyyy-MM-dd h:I:SS z';
   
    //set default parameter from config

    useEffect(() => {
        
        if(column.defaultFilterValue) {

            let filterField = (column.filterField) ? column.filterField : '';
            
            let selectedDate = column.defaultFilterValue;
            DataTableContextObj.updateSearchFields(filterField, selectedDate, column);
            DataTableContextObj.updateInputField(filterField, selectedDate, column);
            let startDate = new Date(String(selectedDate[0].startDate));

            let endDate = new Date(String(selectedDate[0].endDate));
    
            let dateRangeStr =  startDate.toLocaleDateString() + " - " +  endDate.toLocaleDateString();
    
            setState(prevState => { 
                return {...prevState, dateRangeStr: dateRangeStr}
            });
            
        }

    },[]) 

    //set the parameter from url in the search field
    useEffect(() => {
        
        let filterField = (column.filterField) ? column.filterField : '';

        let selectedDate = DataTableContextObj.getInputFieldValue(column, filterField);

        if(selectedDate && selectedDate[0].startDate !== selectedDate[0].endDate ) {
            
            //2022-10-31T18:30:00.000Z

            const newStartDate = String(selectedDate[0].startDate).replace(/%3A/g,":")
            const newEndDate = String(selectedDate[0].endDate).replace(/%3A/g,":")

            let startDate = new Date(newStartDate);
    
            let endDate = new Date(newEndDate);
            let dateRangeStr =  startDate.toLocaleDateString() + " - " +  endDate.toLocaleDateString();

            
            setState(prevState => { 
                 return {...prevState, dateRangeStr: dateRangeStr}
             });
        }
    
    },[]) 
    
    const definedDates = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
      }

      const defaultInputRangesData = [
        {
          label: 'days up to today',
          range(value) {
            if(value){
                return {
                startDate: addDays(definedDates.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                endDate: definedDates.endOfToday,
                clearField: false,
                };
            }
        return { startDate: new Date(), endDate: new Date(), clearField: true, };
          },
          getCurrentValue(range:any) {
            if (range.clearField) return "";
            if (!isSameDay(range.endDate, definedDates.endOfToday)) return '-';
            if (!range.startDate) return '∞';
            return differenceInCalendarDays(definedDates.endOfToday, range.startDate) + 1;
          },
        },
        {
          label: 'days starting today',
          range(value) {
            const today = new Date();
            if(value){
                return {
                startDate: today,
                endDate: addDays(today, Math.max(Number(value), 1) - 1),
                clearField: false,
                };
            }
            return { startDate: today, endDate: new Date(), clearField: true, };
          },
          getCurrentValue(range:any) {
            if (range.clearField) return "";
            if (!isSameDay(range.startDate, definedDates.startOfToday)) return '-';
            if (!range.endDate) return '∞';
            return differenceInCalendarDays(range.endDate, definedDates.startOfToday) + 1;
          },
        },
      ];
      const DateRangePickerAny: any = DateRangePicker;  // FIXME:
      return (
            <div>
                <input type="text" 
                    className='form-control form-control-sm'
                    onClick={setCalenderToggle} 
                    onChange={ (e) => {  } }
                    value={state.dateRangeStr}  
                    placeholder={`Search ${column.title}`} />
                    <Modal
                        show={state.show}
                        onHide={handleClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Please select Date range
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <DateRangePickerAny
                                    onChange={item => setSelectedDateHandler([item.selection])}
                                    moveRangeOnFirstSelection={true}
                                    editableDateInputs={false}
                                    months={2}
                                    direction="horizontal"
                                    ranges={state.selectedDate}
                                    inputRanges={defaultInputRangesData}
                                    maxDate={new Date()}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="dark" onClick={applySearch}>Apply</Button>
                                <Button variant="outline-dark" onClick={clearSearch}>Clear</Button>
                                <Button variant="outline-dark"  onClick={setCalenderToggle}>Close</Button>
                            </Modal.Footer>
                        </Modal>
            </div>
        );
     
}


interface SearchBooleanFieldToggleProps {
    column: ColumnType;
    //searchHandler: (fieldName: string, fieldValue: string, column:columnType) => void;
}

const SearchBooleanFieldToggle: React.FC<SearchBooleanFieldToggleProps> =  React.memo(({ column  }) => {

    const [radioValue, setRadioValue] = useState<string>('');
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    
    useEffect(() => {

        let defaultFilterValue = (column.defaultFilterValue) ? column.defaultFilterValue : '';
        
        let filterField = (column.filterField) ? column.filterField : '';

        if(defaultFilterValue) {

            setRadioValue(state => defaultFilterValue);
            
            DataTableContextObj.updateSearchFields(filterField, defaultFilterValue, column, false);
        }
                 
    },[])
    

    const radios:ToggleOptionType[] = column?.toggleOptions;

      const inputHandler = (radio:any) => {
        if(radioValue == radio.value){
            setRadioValue(state => "")
            let filterField = (column.filterField) ? column.filterField : '';
            
    
            //searchHandler(filterField, radio.searchValue, column);
            DataTableContextObj.updateSearchFields(filterField, "", column);
        } else {
            setRadioValue(state => radio.value)
            let filterField = (column.filterField) ? column.filterField : '';
            
    
            //searchHandler(filterField, radio.searchValue, column);
            DataTableContextObj.updateSearchFields(filterField, radio.searchValue, column);
        }
        
    }

    return (
            <ButtonGroup  className="">
            {radios.map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    id={`${column.key}-${idx}`}
                    type="checkbox"
                    size="sm"
                    variant={'outline-dark'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => inputHandler(radio)}
                >
                    {radio.name}
                </ToggleButton>
            ))}
        </ButtonGroup>
    )
})


interface SearchFieldInputProps {
    column: ColumnType;
    //searchHandler: (fieldName: string, fieldValue: string, column:columnType) => void;
}




const SearchFieldInput: React.FC<SearchFieldInputProps> =  React.memo(( { column } ) => {

    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    
    let urlValue = DataTableContextObj.getUrlValue(DataTableContextObj, column.filterField);

    let maxInputLen = column.hasOwnProperty('maxInputLength') ? column.maxInputLength : DEFAULT_INPUT_LENGTH;

    let inputValidation = column.hasOwnProperty('inputValidation') ? column.inputValidation : DEFAULT_INPUT_VALIDATION;
    
    let timer;

    let filterField = (column.filterField) ? column.filterField : '';

    let mapper = (column.hasOwnProperty('filterParams')) ? ( column.filterParams.hasOwnProperty('mapper') ) ? column.filterParams.mapper : null :  null; 

    const value = DataTableContextObj.getInputField(filterField);


    const [inputValue, setInput] = useState(value || "");

    const [tooltip, setToolTip] = useState(false);


    const inputHandler = (e:any) => {
        //DataTableContextObj.updateSearchFields[''] = '';
        var regex = new RegExp(inputValidation);
        if(regex.test(e.target.value)) {
            if(e.target.value.length < maxInputLen) {
                setInput(state => e.target.value)
            }
        }else {
            document.getElementById(`column-${column.key}`).classList.add("bounce");
            setTimeout(() => {
                document.getElementById(`column-${column.key}`).classList.remove("bounce");
            },1000)
        }
    }

    useEffect(() => {
        if(value) {
            
            setInput(state => value);

        } else if (value == undefined || value == null){
            setInput("");
        }
                 
     },[value])    

     useEffect(() => {
        
        let defaultFilterValue = (column.defaultFilterValue) ? column.defaultFilterValue : '';

        if(defaultFilterValue) {

      
            setInput(state => defaultFilterValue);

            DataTableContextObj.updateSearchFields(filterField, defaultFilterValue, column, false);
        }
                 
     },[])

     const onBlurHandler = (e:any) => {
        let filterField = (column.filterField) ? column.filterField : '';

            //searchHandler(filterField, e.target.value, column);
            let mappedValue = e.target.value;
            if(mapper) {
                mappedValue = mapper(e.target.value);
            }
            
            DataTableContextObj.updateSearchFields(filterField, mappedValue, column);
            if(e.target.value == ""){
                DataTableContextObj.setBulkActionChecked(false);
                DataTableContextObj.setSearchState((prevSearchState:any ) => {
                    return  {...prevSearchState, allCheckboxSelected:false};
                });
            }
     }

    const startSearchHandler = (e:any) => {
        
        
        clearTimeout(timer);

        if(e.key === 'Enter') {
            
            let filterField = (column.filterField) ? column.filterField : '';

            //searchHandler(filterField, e.target.value, column);
            let mappedValue = e.target.value;
            if(mapper) {
                mappedValue = mapper(e.target.value);
            }
            
            DataTableContextObj.updateSearchFields(filterField, mappedValue, column);
            if(e.target.value == ""){
                DataTableContextObj.setBulkActionChecked(false);
                DataTableContextObj.setSearchState((prevSearchState:any ) => {
                    return  {...prevSearchState, allCheckboxSelected:false};
                });
            }
        }else {
            timer = setTimeout(() => {
                setToolTip(prevState => true);
                setTimeout(() => {
                    setToolTip(prevState => false);
                },2000)
                //let filterField = (column.filterField) ? column.filterField : '';
                //DataTableContextObj.updateSearchFields(filterField, e.target.value, column);

            },3500)
        }
    }

    useLayoutEffect(() => {
        
        if(urlValue) {

            setInput(state => urlValue)

            DataTableContextObj.updateSearchFields(filterField, urlValue, column);

        }

   },[urlValue])   

   const renderTooltip = (props) => {
        return (<Tooltip id="button-tooltip" {...props}>
            Press enter to start search
        </Tooltip>);
    };

    return(
        <OverlayTrigger
              placement="top-start"
              trigger="click"
              show={tooltip}
              delay={{ show: 100, hide: 2000 }}
              overlay={renderTooltip}
            >
            <Form.Control size="sm" type="text" 
                id={`column-${column.key}`}
                onChange={inputHandler} 
                className="form-control"
                onKeyDown={startSearchHandler} 
                onBlur={onBlurHandler}
                value={inputValue}  
                placeholder={`Search ${column.title}`} />
        </OverlayTrigger>
    )
})


interface SearchFieldSelectProps {
    column: ColumnType;
    //searchHandler: (fieldName: string, fieldValue: string, column:columnType) => void;
}

const SearchFieldSelect: React.FC<SearchFieldSelectProps> =  React.memo(({ column  }) => {
    const [selectValue, setSelectValue ] = useState('');

    if(!column?.filterParams) return null;

    if(!column.filterParams.options) return null;
    
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    const location = useLocation();
    
    let uniqueClassName = getUniqueClassName(location, column.key);


    useEffect(() => {
        let defaultFilterValue = (column.defaultFilterValue) ? column.defaultFilterValue : '';

        if(defaultFilterValue) {

            setSelectValue(state => defaultFilterValue)

            DataTableContextObj.updateSearchFields(filterField, defaultFilterValue, column, false);
        }
                 
    },[])    
    

    const handleChange = (e:any) => {
        
        setSelectValue(state => e.target.value)
        let filterField = (column.filterField) ? column.filterField : '';

        DataTableContextObj.updateInputField(filterField, e.target.value, column);
        
        DataTableContextObj.updateSearchFields(filterField, e.target.value, column);
    }

    let filterField = (column.filterField) ? column.filterField : '';
    const value = DataTableContextObj.getInputField(filterField);

    
    useEffect(() => {
        
        setSelectValue(state => value)

    },[value])  
    
    return(
        <select onChange={handleChange} className="form-select form-select-sm" aria-label="Default select example" value={selectValue}>
            <option value="">All</option>
            {column.filterParams.options.map(function(row:any, index:any) {
                //let keys = Object.keys(row);
                let lowerClass = String(row.label).toLowerCase();
                
                return (<option className={`ow-${uniqueClassName}-${lowerClass} ${lowerClass}`} key={index} value={row.value}>{row.label}</option>)

            })}
        </select>
    )
})



interface IDassPaging {
    total: number;
    num_per_page: number;
    pages: Array<{
        _ign: number;
        row: object;
        page_state: string;
        _scan: number;
        _cnt: number }
    >;
    recordTotal?:number;
    recordsFilteredUpdateTime?:string;
    recordsTotalUpdateTime?:string;
    sortingEnabled?:boolean;
}


const oboeFetchPageStates = (_url:string, oboe_path:string, dataTableOption: DataTableOption, setData:any, id_field:any, DataTableContextObj, AppContextObj, setIsLoading) => {
    
    let idField = DataTableContextObj.searchState.dataTableOption.hasOwnProperty('id_field') ? DataTableContextObj.searchState.dataTableOption.id_field : null;

    const rowIds:any[] = [];

    let firstPageState = '';

    SpinnerShow();
    setIsLoading(p => true)

    let progressScanned = 0;
    let progressCnt = 0;
    let timeStamp: number;
    const pageStates: string[] = [];
    const records = [];

    
    oboe({ url: _url, headers: headerNoContentType }).node(`${oboe_path}`, function parseElement(pagesNode:any, path, object: IDassPaging[]) {
        //AppContextObj.setIsLoading(false);

        if (object[0].pages && !timeStamp && Date.now() - timeStamp > 500) {

            timeStamp = Date.now();

            for (const obj of object[0].pages) {

                if (obj._ign == null) {

                    pageStates.push(obj.page_state);


                } else if (obj._ign === 2 && obj.row) {

                    records.push(obj.row);


                } else if (obj._ign === 1) {

                    progressScanned = obj._scan;
                    progressCnt = obj._scan;
                }

                console.log("cnt/scanned: " + progressCnt + "/" + progressScanned);
            }

            setData((oldArray: any) => records);

            // TODO: here we should also update the pages states and update the progress counters so people can see progress
        }

      }).done(function( jsonData: IDassPaging ) {
    
        setIsLoading(p => false)

        for (const obj of jsonData.pages) {
            
            
            if (obj._ign == null) {
                
               
                pageStates.push(obj.page_state);
                


            } else if (obj._ign === 2 && obj.row) {
                
                records.push(obj.row);
                if(idField) {
                    rowIds.push(obj.row)
                }
                
                

            } else if (obj._ign === 1) {
                
                progressScanned = obj._scan;
                
                progressCnt = obj._scan;

            }
        }

        setData((oldArray: any) => records);
 
        SpinnerHide();
        
        TotalRecordShow(jsonData.total, DataTableContextObj.countLabel, DataTableContextObj.name);

        if(jsonData?.sortingEnabled != undefined && jsonData?.sortingEnabled != null && jsonData?.sortingEnabled == false){
            TotalWarningMessage(jsonData);
        }
        

          if (jsonData.total) {
         
            const totalPage = Math.ceil(jsonData.total / DataTableContextObj.searchState.limit);
            
            const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;
            const selectedRowsData = selectedRowIds //selectedRowIds.filter(element => rowIds.findIndex((row) => row[idField] == element[idField]) != -1);
            //below code will execute when there are no records but pagestate is there.
            if(records.length === 0 && jsonData.total > 0 && pageStates.length > 0) {

                firstPageState = pageStates[0];
                
                let _url = buildUrl(DataTableContextObj, { page_state: firstPageState });

                oboeFetchPage(_url, '*', setData, DataTableContextObj, setIsLoading);

                DataTableContextObj.setSearchState((prevSearchState:any ) => {

                    return  {...prevSearchState, pageStates: [...pageStates], allRowIds:[...rowIds], page: '', loading:false, queryType:'', selectedRowIds:[...selectedRowsData]};

                })

                
            }else if(pageStates.length > 0  && totalPage > 0 && records.length > 0) {

                if(firstPageState !== '') {

                    DataTableContextObj.setSearchState((prevSearchState:any ) => {
                         return  {...prevSearchState, pageStates: [...pageStates], allRowIds:[...rowIds], page: firstPageState, loading:false, queryType:'', selectedRowIds:[...selectedRowsData]};
                     })

                }else {
                   
                    DataTableContextObj.setSearchState((prevSearchState:any ) => {
                        return  {...prevSearchState, pageStates: [...pageStates], allRowIds:[...rowIds], page: '', loading:false, queryType:'', selectedRowIds:[...selectedRowsData]};
                    })
                }
            }

        }else {

            DataTableContextObj.setSearchState((prevSearchState:any ) => {
                return  {...prevSearchState, pageStates: [], allRowIds:[], page: '', loading:false, queryType:'', selectedRowIds:[]};
            })
        }

         setTimeout(() => {
            DataTableContextObj.resizeCalJs(dataTableOption.columns);
         }, 0)


        ///DataTableContextObj.setDataLoadedHandler()

      }).fail( function( errorReport ) {

        SpinnerHide();
        setIsLoading(p => false)
        
        DataTableContextObj.setSearchState((prevSearchState:any ) => {
            return  {...prevSearchState, loading:false, queryType:''};
        })
        
        console.log(errorReport); 

        if(errorReport.body === 'Unauthorized') {
            
           window.location.href = appBaseUrl + '/signout';
        }

        if(errorReport.statusCode == 402){
            toast.warning(errorReport.body)
        }
        if(errorReport.statusCode == 400){
            toast.error(errorReport.body)
        }
        if( 404 == errorReport.statusCode ){
           console.error('no such content'); 
        }
     });
}




const oboeFetchPage = (_url:string, oboe_path:string, setData:any, DataTableContextObj, setIsLoading) => {

    let idField = DataTableContextObj.searchState.dataTableOption.hasOwnProperty('id_field') ? DataTableContextObj.searchState.dataTableOption.id_field : null;
    SpinnerShow();

    let rowIds:any[] = [];

    let lastTime;
    setIsLoading(p => true)

    oboe({ url: _url, headers: headerNoContentType }).node(`${oboe_path}`, function parseElement(pagesNode:any, path, object: any) {

        // here we only want the full objects
        if (path.length > 1) { return; }

        if (!lastTime || Date.now() - lastTime > 500) {
            
            const records = [];
            
            const dataarray = object[0];
            
            for (const elem of dataarray) {
                if (elem._ign == null) {
                    records.push(elem);
                }
            }

            lastTime = Date.now();

            setData((oldArray:any) => records);

        }


      }).done(function( jsonData ) {

        setIsLoading(p => false)

        const records = [];

        for (const elem of jsonData) {
            
            if (elem._ign == null) {
                records.push(elem);
                if(idField) {
                    rowIds.push(elem)
                }
            }
        }

        setData((oldArray:any) => records);
        
        SpinnerHide();

        DataTableContextObj.setSearchState((prevSearchState:any ) => {
            return  {...prevSearchState, loading:false, allRowIds:[...rowIds]};
        })


        setTimeout(() => {

            DataTableContextObj.resizeCalJs(DataTableContextObj.searchState.dataTableOption.columns);

        }, 0)


      }).fail( function( errorReport ) {

        SpinnerHide();

        DataTableContextObj.setSearchState((prevSearchState:any ) => {
            return  {...prevSearchState, loading:false};
        })

        console.log(errorReport);
        if( 404 == errorReport.statusCode ){
           console.error('no such content');
        }
        if(errorReport.statusCode == 402){
            toast.warning(errorReport.body)
        }
     });
}

    const fetchUrlData =  async ( url: string ) => {
        
        const response = await GenericDassQuery(url);
        const data =  await response.data;
        return data;
    }

    const buildUrl = (DataTableContextObj, options: { [key: string]: string } ) => {
    
        const sort = DataTableContextObj.searchState.sort;
        const searchFields = DataTableContextObj.searchState.searchFields;
        const dataTableOption = DataTableContextObj.searchState.dataTableOption
    
        const searchUrl: {
            [key: string]: string;
        } = { ...options, ...dataTableOption.query_param, ...searchFields };    

        delete searchUrl.get_pages;
        searchUrl.limit = DataTableContextObj.searchState.limit;

        if (DataTableContextObj.searchState.page !== '' && DataTableContextObj.searchState.queryType !== 'sort' && !options.page_state) {            
            searchUrl["page_state"] = DataTableContextObj.searchState.page;
        }
    
        if ((!DataTableContextObj.searchState.page || DataTableContextObj.searchState.page === '') && !options.page_state) {
            searchUrl.get_pages = "true";
        }


        if (sort.sortField !== '' && sort.sortOrder !== '') {
            searchUrl[sort.sortField] = sort.sortOrder;
        } else if (dataTableOption.hasOwnProperty('defaultSortField') != '' &&  dataTableOption.hasOwnProperty('defaultSortOrder') != '') {
           searchUrl[dataTableOption.defaultSortField]  = dataTableOption.defaultSortOrder;
        }

        let query = "";
        for (const key of Object.keys(searchUrl || {})) {
            if (searchUrl[key] != null) {
                query += (query ? "&" : "?") + key + "=" + encodeURIComponent(searchUrl[key] + "");
            }
        }
        
        return dataTableOption.url + query;
}



interface DataTableBodyProps  {
    //setRadioCheckedHandler?: (id: string, checked: boolean) => void;
}

const DataTableBody: React.FC<DataTableBodyProps> =  () => {
    
    let DataTableContextObj;
    const AppContextObj = useContext(AppContext);
    
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    const { state } = useLocation();

    const bulkActionChecked = DataTableContextObj.bulkActionChecked 
    
    const searchState = DataTableContextObj.searchState
    
    const setSearchState = DataTableContextObj.setSearchState

    const modal = DataTableContextObj.modal;

    const [rawdata, setData] = useState<any>([...DataTableContextObj.data]);

    const [isLoading, setIsLoading] = useState<boolean>(false);


    let dataTableOption = searchState.dataTableOption;

    let stream = dataTableOption.query_param.stream;

    let id_field = dataTableOption.id_field;

    let oboe_path = '';

    if(DataTableContextObj.searchState.page !== '' ) {
        oboe_path = '*';
    }else {
        oboe_path = dataTableOption.oboe_path;
        //oboe_path = '*';
    }


    dataTableOption.query_param.limit = DataTableContextObj.searchState.limit;

    let _url = buildUrl( DataTableContextObj, {} );
//    let _url = buildUrlGetPageStates( DataTableContextObj);
    
    let dataTableTotalRecords = DataTableContextObj.searchState.totalRecords;
    
    if(AppContextObj.pageState.totalRecords !== dataTableTotalRecords && AppContextObj.pageState.totalRecords === 0) {
        AppContextObj.setPageState(prevState => {
            return {...prevState, totalRecords: dataTableTotalRecords}
        })
    }


    //Call after first load

    useEffect(() =>  {
        setTimeout(() => {
            DataTableContextObj.resizeCalJs(dataTableOption.columns);
        }, 0)
        
    },[])
    
    useEffect(() => {
        const name = DataTableContextObj.searchState.name;
        if((dataTableOption.hasOwnProperty('extraSearchProp') || pageHasGlobalSearchTagControl(name)) && document.getElementById("extra-search-box")) {
            ReactDOM.render(<DataTableSearchComponent DataTableContextObj={DataTableContextObj} extraSearchProp={dataTableOption.extraSearchProp} AppContextObj={AppContextObj} />, document.getElementById("extra-search-box"));
        }

      },[]);
    
    useEffect(function fetch () {
        ///uiapi/rest/nodes?all=true&get_pages=true&limit=5&stream=progress&sort_by_deveui=asc
        ///uiapi/rest/groups?all=true&get_pages=true&limit=5&stream=progress
        

        (async function() {

            if(stream === 'progress') {

                if (oboe_path == "pages.*") {

                    oboeFetchPageStates(_url, oboe_path, dataTableOption, setData, id_field, DataTableContextObj, AppContextObj , setIsLoading);


                } else {
                    
                    oboeFetchPage(_url, oboe_path, setData, DataTableContextObj, setIsLoading );
                }

            }

            else if(stream === 'websocket') {
                
                if(dataTableOption.dataLoader) {
                    dataTableOption.dataLoader();
                }

            } else if(stream === 'memory') {    
                ///do nothing on memory stream
                let data = DataTableContextObj.searchState.dataTableOption.data;
                let page = DataTableContextObj.searchState.page || 0;
                let limit = DataTableContextObj.searchState.limit;
                let start = ((page + 1) * limit) - limit;

                    let searchFields = DataTableContextObj.searchState.searchFields;
                    delete searchFields['search_tags'];
                    delete searchFields['limit']
                    let keys = Object.keys(searchFields);
                    
                    let fieldValue = searchFields[keys[0]]; 
                    let filteredData =  [...data];
                    let totalPages = Math.ceil(data.length / limit);

                    if (fieldValue) {
                        
                        filteredData = DataTableContextObj.memoryFilter(searchFields, filteredData)
                        totalPages = Math.ceil(filteredData.length / limit);
                        filteredData = filteredData.splice(start, limit);    
                         
                    } else {
                        filteredData = filteredData.splice(start, limit);
                    } 
                    

                    if (DataTableContextObj.searchState.queryType === 'sort') {
                        filteredData = DataTableContextObj.memorySort(DataTableContextObj.searchState.sort.sortOrder, DataTableContextObj.searchState.sort.sortField, [...filteredData]);
                    }
                   
                    setData((oldArray:any) => filteredData);

                    let pageStates = [];

                    for(let i = 0; i < totalPages; ++i ) {
                        pageStates.push(i);
                    }
    
                    DataTableContextObj.setSearchState((prevSearchState:any ) => {
                        return  {...prevSearchState, pageStates: [...pageStates], page: page, loading:false, allRowIds:[...filteredData]};
                    })

                //}
                
                
            } else if(stream === 'simple') {
                
                // SpinnerShow();
                setIsLoading(p => true)

                const responseData =  await fetchUrlData(_url);
                let data = responseData

                let page = DataTableContextObj.searchState.page || 0;

                let limit = DataTableContextObj.searchState.limit;

                let start = ((page + 1) * limit) - limit;
                let searchFields = DataTableContextObj.searchState.searchFields;
                delete searchFields['search_tags'];
                delete searchFields['limit']

                let keys = Object.keys(searchFields);
                
                let fieldValue = searchFields[keys[0]]; 

                let filteredData =  [...data];
                let totalPages = Math.ceil(data.length / limit);
                    if(fieldValue) {

                        filteredData = DataTableContextObj.memoryFilter(searchFields, filteredData)

                        totalPages = Math.ceil(data.length / limit);

                        filteredData = filteredData.splice(start, limit);    

                        
                        
                    }else {
                        filteredData = filteredData.splice(start, limit);

                    }
                    

                    if(DataTableContextObj.searchState.queryType === 'sort') {
                        filteredData = DataTableContextObj.memorySort(DataTableContextObj.searchState.sort.sortOrder, DataTableContextObj.searchState.sort.sortField, [...filteredData]);
                    }

                   
                    setData((oldArray:any) => filteredData);
                    SpinnerHide();
        
                    TotalRecordShow(responseData.length, DataTableContextObj.countLabel, DataTableContextObj.name);
                    let pageStates = [];

                    for(let i = 0; i < totalPages; ++i ) {
                        pageStates.push(i);
                    }
    
                    DataTableContextObj.setSearchState((prevSearchState:any ) => {
                        return  {...prevSearchState, pageStates: [...pageStates], page: page, loading:false, allRowIds:[...filteredData]};
                    })
                // setData((oldArray:any) => responseData);

                
            } else {
                const responseData =  await fetchUrlData(_url);

                setData((oldArray:any) => responseData);
            }

        })();

      }, [_url, DataTableContextObj.searchState.refresh])


      


     

      
    //let path = dataTableOption.path;

    let serial_number = dataTableOption.serial_number;

    
    const bulkActionIdHandler = (row: any, checked:boolean, multi:boolean, id_field:string) => {

        let searchState = DataTableContextObj.searchState;

        let selectedRowIds = searchState.selectedRowIds;
        
        if(checked === true) {
            if(multi) { /// multi means if it is checkbox then can have multiple 
                DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...selectedRowIds, row] } });
            }else {
                //executes only in case of radio button
                DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [row] } });
            }
            

        }else {
            //if uncheck is pressed exclude it then reassing it again
            let temp = selectedRowIds.filter((item) => {
                return item[id_field] !== row[id_field];
            })
            
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...temp] } });
            //use has unselect a checkbox so we need to uncheck the bulk action header checkbox un checked;
            //DataTableContextObj.setBulkActionChecked(prevState => false);
            
        }
    };

    const getDataFromLocalStorage = (key: string) => {
        try {
            const item = JSON.parse(localStorage.getItem(key)) || null;
            if(item) {
                return item;
            }
        } catch (e) {
            console.log(e.message, key, "'" + localStorage.getItem(key) + "'" );
        }
        return null;
    }

    useEffect(() => {
        let selectedDevices =  state?.hasOwnProperty("selectedDevices") ? state['selectedDevices'] : getDataFromLocalStorage('selectedDevices');
        let selectedFirmware =  state?.hasOwnProperty("selectedFirmware") ? state['selectedFirmware'] : getDataFromLocalStorage('selectedFirmware');
        if(selectedDevices && selectedDevices.length > 0 && DataTableContextObj.name == "my-select-devices") {
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...selectedDevices] } });
        }
        if(selectedFirmware && selectedFirmware.length > 0 && DataTableContextObj.name == "my-firmwares") {
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...prevSearchState, selectedRowIds: [...selectedFirmware] } });
        }
    },[]);

    const bodyRows = rawdata.map((row:any, index:number) => {
        return (
            <TableBodyRowItem 
                key={index}
                serial_number={serial_number} 
                index={index}
                bulkActionChecked={bulkActionChecked}
                bulkActionIdHandler={bulkActionIdHandler}
                searchState={searchState}
                setSearchState={setSearchState}
                modal={modal}
                row={row}
                rowNum={index}
            />
        )
    })

    
    if(rawdata.length === 0) {
            
        if(isLoading === false && dataTableOption.dataType !== 'memory') {
            return (<NoDataMsg />)
        }else {
            return null;
        }
            
        
    }else {
        return (
            <>{bodyRows}</>
        )
    }
}


const NoDataMsg = () => {
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    
    let totalColumns = DataTableContextObj.searchState.dataTableOption.columns.length + 1;
    
    const showButton = () => {
        if(DataTableContextObj.searchState.dataTableOption.emptyDataButton) {

            let pageButton = DataTableContextObj.searchState.dataTableOption.emptyDataButton;
    
            return ( <div style={{margin:'1px', float:'left'}}>
                        <Button size='sm' className="text-nowrap" title={pageButton.title} variant="dark" onClick={pageButton.action}>
                            {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />}
                            {pageButton.title}
                        </Button>
                    </div>)
        }else {
            return null;
        }
    }
    

    return (
        <tr  style={{cursor: 'auto'}}>
            <td colSpan={totalColumns} style={{height: '300px', borderBottom: '0px'}} id="empty-data-table-row">
                <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <div style={{display:'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                        <img src={NoDataImage} />
                        {ShowNoData(DataTableContextObj.searchState.dataTableOption.emptyDataMsg)}
                        <br />
                        {showButton()}
                    </div>
                </div>
            </td>
        </tr>
    )
}

const ShowNoData = ( emptyDataMsg ) => {
    let data  = (emptyDataMsg) ? emptyDataMsg: '<b>Sorry!</b> No data available!';
    return (
        <div className='mb-2' dangerouslySetInnerHTML={{__html: data}}>
        </div>
    )
}

interface TableBodyRowItemProps {
    bulkActionChecked: boolean;
    bulkActionIdHandler: (id: string, checked: boolean, multi:boolean, id_field:string) => void;
    searchState:any;
    setSearchState:(prevSearchStates: any) => void;
    modal?:boolean;
    serial_number: boolean;
    index: number;
    row:any;
    rowNum:number;
}

const TableBodyRowItem: React.FC<TableBodyRowItemProps> = React.memo(({ index, serial_number, bulkActionChecked, bulkActionIdHandler, searchState, setSearchState, modal, row, rowNum }) => {
    
    const [showColumn, setShowColumn] = useState(false);
    
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    
    let dataTableOption = DataTableContextObj.searchState.dataTableOption;
    
    const toggleShowColumn = () => {
        setShowColumn((oldShow) => {return !showColumn});
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', function(e) {
            setShowColumn(false);
        });

        
    }, [])

    let display = (showColumn) ? '' : 'none';

    //let displayMenu = (showMenu) ? '' : 'none';

    let totalColumns = dataTableOption.columns.length + 1;
    
    let rowNumber = index + 1;

    let key1;
    let key2;
    //let key3;

    
    key1 = ((rowNumber - 1) * 3);
    key2 = key1 + 1;
    //key3 = key2 + 1;
    

    let oddEvenClass = (index % 2 === 0 ) ? "odd" : 'even';

    let detailAction = null;
    dataTableOption.columns.map((column, index) => {
        if(column.detailLink && column.hasOwnProperty('detailPageNav')) {
            detailAction = column.detailPageNav;
        }
    })
    
    const onclickfunc = () => {  (detailAction) ? detailAction(row) : null };
    
    return (
        <>
            <tr key={key1} className={oddEvenClass} style={{cursor: (detailAction) ?  'pointer' : ''}}  >
                {serial_number === true && <td>{index + 1}</td>}
                <DataTableBodyRow 
                    row={row} 
                    rowNum={rowNum}
                    modal={modal} 
                    toggleShowColumn={toggleShowColumn} 
                    bulkActionChecked={bulkActionChecked}
                    bulkActionIdHandler={bulkActionIdHandler} 
                    showColumn={showColumn}
                    onclickfunc={onclickfunc}
                    />
            </tr>
            <tr style={{display: display, backgroundColor:'#ffffff'}}  key={key2}>
                <td colSpan={totalColumns} key="1">
                    <RenderHiddenFields row={row} />
                </td>
            </tr>
        </>
    )

})

interface RenderFilterPanelIconProps {
    toggleShowColumn: (boolean) => void;
    showColumn: boolean;
}

const RenderFilterPanelIcon: React.FC<RenderFilterPanelIconProps> =  React.memo(( props ) => {

    let icon  = (props.showColumn) ? faChevronCircleDown : faChevronCircleRight;

    const handleClick = (e) => {
        e.stopPropagation(); 
        props.toggleShowColumn(e)
    }

    return (
        <Dropdown show={props.showColumn}>
            <div onClick={(e) => { handleClick(e) }}>
                <RenderFontAwesomeIcon icon={icon} />
            </div>
        </Dropdown>
    )
    
})

interface RenderActionDropDownIconProps {
    toggleShowColumn: (boolean) => void;
    actions:any;
    showColumn: boolean;
}

const RenderActionDropDownIcon: React.FC<RenderActionDropDownIconProps> =  React.memo(( props ) => {
   
    let icon  = (props.showColumn) ? faChevronCircleDown : faChevronCircleRight;

        return (
            <Dropdown show={props.showColumn}>
               <div onClick={props.toggleShowColumn} className="RenderActionDropDownIconClass">
                    <RenderFontAwesomeIcon icon={icon} />
                </div>
            </Dropdown>
        )
})


interface RenderHiddenFieldsProps {
    row:any;
}

//this component rendres the filed that has gone behind the scrollbar so rending it below the row;
const RenderHiddenFields: React.FC<RenderHiddenFieldsProps> =  React.memo(( props ) => {
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    let  dataTableOption = DataTableContextObj.searchState.dataTableOption;
    //let actions = dataTableOption.actions;
    let columns = dataTableOption.columns; 
    // let searchState= DataTableContextObj.searchState;
    
    let row = props.row;
    //let prevType: string = '';
    // let responsiveColumns = DataTableContextObj.searchState.responsiveColumns;

    let visibleColumnsIndexArr = tableSettings.hiddenColumnsIndexArr;
    
    let columnIndex = 0;
    var keys = Object.keys( columns );

    let columnRowArray = [];
    // columnRowArray = columns.filter((item, index) => {
    //     return true;
    // })

    for(let i = 0; i < columns.length; ++i) {
        columnIndex = visibleColumnsIndexArr[i];
        columnRowArray.push(columns[keys[columnIndex]]);
    }
    

    let filteredColumn = columns.filter(item => {
        if(item.key !== 'bulk_action_checkbox' && item.key !== 'action_button' && item.key !== 'field_panel_collapse_icon') {
            return item;
        }
    })

    let column_vl:any = '';

    let columnDetail = filteredColumn.map(function(columnRow: ColumnType, index) {
        if(columnRow) {
            
            let toolTip = '';
            if(columnRow.render_icon !== undefined) {

                //dataAlign = "center";

                let icon  = columnRow.render_icon(row);
                
                if(columnRow.hasOwnProperty('render_tooltip')) {

                    toolTip = columnRow.render_tooltip(row)

                }

                column_vl = <RenderFontAwesomeIcon icon={icon} />;


            }else if(columnRow.render !== undefined) {

                
                column_vl = columnRow.render(row);


            }else {

                column_vl = row[columnRow.key];

            }

            return (
                <tr key={index} className={`panel-field-${columnRow.key}`} title={toolTip} style={{display: 'none'}}>
                    <td style={{width:'30%'}}>
                        <div style={{padding:5, fontWeight:'bold'}}>
                            {columnRow.title}:
                        </div>
                    </td>
                    <td style={{width:'70%'}}>
                        <div style={{padding:5}}>
                            {column_vl}
                        </div>
                    </td>
                </tr>
            )
        }else {
            return null;
        }
    })
        return (
            <div>
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <div style={{display:'flex', flexDirection: 'column'}}> 
                        <table>
                            <tbody>
                                {columnDetail}   
                            </tbody>
                        </table>
                    </div>
                    {/* <div style={{display:'flex', flexDirection: 'row'}}>
                        {list}
                    </div> */}
                </div>
            </div>
        )
})


interface RenderBulkActionChekcboxProps {
    bulkActionChecked: boolean;
    bulkActionIdHandler: (id:string, checked:boolean, multi:boolean, id_field:string) => void;
    row: any;
    id_field: string;
    selectedRowIds:any[];
    index:number;
}
 

const RenderBulkActionChekcbox: React.FC<RenderBulkActionChekcboxProps> =  React.memo(({ row, bulkActionChecked, bulkActionIdHandler, id_field, selectedRowIds, index }) => {
    
    const chkHandler = (e: any) => {

        e.stopPropagation();
        
        bulkActionIdHandler(row, e.target.checked, true, id_field);

    }

    let findIndex:any = "-1";
    
    if(selectedRowIds && selectedRowIds.length > 0) {
        findIndex = selectedRowIds.findIndex((selectedRow) => {return selectedRow[id_field] === row[id_field] })
    }
    return (
        <Form.Check  type={`checkbox`} id={`${row[id_field]}`} key={index} value={`${row[id_field]}`} checked={findIndex != "-1"} onChange={chkHandler} />
    )
})



interface RenderBulkActionRadioProps {
    bulkActionChecked: boolean;
    bulkActionIdHandler?: (row:any, checked:boolean, multi:boolean) => void;
    row: any;
    id_field: string;
    selectedRowIds:any[];
}
 

const RenderBulkActionRadio: React.FC<RenderBulkActionRadioProps> =  ({ row, bulkActionChecked,  id_field, bulkActionIdHandler, selectedRowIds }) => {
    
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        //bulkActionIdHandler(row.id, true);
        setChecked(bulkActionChecked)
    }, [bulkActionChecked])


    if(!bulkActionIdHandler) return null;

    const chkHandler = (e: any) => {

        bulkActionIdHandler(row, e.target.checked, false);
        setChecked(e.target.checked)
    }
    let findIndex:any = "-1";
    
    if(selectedRowIds && selectedRowIds.length > 0) {
        findIndex = selectedRowIds.findIndex((selectedRow) => {return selectedRow[id_field] === row[id_field] })
    }

    return (
        <input name="group1" type={`radio`} id={`${row[id_field]}`} value={`${row[id_field]}`} checked={findIndex != "-1" || checked} onChange={chkHandler} />
    )
}

interface RenderFontAwesomeIconProps {
    icon: any;
    size?: any;
    color?: any;
}

const RenderFontAwesomeIcon: React.FC<RenderFontAwesomeIconProps> = ( props ) => {
    try {
        let size = (props.size) ? props.size : 'sm';
        if(props.icon.prefix != null) {
            return (<FontAwesomeIcon icon={props.icon} size={size} />)
        }else {
            return null;
        }
        
    }catch (e) {
        return null;
    }
}

interface RenderActionDropDownProps {
    actions?: ActionType[];
    row:any;
    isAllFieldVisible:boolean;
    index: number;
}

const RenderActionDropDown: React.FC<RenderActionDropDownProps> =  React.memo(( props ) => {

    let actions = props?.actions;
    let row = props.row;
    let prevType: string = '';
    
    type CustomToggleProps = {
        children?: React.ReactNode;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
      };
   
      const CustomToggle = React.forwardRef(
        (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
          <a
            ref={ref}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.onClick(e);
            }}
          >
            {props.children}
          </a>
        )
      );
      
   

    if(actions && actions.length > 0) {
        const list = actions.map(function(action: ActionType, index: number) {
            
            
            if(action.type === 'action' && ((action.visible !== undefined &&  action.visible(row) === true ) || action.visible === undefined )) {
                prevType = action.type;
                return (<Dropdown.Item key={index} eventKey="1" onClick={(e) => { e.stopPropagation(); return (action.action) ? action.action(row) : undefined }}>
                        <div style={{display: 'flex', flexDirection :'row' }}>
                            {action.icon &&  <div style={{ padding: 5 }}><RenderFontAwesomeIcon icon={action.icon} /></div> }
                            <div style={{ padding: 5}}>{action.text}</div>
                        </div>
                        </Dropdown.Item>)

            }else if(action.type === 'separator' && prevType !== 'separator' &&  ((action.visible !== undefined &&  action.visible(row) === true ) || action.visible === undefined )) {
                prevType = action.type;
                return (<Dropdown.Divider key={index} />)

            }else {
                return null;
            }

            
        }) 

        const closePrevious = () => {
            document.body.click();

        }

            return (
                <Dropdown autoClose="outside">
                        <Dropdown.Toggle as={CustomToggle} variant="success" id={`dropdown-basic-${props.index}`} >
                            <div className="d-flex flex-grow-1 justify-content-center align-items-center p-3"  onClick={() => { closePrevious() }} >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu  align="end" title="Dropdown end" id={`dropdown-menu-align-end-${props.index}`} >
                            {list}
                        </Dropdown.Menu>
                </Dropdown>
            )
        //}

    }else {
        return null;
    }
})



interface RenderActionIconsProps {
    actions?: ActionType[];
    row:any;
}

const RenderActionIcons: React.FC<RenderActionIconsProps> =  React.memo(( props ) => {

    let actions = props?.actions;
    let row = props.row;
    let prevType: string = '';
    let totalRenderedIcons:number = 0; 0;
    actions = actions.filter((item) => item.type !== 'separator')
    if(actions && actions.length > 0) {
        const list = actions.map(function(action: ActionType, index: number) {
           
            
            if(index < MAX_ACTION_ICONS) {

                if(action.type === 'action_switch' && ((action.visible !== undefined &&  action.visible(row) === true ) || action.visible === undefined )) {

                    return (<div className="form-check form-switch" key={index}>
                                <input className="form-check-input" onClick={(e) => { e.stopPropagation(); return (action.action) ? action.action(row) : undefined }} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>)

                }else if(action.type === 'action' && ((action.visible !== undefined &&  action.visible(row) === true ) || action.visible === undefined ) && totalRenderedIcons <=  MAX_ACTION_ICONS) {
                
                    prevType = action.type;
                    ++totalRenderedIcons;

                    let iconProps = (action.iconProps) ? action.iconProps : {};
                    let variant = (iconProps.hasOwnProperty('variant')) ? iconProps['variant'] : 'outline-dark';
                    let size = (iconProps.hasOwnProperty('size')) ? iconProps['size'] : 'sm';

                    return (<div className="me-1" style={{display: 'flex', flexDirection :'row' }} title={action.text}  key={index}>
                                {action.icon &&  <Button className='btn-icon' size={size} variant={variant} onClick={(e) => { e.stopPropagation(); return (action.action) ? action.action(row) : undefined }}><RenderFontAwesomeIcon icon={action.icon} /></Button> }
                            </div>)

                }else if(action.type === 'separator' && prevType !== 'separator' &&  ((action.visible !== undefined &&  action.visible(row) === true ) || action.visible === undefined )) {
                    prevType = action.type;
                    return null;

                }else {
                    return null;
                }
            }else {
                return null; 
            }
        }) 
        
        return (
            <div style={{display: 'flex', flexDirection :'row', justifyContent :'left', alignItems :'center' }}>
                {list}
            </div>
        )
    }else {
        return null;
    }
        
       
})

const getCalcCharWidth = (column, remCellWidth   ) => {
    let chrWidth = 'auto';
    
    if(column.cellWidth) {

        if(column.cellWidthType) {
            

            chrWidth = ((remCellWidth / document.body.clientWidth) * column.cellWidth) + '%';
        }else {

            if(column.cellWidth === 'nowrap') {
                chrWidth = column.cellWidth;
            }else {
                chrWidth = (column.cellWidth * PIXEL_IN_CHAR) + 'px';
            }
            

        }

    }else {
        chrWidth = 'auto';
    }
    

    if(chrWidth === 'nowrap') {
        return { whiteSpace: 'nowrap', width: 'auto'};

    }else {
        return {width: chrWidth, whiteSpace: 'normal'};
    }
    
}


interface DataTableBodyRowProps  {
    row: any;
    rowNum:number;
    modal?:any;
    toggleShowColumn?:any;
    bulkActionChecked?:any;
    bulkActionIdHandler?:any;
    showColumn?:any;
    onclickfunc?:() => void;
}


const RenderColumn = ( { column, row, rowNum, getClickFunc }) => {

    try {
        
        // let cellWidth:number = 0;
        // cellWidth = column.cellWidth as number;
        
        let dataAlign: AlignType =  "align-items-start";


        if(column.filterType && column.filterType === 'boolean_toggle') {
            dataAlign = "justify-content-center";
        }

        if(column.type === 'boolean') {
            dataAlign = "justify-content-center";
        }

        if(column.hasOwnProperty("dataAlign")) {
            if(column.dataAlign === 'center') {
                dataAlign =  "justify-content-center"
            }else if(column.dataAlign === 'right') {
                dataAlign =  "justify-content-end";
            }
        }

        let toolTip:any = '';
        if(column.hasOwnProperty('render_tooltip')) {
            
            let toolTipSnippet = column.render_tooltip(row)
            
            if(toolTipSnippet) {
                const TempComp = ({ toolTipSnippet } ) =>  {
                    return <div dangerouslySetInnerHTML={{__html: toolTipSnippet}}></div>
                }
                toolTip = <TempComp toolTipSnippet={toolTipSnippet} />;
            }else {
                toolTip = null;
            }
            
        }

        let column_vl:any = row[column.key];

        if(column.hasOwnProperty('render')) {
            
            column_vl = column.render(row);

        }else if(column.hasOwnProperty('htmlRender')) {

            let htmlSnippet = column.htmlRender(row);
            
            column_vl = <div dangerouslySetInnerHTML={{__html: htmlSnippet}}></div>;

            
        }else if(column.hasOwnProperty('render_icon')) {
            
            let icon = column.render_icon(row);
            
            column_vl = <RenderFontAwesomeIcon icon={icon} />
        }
        
        let textBreak = '';
        let newClass = column?.extraClass || ''
        // let column_vl_length = (column_vl) ? column_vl.length : 0;
        // if(cellWidth > 0) {
        //     if(column.hasOwnProperty("cellWidthType")) {
                
        //         if(column_vl_length >  (cellWidth)) {
        //             toolTip = column_vl;
        //             textBreak = "text-break"
        //             //column_vl = elipsis(column_vl, cellWidth);
        //         }

        //     }else {
                
        //         if(column_vl_length >  (cellWidth * 3)) {
        //             toolTip = column_vl;
        //             textBreak = "text-break"
        //             //column_vl = elipsis(column_vl, cellWidth);
        //         }

        //     }
        // }        

        let flexRow = '';

        if(column?.copyLink === true) {
            flexRow = '`d-flex flex-row';
        }

        let func = getClickFunc(column);
        
        if(rowNum === 0) {
            
            return (<FieldCellWrapper column={column} row={row} toolTip={toolTip} key={column.key} dataAlign={dataAlign}>
                        <div style={{width : newClass ? column?.newCellWidth : "100% !important" }} onClick={func} className={`${flexRow}  ${textBreak} ${dataAlign} ${newClass}`} >{column_vl}</div>
                    </FieldCellWrapper>
                    )

        }else {

            return(<FieldCellWrapper column={column} row={row} toolTip={toolTip} key={column.key} dataAlign={dataAlign}>
                    <div style={{width : newClass ? column?.newCellWidth : "100% !important" }} onClick={func} className={`${flexRow} ${textBreak} ${dataAlign} ${newClass}`} >{column_vl}</div>
                </FieldCellWrapper>)

        }
    }  catch(e)  {
        return null;
    }
 }

const DataTableBodyRow: React.FC<DataTableBodyRowProps> =  React.memo(( { row, rowNum, modal , toggleShowColumn, bulkActionChecked, bulkActionIdHandler, showColumn, onclickfunc} ) => {
    
    let DataTableContextObj;
    if(globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }
    let dataTableOption = DataTableContextObj.searchState.dataTableOption;


    const hideBulkActions = dataTableOption.hasOwnProperty("hideBulkActions") ? dataTableOption['hideBulkActions'] : false;

    let columns = dataTableOption.columns;

    const searchState = DataTableContextObj.searchState;

    let isAllFieldVisible = DataTableContextObj.isAllFieldVisible();

    let visibleColumns = searchState.visibleColumns;

    let responsiveColumns = searchState.responsiveColumns;

    //get the columns in array that are behinde the right side
    let columnIndex = 0;
    var keys = Object.keys( columns );
    let columnRowArray = [];
    for(let i = 0; i < columns.length; ++i) {
        columnIndex = responsiveColumns[i];
        let v = columns[keys[columnIndex]];
        if(v) {
            columnRowArray.push(v.key);
        }
    }
    
    const getClickFunc = (column) => {
        
        if(column.customNavigation) {
            return () => { column.customNavigation(row) };
        }else {
            return  onclickfunc;
        }
    }

    // filter merged column so it can be itrate seperatly to generate output
    const mergdColumns = columns.filter(item => {

        return item.type === 'merged';

    })

    //generate output for merged columns

    const mergedColumns  = mergdColumns.map(function(column: ColumnType, index: number) {
        
        return (<div className="p-1" key={`${column.key}-${index}`}><RenderColumn column={column} row={row} rowNum={rowNum} getClickFunc={getClickFunc} /></div>)

    }) //end column loop
    
    
    const list = columns.map(function(column: ColumnType, index: number) {

        let pos = visibleColumns.indexOf(column.key);

        if(pos > -1) {
           
            let hidden =  "";

            let dataAlign: AlignType = "left";

            if(column.filterType && column.filterType === 'boolean_toggle') {
                dataAlign = "center";
            }
            if(column.type === 'boolean') {
                dataAlign = "center";
            }
            

            if(( (row[column.dbKey] !== undefined || row[column.key] !== undefined ) || column.type === 'bulk_action_checkbox' || column.type === 'action_button' || column.type === 'field_panel_collapse_icon' || column.type === 'text_with_tooltip') && column.type !== 'merged') {
                
                let detailColumnClass = '';
                
                if(column.customClass) {
                    detailColumnClass = column.customClass;
                }
                let tKey = index;
                
                if(dataTableOption.id_field) {
                    tKey = row[dataTableOption.id_field];
                }


                if(column.type === 'bulk_action_checkbox' ) {
                    if(dataTableOption?.bulkActions?.length > 0 || isMobile) {
                    return (<td align="center" className={`ps-1 ${column.key}`} key={index} >
                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                                        {DataTableContextObj.searchState.allowBulkActions &&  DataTableContextObj.searchState.dataTableOption?.bulkActions?.length > 0 && 
                                            <div style={{/* paddingLeft: 5*/}}> 
                                                {!modal && <RenderBulkActionChekcbox key={tKey} index={index} id_field={dataTableOption.id_field} row={row}  bulkActionChecked={bulkActionChecked} bulkActionIdHandler={bulkActionIdHandler} selectedRowIds={DataTableContextObj.searchState.selectedRowIds} />}
                                                {modal &&  DataTableContextObj.searchState.allowBulkActions === true && <RenderBulkActionRadio key={tKey} id_field={dataTableOption.id_field} row={row}  bulkActionChecked={bulkActionChecked} selectedRowIds={DataTableContextObj.searchState.selectedRowIds} bulkActionIdHandler={bulkActionIdHandler}  />}
                                            </div>}
                                    <div style={{width: 20, paddingLeft:10, cursor: 'pointer', display: isMobile ? 'block': 'none'}}>
                                        {/*{!searchState.smallScreen && <RenderActionDropDownV2 actions={dataTableOption.actions} row={row} />}*/}
                                        {/* {isAllFieldVisible === false && <RenderActionDropDownIcon showColumn={showColumn} toggleShowColumn={toggleShowColumn} actions={dataTableOption.actions}/>} */}
                                        <div className="RenderActionDropDownIcon">
                                            <RenderActionDropDownIcon key={tKey} showColumn={showColumn} 
                                                toggleShowColumn={toggleShowColumn} 
                                                actions={dataTableOption.actions} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            </td>)
                    }else {
                        return null;
                    }
                } else if(column.type === 'field_panel_collapse_icon'  && dataTableOption.dataType !== 'memory') {
                    if(dataTableOption?.bulkActions?.length > 0) {
                        return (<td  align="center"  className={`${column.key}`} key={index}>
                                <div style={{width: 20, cursor: 'pointer'}} className="RenderActionDropDownIcon">
                                    <RenderActionDropDownIcon key={index} showColumn={showColumn} toggleShowColumn={toggleShowColumn} actions={dataTableOption.actions} />
                                </div>
                            </td>)
                    }else {
                        return null;
                    }
                
                } else if(column.type === 'action_button' && hideBulkActions === false ) {
                    return (<td  key={index}  align="center"  className={`${column.key}`}>
                                <div className={`d-flex flex-row justify-content-center align-itemns-end `}>
                                    <div className={`d-flex flex-row justify-content-center align-items-center`}>{mergedColumns}</div>
                                    {/* {isAllFieldVisible === false && <RenderActionDropDown actions={dataTableOption.actions} row={row} isAllFieldVisible={isAllFieldVisible} /> } */}
                                    {/* {isAllFieldVisible === true && <RenderActionIcons actions={dataTableOption.actions} row={row}  />} */}
                                    <div className="RenderActionDropDown" style={{display: 'none'}}>
                                        <RenderActionDropDown key={index} index={index} actions={dataTableOption.actions} row={row} isAllFieldVisible={isAllFieldVisible} />
                                    </div>
                                    <div className="RenderActionIcons">
                                        <RenderActionIcons key={index} actions={dataTableOption.actions} row={row}  />
                                    </div>
                                    {/*searchState.smallScreen && <RenderActionDropDownIconRightColumn showColumn={showMenu} toggleShowColumn={toggleShowMenu} actions={dataTableOption.actions}/>*/}
                                </div>
                            </td>)
                
                } else {

                    if(rowNum === 0) {
                        var opts = {};
                        opts['onClick'] = getClickFunc(column);
                        opts['key'] = index;
                        opts['align'] = dataAlign;
                        let classNames = column.key;
                        if (detailColumnClass || hidden) {
                            classNames += ` ${detailColumnClass} ${hidden}`;
                            
                        }
                        opts['className'] = `${classNames}`;
                        let width = (column?.extraClass && column?.newCellWidth) ? column?.newCellWidth : 'auto';
                        return(<td {...opts} key={index} style={{width: width}}>
                                <RenderColumn key={tKey} column={column} row={row} rowNum={rowNum} getClickFunc={getClickFunc} />
                                </td>)
                    }else {
                        let width = (column?.extraClass && column?.newCellWidth) ? column?.newCellWidth : 'auto';
                        var opts = {};
                        opts['onClick'] = getClickFunc(column);
                        opts['key'] = index;
                        opts['align'] = dataAlign;
                        let classNames = column.key;
                        if (detailColumnClass || hidden) {
                            classNames += ` ${detailColumnClass} ${hidden}`;
                        }
                        opts['className'] = `${classNames}`;

                        return(<td {...opts} style={{width: width}}>
                                    <RenderColumn key={tKey} column={column} row={row} rowNum={rowNum} getClickFunc={getClickFunc} />
                                </td>)
                    }
                }
                
            }
            else if(column.type !== 'merged') {
                //return null;0
                return(<td onClick={getClickFunc(column)} key={index} className={`${column.key} `}></td>)

            } else {
                return null;
            }

         }else {
             return null;
         }

    }) //end column loop

    return (<>{list}</>)
})

const getUniqueClassName = ( location, columnKey ) => {
    let uniqueClassName = 'datatable-' + (String(location.pathname).replace(/\//,'')).replace(/\//g,'-') + '-' + columnKey;
    
    return uniqueClassName;
}

interface FieldCellWrapperProps {
    toolTip?:string;
    column:ColumnType;
    row:any;
    children:any;
    dataAlign?:string;
}

const generateColumnWrapperClassName = (location, column ) => {

    let uniqueClassName = getUniqueClassName(location, column.key);
    
    let detailColumnClass = '';
    
    if(column.detailLink) {        
        
        detailColumnClass = `ow-${uniqueClassName}`;

    }else if(column.hasOwnProperty('filterParams') && column.filterParams.hasOwnProperty('startField')) {
        
        detailColumnClass = `ow-${uniqueClassName}`;

    }else {
        
        detailColumnClass = `ow-${uniqueClassName}`;

    }

    return detailColumnClass;
}

const generateColumnClassName = (location, column ) => {

    
    let detailColumnClass = '';
    if(column.detailLink) {   
        
        detailColumnClass = `xdetail-link mr-2 x    xfont-monospace `;

    }else if(column.hasOwnProperty('filterParams') && column.filterParams.hasOwnProperty('startField')) {
        
        detailColumnClass = `font-monospace `;

    }else {
        
        detailColumnClass = `ow-${column.key} `;

    }

    return detailColumnClass;
}

const FieldCellWrapper: React.FC<FieldCellWrapperProps> = ( props ) => {
    
    const location = useLocation();
    let row = props.row;

    
    let detailColumnWrapperClass = generateColumnWrapperClassName (location, props.column ) ;

    let detailColumnClass = generateColumnClassName (location, props.column ) ;

    var element:any = document.getElementById('debug-checkbox');
    

    let classDebugTitle = '';
    
    if(element && element.checked) {
        classDebugTitle = detailColumnClass;
    }

    const handleCopy = () => {
        
        navigator.clipboard.writeText(row[props.column.key]);
        setCopied(old => true)
    }
    const [copied, setCopied] = useState(false)

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Copied
        </Tooltip>
    )

    const renderTitleTooltip = ( props ) => {
        return (
            <Tooltip  {...props}>
                {toolTip}
            </Tooltip>
        )
    }

    const hideTooltip = () => {
        setCopied(old => false)
    }
    
    let toolTip = props.toolTip;
    
    return (
        <div title={classDebugTitle} className={`d-flex flex-row ${props.dataAlign} ${detailColumnWrapperClass} `}>
            {toolTip && toolTip !== '' && <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTitleTooltip}
            >
            <div className={`${detailColumnClass}`}>
                {props.children}
            </div>
            </OverlayTrigger>}
            {(!toolTip || toolTip === '') && <div className={`${detailColumnClass}`}>{props.children}</div>}
            {props.column.copyLink && props.column.copyLink === true &&
             <OverlayTrigger
                placement="left"
                show={copied}
                trigger="click"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                onEntered={() => { setTimeout(() => { hideTooltip() }, 800) }}
                >
                <div className='copy-device ms-1' onClick={(e) => {e.stopPropagation(); handleCopy() }}>
                    <FontAwesomeIcon icon={faCopy} />
                </div>
            </OverlayTrigger>}
        </div>
    )

}//end function

interface pageStateProps {
    index: number;
    pageState:string;
}

interface PaginationProps {
    
}

const PaginationComponentsV2:React.FC<PaginationProps> = ( {  }) => {

    let DataTableContextObj;

    if(globalScope === 'child') {

        DataTableContextObj = useContext(DataTableChildContext);

    }else {

        DataTableContextObj = useContext(DataTableContext);

    }

    let pageStates = DataTableContextObj.searchState.pageStates;

    const dataTableOption  = DataTableContextObj.searchState.dataTableOption;


    let pagesInPagination = dataTableOption.hasOwnProperty('pagesInPagination') ? dataTableOption.pagesInPagination : PAGES_IN_PAGINATION;

    const [currentPageState, setCurrentPage ] = useState<pageStateProps>({pageState:pageStates[0], index:0});

    const pageChange = (pageState:any, index:number) => {
        
        DataTableContextObj.pageChangeHandler(pageState);

        setCurrentPage({pageState, index});
        
    }

    const totalPages = pageStates.length;

    useLayoutEffect(() => {
        
        setCurrentPage(prevState => { return {pageState:pageStates[0], index:0} });

    }, [pageStates.length])


    const curentIndex = currentPageState.index;

    let prevIndex  = curentIndex - 1;

    if(prevIndex < 0) {
        prevIndex = 0;
    }
    
    let nextIndex = curentIndex + 1;
    

    if(nextIndex  > totalPages ) {
        nextIndex = totalPages
    }
    
    let startIndex = curentIndex - pagesInPagination;

    if(startIndex < 0) {
        startIndex = 0;
    }   
    
    let lastIndex = 0;

    lastIndex = curentIndex + pagesInPagination; 
    
    // add pages at the end if less then defined
    let diff = (pagesInPagination * 2) - (lastIndex - startIndex);
    if(diff > 0) {
        
        lastIndex = lastIndex  + diff;
    }

    ///add the pages in the first if less then defined size
    if(lastIndex >  totalPages) {
        lastIndex = totalPages;
        let diff = (pagesInPagination * 2) - (lastIndex - startIndex);
        if(diff > 0) {
            startIndex = startIndex  - diff;
            startIndex  = (startIndex < 0) ? 0 : startIndex;
        }
    }

    let pages:any[] = [];


    for(let i = startIndex; i < lastIndex; ++i) {
        pages.push({pageState:pageStates[i], index:i});
    }

    let activeClass:boolean = false;

    //when button should be disabled
    //it should be disable when loading = true;

    const getButtonDisableState = ( type ) => {
        if(type === 'number') {
            //disable when only one or is loading
            return (DataTableContextObj.searchState.loading || totalPages < 2) ? true : false;
        }else if(type === 'first') {
            //disable when only one page or is loading or current page is < 1
            return (DataTableContextObj.searchState.loading || totalPages < 2 || curentIndex < 1) ? true : false;
        }else if(type === 'prev') {
            //disable when only one page or is loading or current page is < 1
            return (DataTableContextObj.searchState.loading || totalPages < 2 || curentIndex < 1) ? true : false;
        }else if(type === 'next') {
            //disable when only one page or is loading or nextindex is equal to totalpage
            return (DataTableContextObj.searchState.loading || totalPages < 2 || nextIndex === totalPages) ? true : false;
        }else if(type === 'last') {
            //disable when only one page or is loading or nextindex is equal to totalpage
            return (DataTableContextObj.searchState.loading || totalPages < 2 || nextIndex === totalPages) ? true : false;
        }else {
            //disable when only one page or is loading 
            return (DataTableContextObj.searchState.loading || totalPages < 2 ) ? true : false;
        }
    }
    

    const getCursorState = ( type  ) => {
        
        if(type === 'number') {
            return (DataTableContextObj.searchState.loading || totalPages < 2 ) ? '' : 'pointer'
        }else if(type === 'first') {
            return (DataTableContextObj.searchState.loading || totalPages < 2 || curentIndex < 1) ? '' : 'pointer'
        }else if(type === 'prev') {
            return (DataTableContextObj.searchState.loading || totalPages < 2 || curentIndex < 1) ? '' : 'pointer'
        }else if(type === 'next') {
            return (DataTableContextObj.searchState.loading || totalPages < 2 || nextIndex === totalPages) ? '' : 'pointer'
        }else if(type === 'last') {
            return (DataTableContextObj.searchState.loading || totalPages < 2 || nextIndex === totalPages) ? '' : 'pointer'
        }else {
            return (DataTableContextObj.searchState.loading || totalPages < 2 ) ? '' : 'pointer'
        }
    }

    let pageStateArr = [];

    pages.map(function(pageRow, index) {
        pageStateArr.push(pageRow.pageState)
    })
    
    let found = pageStateArr.includes(currentPageState.pageState);
    
    let linkDisplay = '';
    

    const pagesList = pages.map(function(pageRow, index) {

        let pageState = pageRow.pageState;
        let pageNum = pageRow.index;

        linkDisplay = (isMobile) ? 'none' : 'block';

        activeClass = false;
        
        if(currentPageState.pageState ===  pageState) {
            found = true;
            activeClass = true;
            linkDisplay = 'block';
        }else if (index === 0 && currentPageState.pageState === '' || found === false) {
            activeClass = true;
            found = true;
            linkDisplay = 'block';
        }

        return (
            <Pagination.Item disabled={getButtonDisableState('number')} style={{cursor: getCursorState('number'),display:linkDisplay}} key={index} active={activeClass} onClick={() => pageChange(pageState, pageNum)}>{pageNum + 1 }</Pagination.Item>
        )
    })
    
        return (
            <Pagination className="data-table-paginate">
                <Pagination.First disabled={getButtonDisableState('first')} onClick={() => pageChange(pageStates[0], 0)} style={{cursor: getCursorState('first')}} key={`first`}>
                    <FontAwesomeIcon icon={faAnglesLeft} />
                </Pagination.First>
                <Pagination.Prev disabled={getButtonDisableState('prev')} style={{cursor: getCursorState('prev')}} onClick={() => pageChange(pageStates[prevIndex], prevIndex)} key={`prev`} >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Pagination.Prev>
                {pagesList}
                <Pagination.Next disabled={getButtonDisableState('next')} style={{cursor: getCursorState('next')}} onClick={() => pageChange(pageStates[nextIndex], nextIndex)} key={`next`} >
                    <FontAwesomeIcon icon={faAngleRight} />
                </Pagination.Next>
                <Pagination.Last disabled={getButtonDisableState('last')} style={{cursor: getCursorState('last')}} onClick={() => pageChange(pageStates[totalPages - 1], totalPages - 1)} key={`last`} >
                    <FontAwesomeIcon icon={faAnglesRight} />
                </Pagination.Last>
            </Pagination>
        )
    //}
}
