import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
//import { Outlet  } from 'react-router-dom';

import { faTrashAlt, faRefresh, faAdd, faFileCertificate }  from '@fortawesome/pro-regular-svg-icons';


import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog, dialogDescription} from '../../components/Common';


import { TAB_TENANT_CERT, TAB_ADD_TENANT, TAB_TENANT_INFO } from '../../datatypes/tabsconstants'

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";

//import { AlertModal } from "./../../components/Modals/AlertModal";
import PageContent from "../PageContent";
import { appBaseUrl, getAppBase } from '../../utils/consts';


import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "../../../src/components/Common/DataTable/DataTypes";

import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { getVisibleActions } from '../../utils/filters';

declare const constants;

export interface IRowType {
    tenantid: string;
    name: string;
}

interface ITenantStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface ITenantProps {
}




const Tenants: React.FC<ITenantProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);



    const stateInit: ITenantStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: "Tenants", url:''}],
        refresh:false
    };

    const [state, setState] = useState<ITenantStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])


    const detailPageNav = ( navigate, tab, id, row) => {
        if(id) {
            navigate(`${getAppBase()}/tenants/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/tenants`}})
        }
    }

    const detailPageAdd = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/tenants/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/tenants`}})
    }




    const deleteTenant = async ( tenant: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a tenant account",
            description: "Delete '" + tenant.name + "'?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/tenants/" + tenant.tenantid, {
                    method: "DELETE",
                });
                toast.success(`Tenant ${tenant.name} succesfully deleted`);
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }


    const deleteTenants = async (selectedRows: IRowType[]) => {
        
        const tenantids = selectedRows.map((row) => {
            return row['tenantid'];
        })
        const tenantName = selectedRows.map((row) => {
            return row['name'];
        })

        if(tenantids && tenantids.length > 0) {
            const confirmDialogeSettings = {
                title:  `You are about to delete ${tenantids.length} tenant accounts`,
                description: await dialogDescription(tenantName),
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(tenantids.map((tenantId) => {
                        return GenericDassQuery("/rest/omc/tenants/" + tenantId, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable();
                        toast.success("Tenants successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

    const  getActions = () => {

        const actions: ActionType[] = [
            {
                type: "action",
                text: "Certificate",
                icon: faFileCertificate,
                action: (row: IRowType) => detailPageNav(navigate, TAB_TENANT_CERT, row.tenantid, row),
                visible: (row) => AppContextObj.user.omc_mng_network_tenants || AppContextObj.user.omc_edit_user_settings,
            },
            {
                type: "action",
                text: strings.TENANT_DELETE,
                icon: faTrashAlt,
                action: (row: IRowType) => deleteTenant(row),
                visible: (row) => AppContextObj.user.omc_mng_network_tenants,
            }
        ];
        const bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: strings.TENANTS_DELETE,
                icon: faTrashAlt,
                action: (selectedRows) => {  deleteTenants(selectedRows) },
                visible: () => AppContextObj.user.omc_mng_network_tenants,
            }
        ];

        return { actions, bulkActions };
    }



    const  initDataTable = () => {

        const { actions, bulkActions } = getActions();

        let columns: ColumnType[] = [
            {
                key: "name",
                type: "text",
                title: "Tenant",
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                newCellWidth: "150px",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },
                sortKey: "sort_by_name",
                sortable: true,
                detailLink:true,
                copyLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_TENANT_INFO, row.tenantid, row),
                customClass: 'font-monospace nowarp',
                cellWidth: 30
                
            },
            {
                key: "tenantid",
                type: "text",
                title: "Tenant UUID",
                sortKey: "sort_by_tenantid",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_tenantid',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                copyLink: true,
                // detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_TENANT_INFO, row.tenantid, row),
                customClass: 'font-monospace nowarp',
                cellWidth: 100,
                cellWidthType: "%"
                
            },
        ];

        let options: DataTableOption = {
            url:'/uiapi/rest/omc/tenants',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'tenantid',
            oboe_path: 'pages.*',
            available_key: 'deveui',
            columns,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)
        }

        return options;
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: strings.TENANT_TITLE,
                action: () => detailPageAdd(navigate, TAB_ADD_TENANT),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => AppContextObj.user.omc_mng_network_tenants
                
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }

    
    return (<PageContent
        name={`tenants`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Tenants`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}

export default Tenants;