import React, { useEffect, useState, useContext } from "react";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
declare const constants;
import { useNavigate, useParams } from 'react-router-dom';
import { faDatabase, faPaperPlane, faTrashAlt , faAdd,  faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_GROUP, TAB_GROUP_ACTIVITY, TAB_SEND_MULTICAST, TAB_APP_ASSIGNMENT } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption,  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { getVisibleActions } from "./../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
interface IGroupsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface IRowType {

   applications: string;
   appskey: string;
   comment: string;
   devaddr: string;
   fcnt: string;
   groupid: string;
   nwkskey: string;
   title: string;

}


const Groups: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.MY_DEVICES_GROUPS,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_MANAGE_GROUPS, url:''}],
        refresh:false
    };

    const [groupState, setGroupState] = useState<IGroupsStates>(stateInit)

    useEffect(() => {
        
        if(checkLogin(AppContextObj.user)) {
            setGroupState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[])

     const detailPageNav = ( navigate, tab, groupid, row) => {
        
        navigate(`${getAppBase()}/groups/${groupid}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/groups`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/groups/${tab}`, {state: {prevPageUrl: `${getAppBase()}/groups`}})
    }
    


    const groupBulkAction = ( navigate, tab, selectedIds ) => {
        
        const idArr = selectedIds.map((row) => {
            return row['groupid'];
        })

        navigate(`${getAppBase()}/groups/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr, prevPageUrl: `${getAppBase()}/groups`}})

    }

   const getActions = () => {

        let actions: ActionType[] = [{
            type: "action",
            text: strings.SEND_MULTICAST,
            icon: faPaperPlane,
            visible: () => !groupState.loggedUser?._readonly,
            action: (row) => detailPageNav(navigate, TAB_SEND_MULTICAST, row.groupid, row),
        }, 
        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_DATA,
            icon: faDatabase,
            action: (group) => detailPageNav(navigate, TAB_GROUP_ACTIVITY, group.groupid, group)
        },
        {
            type: "action",
            text: strings.DELETE_GROUP,
            visible: () => !groupState.loggedUser?._readonly,
            icon: faTrashAlt,
            action: (group) => deleteGroup(group)
        }]

        let bulkActions:BulkActionType[] = [
        
            {
                type: "action",
                text: strings.APP_ASSIGNMENT,
                icon: faPaperPlane,
                visible: () => !groupState.loggedUser?._readonly,
                action: async ( selectedIds ) => groupBulkAction(navigate, TAB_APP_ASSIGNMENT, selectedIds)
            },
            {
                type: "action",
                text: strings.DELETE_GROUP,
                visible: () => !groupState.loggedUser?._readonly,
                icon: faTrashAlt,
                action: (selectedIds) => deleteGroupInBulk(selectedIds)
            }
        ]

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteGroupInBulk =  async (selectedRows: IRowType[]) => {
        const groupIds = selectedRows.map((row) => {
            return row['groupid'];
        })
        if(groupIds && groupIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${groupIds.length} selected group(s)`,
                description: await dialogDescription(groupIds) + "",
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(groupIds.map((groupid) => {
                        return GenericDassQuery("/rest/groups/" + groupid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Bulk Groups deleted successfully");
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteGroup =  async (group) => {
        const confirmDialogeSettings = {
            title:  "Do you want to delete this group",
            description: group.groupid,
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/groups/" + group.groupid, { method: "DELETE" })).then((values) => {

                    refreshTable();
                    toast.success("Group successfully deleted");

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

   const initDataTable = () => {
		
        const {actions, bulkActions} = getActions();
        
        const optionFetcher = async () => {
            
            try {

                let pageUrl = `/rest/applications?all=true`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const data =  response.data;
                const arr = data.map(function(row) {
                    return {label:row.accountid, value:row.accountid}
                })
                return arr;

            }catch(e) {
                console.log(e);
            }

        }
        
		const columns: ColumnType[] = [
            {
                key: "groupid",
                type: "text",
                title: strings.GROUP_ID,
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, "group-info", row.groupid, row ),
                customClass: 'nowarp'
            }, {
                key: "title",
                title: strings.GROUP_TITLE,
                type: "text",
                inputValidation: "",
                filterable: true,
                filterField: 'search_title',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                }
            }, {
                key: "comment",
                title: strings.COMMENT,
                type: "text",
                inputValidation: "",
                filterable: true,
                filterField: 'search_comment',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                }
            },
        ];

        if (!groupState.loggedUser?.app_uuid) {
            // Add only the applitions column for non-application users.
            columns.push({
                key: "applications",
                title: strings.MY_DEVICES_APPLICATIONS,
                type: "text_with_tooltip",
                // render_tooltip: x => (x.applications).replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.applications, 'blue', `${getAppBase()}/my-apps/~tag~/edit-app`,{prevPageUrl:`${getAppBase()}/groups`,row: { accountid:0 }},"Applications")},
                // render: x => { return renderTags(x.applications, 'blue', null,null)},
                customNavigation: (x) => {
                    if(x.applications){return null} else {
                    detailPageNav(navigate, "group-info", x.groupid, x )
                }},
                filterable: true,
                filterField: "application",
                newCellWidth: "250px",
                filterType: "multiselect",
                filterParams: {
                    optionFetcher: optionFetcher,
                    data: [],
                    mapper: x => x,
                }
            });
        }


        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/groups',
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'groupid',
            oboe_path:'pages.*',
            available_key: 'groupid',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
	}
    
 

    const refreshTable = () => {

        setGroupState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: "Group",
                action: () => detailPageAddNav(navigate, TAB_ADD_GROUP ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => !groupState.loggedUser?._readonly,
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-groups" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={groupState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Groups`} 
            dataTableOption={initDataTable()} 
            refresh={groupState.refresh}>
        </PageContent>
    );

}


export default Groups;