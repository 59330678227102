import { strings } from "./../../services/Localization";

import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';

import { faList } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import {
    TAB_CREATE_LINKED_PROFILE_ACTION
    , TAB_PROFILE_EDIT_ACTION
    , TAB_ADD_DEVICE_PROFILE
    , TAB_ADD_SERVICE_PROFILE
    , TAB_ADD_CONNECTIVITY_PROFILE
    , TAB_ADD_ROAMING_PROFILE
    , TAB_ADD_QOS_PROFILE
    , TAB_ADD_CHANNEL_PROFILE
    , TAB_IMPORT_DEVICE_PROFILE
} from '../../datatypes/tabsconstants'


import { IRowType } from "./Profiles";

const detailPageNav = (navigate, tab, accountid, row, profileType) => {

    navigate(`${getAppBase()}/profiles/${profileType}/${accountid}/${tab}`, { state: row })
}

const addPageNav = (actionArgs) => {

    const { navigate, tab, profileType } = actionArgs;

    navigate(`${getAppBase()}/profiles/${profileType}/${tab}`)
}



const getButtonProps = (action: string, actionArgs, navigate) => {

    const { profileType } = actionArgs;
    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/profiles/${profileType}`;

    const tabTypeArr: {
        [action: string]: DetailPageActionType;
    } = {
        importprofile: {
            type: "tab",
            text: "Import Device Profile",
            icon: faList,
            key: TAB_IMPORT_DEVICE_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_IMPORT_DEVICE_PROFILE, profileType: 'device', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/vendors?get_schema=dassui,post`,
                backUrl: prevPageUrl,
            },
            EditObject: null
        },
        linkprofile: {
            type: "tab",
            text: strings.ADD_NEW_LINKED_PROFILE,
            icon: faList,
            key: TAB_CREATE_LINKED_PROFILE_ACTION,
            action: () => addPageNav({ navigate, tab: TAB_CREATE_LINKED_PROFILE_ACTION, profileType: 'device', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/${profileType}-profiles?get_schema=dassui,post,link`,
                backUrl: prevPageUrl
            },
            EditObject: { profile_type: profileType, __readonly: false },
        },
        device: {
            type: "tab",
            text: strings[`DEVICE_PROFILE`],
            icon: faList,
            key: TAB_ADD_DEVICE_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_DEVICE_PROFILE, profileType: 'device', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/device-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl
            },
            EditObject: null
        },
        service: {
            type: "tab",
            text: strings[`SERVICE_PROFILE`],
            icon: faList,
            key: TAB_ADD_SERVICE_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_SERVICE_PROFILE, profileType: 'service', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/service-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl
            },
            EditObject: null
        },
        connectivity: {
            type: "tab",
            text: strings[`CONNECTIVITY_PROFILE`],
            icon: faList,
            key: TAB_ADD_CONNECTIVITY_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_CONNECTIVITY_PROFILE, profileType: 'connectivity', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/connectivity-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl
            },
            EditObject: null
        },
        roaming: {
            type: "tab",
            text: strings[`ROAMING_PROFILE`],
            icon: faList,
            key: TAB_ADD_ROAMING_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_ROAMING_PROFILE, profileType: 'roaming', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/roaming-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl
            },
            EditObject: null
        },
        qos: {
            type: "tab",
            text: strings[`QOS_PROFILE`],
            icon: faList,
            key: TAB_ADD_QOS_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_QOS_PROFILE, profileType: 'qos', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/qos-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl
            },
            EditObject: null
        },
        channel: {
            type: "tab",
            text: strings[`CHANNEL_PROFILE`],
            icon: faList,
            key: TAB_ADD_CHANNEL_PROFILE,
            action: (row: IRowType) => addPageNav({ navigate, tab: TAB_ADD_CHANNEL_PROFILE, profileType: 'channel', prevPageUrl }),
            content: {
                type: 'SchemaModal',
                url: `/rest/channel-profiles?get_schema=dassui,post`,
                backUrl: prevPageUrl

            },
            EditObject: null
        }
    }

    return tabTypeArr[action];
}

const ProfileProps: DetailPagePropsType = {

    getBreadCrumbArr: (breadCrumbArgs) => {

        const { profileType, tabname } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/profiles/${profileType}`;

        let profileTypeUpper = profileType.toUpperCase();
        const crumbArray:BreadCrumbType[] = [
            { label: strings[`NAV_PROFILES_ITEM`], url: prevPageUrl },
            { label: strings[`NAV_${profileTypeUpper}_PROFILES`], url: prevPageUrl },
        ];
        if ([TAB_ADD_DEVICE_PROFILE,
            TAB_ADD_SERVICE_PROFILE,
            TAB_ADD_CONNECTIVITY_PROFILE,
            TAB_ADD_ROAMING_PROFILE,
            TAB_ADD_QOS_PROFILE,
            TAB_ADD_CHANNEL_PROFILE
        ].includes(tabname)) {
            crumbArray.push({ label: strings.ACTION_ADD, url: '' })
        } else if (tabname === TAB_IMPORT_DEVICE_PROFILE) {
            crumbArray.push({ label: strings.IMPORT_PROFILE, url: '' })
        } else if (tabname === TAB_CREATE_LINKED_PROFILE_ACTION) {
            crumbArray.push({ label: strings.ADD_NEW_LINKED_PROFILE, url: '' })
        }
        return crumbArray;
    },
    getActions: (actionArgs, navigate) => {

        const { profileType, tabname } = actionArgs;
        const tabArray: DetailPageActionType[] = [];
        
        if ([TAB_ADD_DEVICE_PROFILE,
            TAB_ADD_SERVICE_PROFILE,
            TAB_ADD_CONNECTIVITY_PROFILE,
            TAB_ADD_ROAMING_PROFILE,
            TAB_ADD_QOS_PROFILE,
            TAB_ADD_CHANNEL_PROFILE
        ].includes(tabname)) {

            tabArray.push(getButtonProps(profileType, actionArgs, navigate));

        } else if (tabname === TAB_IMPORT_DEVICE_PROFILE) {

            tabArray.push(getButtonProps("importprofile", actionArgs, navigate));

        } else if (tabname === TAB_CREATE_LINKED_PROFILE_ACTION) {

            tabArray.push(getButtonProps("linkprofile", actionArgs, navigate));

        }

        return tabArray
    }
}

export { ProfileProps };





const ProfileRowProps: DetailPagePropsType = {

    getBreadCrumbArr: (breadCrumbArgs) => {

        const row: IRowType = breadCrumbArgs.row;
        const { profileType } = breadCrumbArgs;

        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/profiles/${profileType}`;
        let profileTypeUpper = String(profileType).toUpperCase();
        let breadCrumb:BreadCrumbType[];
        if (row === null) {
            breadCrumb = [
                { label: strings[`NAV_PROFILES_ITEM`], url: prevPageUrl },
                { label: strings[`NAV_${profileTypeUpper}_PROFILES`], url: prevPageUrl },
            ]
        } else {
            breadCrumb = [
                { label: strings[`NAV_PROFILES_ITEM`], url: prevPageUrl },
                { label: strings[`NAV_${profileTypeUpper}_PROFILES`], url: prevPageUrl },
                
            ]
            if(row.profile_name){
                breadCrumb.push({ label: `${row.profile_name}`, url: '' })
            }
        }
        return breadCrumb;

    },
    getActions: (actionArgs, navigate) => {

        const row: IRowType = actionArgs.row;
        const profile_uuid = row.hasOwnProperty('profile_uuid') ? row.profile_uuid : (row.hasOwnProperty('id') ? row.id : null);
        const { profileType } = actionArgs;

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/profiles/${profileType}`;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.PROFILE_EDIT_ACTION,
                icon: faList,
                key: TAB_PROFILE_EDIT_ACTION,
                action: (row: IRowType) => detailPageNav(navigate, TAB_PROFILE_EDIT_ACTION, profile_uuid, { row: row }, profileType),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/${profileType}-profiles?get_schema=dassui,put${row.link_to_profile_uuid ? ",link" : ""}`,
                    backUrl: prevPageUrl
                },
                EditObject: { profile_uuid: profile_uuid, profile_type: profileType },
            },

        ]
        return actions;
    }
}

export { ProfileRowProps };