import React, { useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from 'react-dropzone'
import { strings, translate } from "./../../services/Localization";
import { Button  } from 'react-bootstrap';
import _  from 'lodash';
import { getAppBase } from "../../utils/consts";
import { DataTable, DataTableWrapper, PageHeader, SpinnerHide, SpinnerShow,  } from '../../components/Common';
// import PageContent from "../PageContent";

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';

import { 
	faRefresh, 
	faFileImport, 
	faFileEdit, 
	faRemove, 
	faCircleArrowRight, 
	faCloudArrowUp
} from '@fortawesome/pro-regular-svg-icons'


import ProgressBar from 'react-bootstrap/ProgressBar';

import { ActionType, BulkActionType, ColumnType, OptionType, DataTableOption } from '../../components/Common/DataTable/DataTypes';

import {  DataTableContext } from "../../components/Common/DataTable/DataTableState";

import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

declare const constants;

import { 
		loadRegDataService
		,refreshDevicesService
		,registerDevicesService
		,updateDevicesService
		,deleteDevicesService
		,clearDevicesService 
	} from "../../services/batch"

// import { IUser } from "src/dassTypes";

import { toast } from "../../utils/Toaster";

import { filter } from "../../utils/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext from "../../context/AppContext";

interface IBatchRegistrationStates {
    //user: IUser | null;
	user: any;
    devUiId: string;
    showDeviceList: boolean;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
	pageData:any[];
	regData:any[];
    dataTableOptions?:DataTableOption;
	breadCrumbArr: BreadCrumbType[];
	nameIndex:number;
	isLoading:boolean;
	allSelected:boolean;
	
}


const ProgressBarComponent = ( props ) => {
	return (<div className="w-100 float-left" >
				<ProgressBar now={props.value} label={`${props.value}% ${props.caption}`} />
			</div>);
}

const BatchRegistration = () => {
    
    const appContextObj = useContext(AppContext);
	const DataTableContextObj = useContext(DataTableContext);
	const formatDeveui = filter("formatDeveui");
	const sortColumn = filter('sortByColumn');
	const loggedInUserId = appContextObj?.user?.userid || "";

	let regDevProgress = "";
	let regDevProgressMax = 0;
	let regDevProgressCurrent = 0;
	let regDevProgressErrors = 0;
	let currentPage = 1;
	let itemsPerPage = 25;
	let tableOffsetIdx = 0;
	

	let StatusValues = {
		404: 'Device Not Found',
		0: 'Registered',
		1: 'Duplicate',
		2: 'Deleted',
		3: 'Not Found',
		4: 'Updated',
		ERROR:'Error',
		REGISTERED: 'Registered'
	}

	const BatchStateInit = {
		devUiId: '',
		showDeviceList: false,
		showAlertModal: false,
		editUserId: '',
		pageTitle: '',
		pageData: [],
		regData: [],
		user: {},
		selectedRows: {},
		allSelected: true,
		limit: 50,
		page: 0,
		totalPage: 0,
		breadCrumbArr: [
			{
				label: strings.NAV_DEVICES_ITEM,
				url:`${getAppBase()}/my-devices`
			},
			{
				label: "Batch Registration",
				url:''
			}
		],
		nameIndex: 0,
		isLoading: false
	}
    
	const [batchState, setBatchState ] = React.useState<IBatchRegistrationStates>(BatchStateInit);

	// get the current user from the appContext and trigger the reload of the page.
	useEffect(() => {
		if (!loggedInUserId) { appContextObj.signOut(); }

		setBatchState(prevState => {
			return {...prevState, user: appContextObj?.user }
		});

		setTimeout(() => {
			refreshDevices()
			changeTotal(0);
		}, 510)

	}, []);



	const getPageButtons = () => {
    
        const pageButtons:PageButtonType[] = [
            {
                title: strings.REFRESH,
                action: () => { refreshDevices() },
                type: 'button_with_icon',
                icon: faRefresh
            },
			{
                title: strings.REGISTER,
                icon: faFileImport,
				action: () => { registerDevices() },
				type: 'button_with_icon',
            },
			{
                type: "button_with_icon",
                title: strings.UPDATE,
                icon: faFileEdit,
				action: () => { 
					updateDevices() 
					console.log(currentPage,itemsPerPage,tableOffsetIdx);
					console.log(regDevProgress,sortColumn,formatDeveui, regDevProgressErrors,actionOn,selectedItems,registerDisable , allSelected, registered_devices  );
				},
            },
			{
                type: "button_with_icon",
                title: strings.DELETE,
                icon: faRemove,
				action: () => { deleteDevices() },
            },			
            {
                title: strings.CLEAR,
                action: () => { clearDevices() },
                type: 'button_with_icon',
				icon: faCircleArrowRight
            },

        ]

        return pageButtons;
    };

	
	let registered_devices = 0;
	
	let websoc = null;
	
	let pendingStatus = null;
	
	let duplicate = false;
	
	let actionOn = false;
	
	let allSelected = true;

	let selectedItems = [];

	let registerDisable;

	const validRegionsStr = "^EU868$|^US915$|^CN779$|^EU433$|^AU915$|^CN470$|^AS923$|^KR920$|^IN865$|^RU864$|"
                                + "^JP923$|^China779$|^Australia915$|^China470$|^INDIA$";
                                
	const validRegions = new RegExp(validRegionsStr, "i");
    
    	

	const allowedProperties = [
		"appeui",
		"joineui",
		"comment",
		"deveui",
		"devaddr",
		"device_status",
		"registration_status",
		"groups",
		"applications",
		"appkey",
		"appskey",
		"snwksintkey",
		"fnwksintkey",
		"nwksenckey",
		"expiry_time_downlink",
		"expiry_time_uplink",
		"lora_device_class",
		"last_reception",
		"join_nonce_cnt",
		"dl_fcnt",
		"ownerid",
		"nwkskey",
		"lora_major",
		"lora_rx_delay1",
		"lora_rx_delay2",
		"lora_fcnt_32bit",
		"lora_rx2_sf",
		"redundant_uplink_cnt",
		"max_allowed_dutycycle",
		"expected_avr_dutycycle",
		"activation",
		"options",
		"device_properties",
		"qos_class",
		"lora_location",
		"activated",
		"downlink_allowed",
		"tenant_id",
		"macversion",
		"regparamsrevision",
		"maxeirp",
		"RFRegion",
		"ul_fcnt",
	]

	const deviceProfiles = (array) => {
		if (array.includes("deveui") && ((array.includes("device_profile_uuid") && array.includes("service_profile_uuid")) ||
			(!array.includes("device_profile_uuid") && !array.includes("service_profile_uuid")))) {
			return true;
		} else if (!array.includes("device_profile_uuid") || !array.includes("service_profile_uuid") || !array.includes("deveui")){
			return false;
		} else {
			return false;
		}
	}

	// const tableIdx = (idx) => {
	//  	return (currentPage - 1) * itemsPerPage + tableOffsetIdx + idx;
	// };

	// const tableLength = () => {
	// 	return tableData != null ? tableData.length - tableOffsetIdx : 0;
	// };

	// const pageChangeCb = () => {
	// 	console.log("page change");
	// 	updateTablePage();
	// };
    const buildColumns = ( columnArray ) => {

        let columns = [];
        
        for(let i = 0; i <= columnArray.length; ++i) {
			if(columnArray[i]) {
				var obj = {};
				obj['key'] = columnArray[i];
				obj['type'] = columnArray[i];
				obj['title'] = columnArray[i];
				obj['sortable'] = true;
				obj['sortKey'] = columnArray[i];
				obj['newCellWidth'] = '150px'
				columns.push(obj)
			}
        }
        return columns;
        
    }

	const updateTablePage = ( table ) => {  
		
		let columnsHeader = table.data.slice(0, 1);
		
		//const user = batchState.user;

		
		//let dataWithoutHeader = table.data.slice(1, table.data.length - 1);
        let data = [];

        // for(let key in dataWithoutHeader) {
        //     let row = dataWithoutHeader[key];
        //     let rowObj = {};
            
		// 	for(let i = 0; i < row.length; ++i) {
        //         rowObj[columnsHeader[0][i]] = row[i];
        //     }
			
        //     data.push(rowObj)
        // }


        let headerArray = columnsHeader[0];

        let columns:ColumnType[] = [];

		columns.push({
            key: 'bulk_action_checkbox',
            type: "bulk_action_checkbox",
            title: 'Bulk Action',
            filterable: false,
            cellWidth: 3,
        });

		columns.push({
            key: 'status',
            type: "text",
            title: 'State',
            filterable: false,
            cellWidth: 3,
			customClass: "text-nowrap"
        });

        let moreColumns = buildColumns(headerArray);
		
		columns = [...columns, ...moreColumns];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
        });
       
        let actions:ActionType[] = [];
         
		let bulkActions:BulkActionType[] = [
			{
                type: "action",
                text: strings.REGISTER,
                icon: faFileImport,
				action: () => { registerDevices() },
            },
			{
                type: "action",
                text: strings.UPDATE,
                icon: faFileEdit,
				action: () => { 
					updateDevices() 
					console.log(currentPage,itemsPerPage,tableOffsetIdx);
					console.log(regDevProgress,sortColumn,formatDeveui, regDevProgressErrors,actionOn,selectedItems,registerDisable , allSelected, registered_devices  );
				}
            },
			{
                type: "action",
                text: strings.DELETE,
                icon: faRemove,
				action: () => { deleteDevices() },
            },			

		];

        let options: OptionType = {
            url:'default-url',
            query_param:{all:true, get_pages:true, limit: DEFAULT_RECORD_LIMIT, stream: 'memory', projection: "gatewayDashboardView"} as any,
            serial_number:false,
            id_field:'deveui',
            oboe_path:'pages.*',
            pagesInPagination:1,
            paginationWrapper: 'map-pagination-wrapper',
            enableDebug:false,
            dataType:'memory',
            data:data,
			allowBulkActions:true,
			allCheckboxSelected:false,
			hideBulkActions:true
        }

        let dataTableOptions: DataTableOption =  {
            actions: actions, 
            bulkActions: bulkActions,
            columns: columns, 
            ...options,
        };
        
        
        setBatchState(prevState => {
			let isLoading = prevState.isLoading;
			return {...prevState, dataTableOptions:dataTableOptions, isLoading:!isLoading, pageData:table}
        })
	}

	useEffect(() => {
		let table = batchState.pageData;
		if(table['data']) {

			let columnsHeader = table['data'].slice(0, 1);
			
			let dataWithoutHeader = table['data'].slice(1, table['data'].length);
			let statuses = table['status'].slice(1, table['status'].length);
			
			let data = [];
			columnsHeader[0].unshift('status')
			 
			for(let key in dataWithoutHeader) {
				let row = dataWithoutHeader[key];
				let rowObj = {};
				rowObj[columnsHeader[0][0]] = StatusValues[statuses[key]];
				for(let i = 1; i <= row.length; i++) {
					rowObj[columnsHeader[0][i]] = row[i - 1 ];
				}
				
				data.push(rowObj)
			}


			setBatchState(prevState => {
				let nameIndex = prevState.nameIndex;
				const dataTableOptions = prevState.dataTableOptions;
				++nameIndex;
				return {...prevState, dataTableOptions:{...dataTableOptions, data:data} , nameIndex:nameIndex,pageData:table}
			})
		}

	},[batchState.isLoading])

	const  initDataTable = () => {
		return batchState.dataTableOptions;
	}

	const setTableData = ( table ) => {
		
		updateTablePage( table );

	};

	var createWebSocket = (path, protocols) => {
		var protocolPrefix = (location.protocol === 'https:') ? 'wss://' : 'ws://';
		var ws = null;

		try {
			//console.log(protocolPrefix + location.host + path);
			ws = new WebSocket(protocolPrefix + location.host + path, protocols);
		} catch (e) {
			console.log(e);
		}
		return ws;
	}

	const startRegDataWebSocket = () => {
		
		if (websoc == null) {
			websoc = createWebSocket('/regdataws', ['json']);
			
			//console.log('creating web socdket', websoc);

			if (websoc == null) {
				return;
			}

			websoc.onopen = function () {
				console.log("socket open");
				websoc.send('Ping'); // Send the message 'Ping' to the server
			};

			websoc.onmessage = function (msg) {
				console.log("Socket message");
				var o = msg.data;
				console.log(msg, o);
				if (typeof o === "string") {
					o = JSON.parse(o);
				}

				if (typeof o.total === "number" && typeof o.completed === "number") {
					setTimeout(function () {
						var i;
						regDevProgressMax     = o.total;
						regDevProgressCurrent = o.completed + o.skipped;
						regDevProgressErrors  = o.errors;
						regDevProgress        = regDevProgressCurrent + "/" + regDevProgressMax;

						if (o.status && batchState.regData) {

							if (!batchState.regData['status']) {
								batchState.regData['status'] = [];
							}
							
							for (i = 0; i < o.status.length; i++) {
								if (typeof o.status[i].index === "number" && o.status[i].status != null) {
									batchState.regData['status'][o.status[i].index] = o.status[i].status;
								}
							}
						}

						if (o.status && pendingStatus) {
							for (i = 0; i < o.status.length; i++) {
								pendingStatus.push(o.status[i]);
							}
						}
					});
				}
			};

			websoc.onclose = function (msg) {
				console.log("Socket close");

				websoc = null;
			};
		}
	}

	const hasDuplicates = (array) => {
		return (new Set(array).size !== array.length)
	}

	const loadRegData = () => {
		// open the websocket
		if (!duplicate) {

			allSelected = true;

			startRegDataWebSocket();

			pendingStatus = [];

			loadRegDataService().then(
				function (data) {
					let regData = data;
					if (data && hasDuplicates(data.data.data[0])) {
						
						duplicate = true;
						console.log(strings.DUPLICATE_HEADERS);
						toast.error(strings.DUPLICATE_HEADERS);
						return;
					} else {
						// Apply any updates to the status that was received while still loading the data.
						
						// var i;
						// for (i = 0; i < pendingStatus.length; i++) {
						// 	if (typeof pendingStatus[i].index === "number" && pendingStatus[i].status != null) {
						// 		regData.status[pendingStatus[i].index] = pendingStatus[i].status;
						// 	}
						// }

						// pendingStatus = null;

						// tableOffsetIdx = 1;
                        
						setTableData(regData && regData.data);
						SpinnerHide();
					}
				},
				function (response) {
					
					setBatchState(prevState => {
						return {...prevState, pageData:null}
					})

					pendingStatus = null;

					setTableData(null);
				}
			);
		}
	}



	const changeTotal = (index) => {

		/*
				console.log("changeTotal");
				if (isNumber(regDevProgress)) {
					if (regData.selected[index] === false) {
						regDevProgress--;
					} else {
						regDevProgress++;
					}
				} else {
					regDevProgress.split("/");
					var change = parseInt(regDevProgress.split("/")[1]);
					if (regData.selected[index] === false) {
						change -= 1;
					} else {
						change += 1;
					}
					regDevProgress = change;
				}
		*/
	}



	const refreshDevices = () => {
		actionOn = true;
		regDevProgressCurrent = 0;
		regDevProgressErrors  = 0;
		
		SpinnerShow();
		showProgress(0, '');

		refreshDevicesService({ selected: batchState.regData && batchState.regData['selected']}).then(
			(response) => {
				actionOn = false;
				if (response.data === null) {
					regDevProgress = "";
					SpinnerHide();
				} else {

							
					let regDevProgressCurrent = response.data.completed + response.data.skipped;
					
					const processedPercent = regDevProgressCurrent / response.data.total * 100;

					const msg = `Completed`

					showProgress(processedPercent, msg);

					loadRegData();
				}
				//console.log("Got reponce from getstatus_devices");
			},
			(response) => {
				console.log("Failed regstatus_devices", response);
				SpinnerHide();
			}
		);

	};

	const checkDataType = (inputHeader, dataValues, validators, validatorProperties) => {
		const validatableProperties = Object.keys(validatorProperties);
		const errors = [];

		for (let i = 0; i < inputHeader.length; i++) {
			const property = inputHeader[i];
			
			if (!validatableProperties.includes(property)) {
				continue;
			}

			const validatorProperty = validatorProperties[property];
			const validator = validators[validatorProperty.type];

			validator.params = validatorProperty.params;
			const input = dataValues[i];
			if (!input) { continue; }	// skip empty fields
			

			const valid = validator.validate(input);
			let errorMessage = validator.errorMessage && validator.errorMessage(property, input);
			// console.log('input',input, 'errorMessage', errorMessage);
			if (!errorMessage) {
				errorMessage = `${strings.BATCH_FIELD}${property}${strings.BATCH_INVALID_VALUE}`;
			}

			if (!valid) {
				errors.push(errorMessage)
			}
		}

		return errors;
	}

	const validatorProperties = {
		"appeui": { type: "hex16" },
		"joineui": { type: "hex8" },
		"comment": { type: "string" },
		"deveui": { type: "hex8", required: true },
		"devaddr": { type: "hex" },
		"device_status": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"registration_status": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"groups": { type: "string" },
		"applications": { type: "string" },
		"appkey": { type: "hex16" },
		"snwksintkey": { type: "hex16" },
		"fnwksintkey": { type: "hex16" },
		"nwksenckey": { type: "hex16" },
		"appskey": { type: "hex16" },
		"expiry_time_downlink": { type: "range", params: { from: 1, to: 1000000, fromInclusive: true, toInclusive: true } },
		"expiry_time_uplink": { type: "range", params: { from: 1, to: 1000000, fromInclusive: true, toInclusive: true } },
		"lora_device_class": { type: "range", params: { from: 0, to: 2, fromInclusive: true, toInclusive: true } },
		"dl_fcnt": { type: "number" },
		"ul_fcnt": { type: "number" },
		"join_nonce_cnt": { type: "range", params: { from: 0, to: 0xffffff, fromInclusive: true, toInclusive: true } },

		"nwkskey": { type: "hex16" },
		"lora_major": { type: "zero" },
		"lora_rx_delay1": { type: "range", params: { from: 1, to: 5, fromInclusive: true, toInclusive: true } },
		"lora_fcnt_32bit": { type: "bool", required: true },
		"lora_rx2_sf": { type: "sfrange", required: true },
		"redundant_uplink_cnt": { type: "range", params: { from: 0, to: 6, fromInclusive: true, toInclusive: true } },
		"max_allowed_dutycycle": { type: "procents" },
		"expected_avr_dutycycle": { type: "procents" },
		"activation": { type: "activation" },
		"device_properties": { type: "properties" },
		"qos_class": { type: "range", params: { from: 0, to: 3, fromInclusive: true, toInclusive: true } },
		"lora_location": { type: "bool" },
		"activated": { type: "bool" },
		"downlink_allowed": { type: "bool" },
		"tenant_id": { type: "not_supported" },
		"device_profile_uuid": { type: "ProfileUUID", required: true },
		"service_profile_uuid": { type: "ProfileUUID", required: true },
		"options": { type: "options" },
		"tags": { type: "tags" },

/*
		"MACVersion": { type: "MACVersion", required: true },
		"RegParamsRevision": { type: "RegParamsRevision", required: true },
		"RFRegion": { type: "RFRegion" },
		"MaxEIRP": { type: "number", required: true },
*/

		// case-less versions
		"maxeirp": { type: "number", required: true },
		"regparamsrevision": { type: "RegParamsRevision", required: true },
		"macversion": { type: "MACVersion", required: true },
		"rfregion": { type: "RFRegion" },
	}

	const validators = {
		"hex8": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^[0-9a-fA-F]{16}$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_MUST_8;
			}
		},
		"string": {
			validate: function (arg) {
				return typeof arg === "string" || arg === null || arg === "";
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_MUST_BE_A_STRING;
			}
		},
		"hex16": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^[0-9a-fA-F]{32}$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_ERROR_MUST_16BIT;
			}
		},
		"hex": {
			validate: function (arg) {
				return typeof arg === "string" && arg.match(/^((0[xX][0-9a-fA-F]{1,8})|([0-9a-fA-F]{8}))$/) != null;
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_ERROR_MUST_32BIT;
			}
		},
		"range": {
			params: {
				fromInclusive: true,
				toInclusive: true,
				from: null,
				to: null
			},
			validate: function (arg) {
				let valid = true;
				arg = parseInt(arg);
				if (!arg && arg !== 0) {
					return false;
				}

				if (arg < this.params.from || arg > this.params.to) {
					return false;
				}

				if (this.params.from !== null) {
					valid = this.params.fromInclusive ? arg >= this.params.from : arg > this.params.from;
				}

				if (this.params.to !== null) {
					valid = this.params.toInclusive ? arg <= this.params.to : arg < this.params.to;
				}

				return valid;
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_VALID_VALIE_BETWEEN + `${this.params.from}` + strings.BATCH_TO + `${this.params.to}`;
			}
		},
		"not_supported": {
			validate: function () { return false },
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_VALID_IS_NOT_SUPPORTED;
			}
		},
		"bool": {
			validate: (arg:any) => {
				if (arg != null || arg != "") {
					return arg.toLowerCase() === "false" || arg.toLowerCase() === "true";
				}else {
					return "false";
				}
			},
			errorMessage: (header, data) => {
				return `${header}` + strings.BATCH_MUST_BE_BOOLEN;
			}
		},
		"number": {
			validate: (arg) => {
				let number = parseInt(arg);
				return isFinite(number);
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_CAN_ONLY_BE_A_NUMBER;
			}
		},
		"sfrange": {
			validate: (arg) => {
				return /^sf[7-9](bw125|bw250|bw500)?$|^sf1[0-2](bw125|bw250|bw500)?$/i.test(arg);
			},
			errorMessage: function (header, data) {
				return strings.BATCH_ALLOWED_VALUES_FOR + `${header}` + strings.BATCH_ALLOWED_VALUES_FOR_IS;
			}
		},
		"properties": {
			validate: (arg) => {
				return arg === "static" || arg === "mobile" || arg === "indoor" || arg === "outdoor" || arg === "static,indoor" || arg === "static,outdoor" || arg === "mobile,indoor" || arg === "mobile,outdoor";
			},
			errorMessage: function (header, data) {
				return strings.BATCH_ALLOWED_VALUES_FOR + `${header}` + strings.BATCH_ALLOWED_VALUES_FOR_STATIC;
			}
		},
		"activation": {
			validate: (arg) => {
				arg = arg.toLowerCase();
				return arg === "otaa" || arg === "abp";
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_OTAA_ABP;
			}
		},
		"procents": {
			validate: (arg) => {
				return arg >= 0 && arg <= 100;
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_0_9;
			}
		},
		"zero": {
			validate: (arg) => {
				if (arg != null && arg != "") {
					return arg == 0;
				}else {
					return  0;
				}
			},
			errorMessage:  (header, data) => {
				return `${header}` + strings.BATCH_0;
			}
		},
		"MACVersion": {
			validate: (arg) => {
				return arg === "1.1.1" || arg === "1.0.4" || arg === "1.0.3" || arg === "1.0.2" || arg === "1.0.1"
					|| arg === "1.0.0";
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_CAN_ONLY_VERSION;
			}
		},
		"RegParamsRevision": {
			validate: (arg) => {
				return arg === "A" || arg === "B" || arg === "RP002-1.0.0" || arg === "RP002-1.0.1" || arg === "RP002-1.0.2";
			},
			errorMessage: function (header, data) {
				return `${header}` + strings.BATCH_CAN_A_B;
			}
		},
		"RFRegion": {
			validate: (arg) => {
				return validRegions.test(arg);
			},
			errorMessage: (header, data) =>  {
				return `${header}` + strings.BATCH_CAN_EU_US_CH;
			}
		},
		"ProfileUUID": {
			validate: (arg) => {
				let pattern = new RegExp("[0-9a-zA-Z]{8}(-[0-9a-zA-Z]{4}){3}-[0-9a-zA-Z]{12}");
				return pattern.test(arg);
			},
			errorMessage: (header, data) => {
				return `${header}` + strings.BATCH_WRONG_FORMAT;
			}
		},
		"options": {
			validate: (arg) => {
				if (arg === null) {
					arg = "";
				}
				return arg.length < 9;
			},
			errorMessage: (header, data) => {
				return `${header}` + strings.BATCH_WRONG_FORMAT;
			}
		},
		"tags": {
			validate: (arg) => {
				try {
					const tagsObj = JSON.parse(arg)
					const tagsKeys = Object.keys(tagsObj);
					for (const key of tagsKeys) {
						const type = typeof tagsObj[key];
						if (type !== "string" && type !== "number") {
							return false;
						}
					}
					return true;
				} catch (e) {
					return false;
				}
			},
			errorMessage: (header, data) => {
				return `${header}` + " invalid JSON"; // strings.('BATCH_ERROR_MUST_32BIT');
			}
		}
	}

	const checkMandatoryFields = (inputHeader, validatorProperties) => {
		//const validatableProperties = Object.keys(validatorProperties);
		const errors = [];

		// if 'service_profile_uuid' and 'device_profile_uuid' are present in the headers,
		// that means that device with profiles are added and no further mandatory checks are needed
		if (inputHeader.includes('service_profile_uuid') || inputHeader.includes('device_profile_uuid')) {
			return true;
		}

		let bFlag = true;
		
		_.map(validatorProperties, function(value, key) {
			if (value.required &&
				!inputHeader.includes(key) &&
				(key != 'device_profile_uuid' && key != 'service_profile_uuid')) {

				errors.push(key);
				bFlag = false;
			}
		})

		// validatorProperties.map(function(value, key) {
		// 	if (value.required &&
		// 		!inputHeader.includes(key) &&
		// 		(key != 'device_profile_uuid' && key != 'service_profile_uuid')) {

		// 		errors.push(key);
		// 		bFlag = false;
		// 	}
		// });

		
		errors.map(function(key){
			console.log(key)

			toast.error(translate(strings.BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS,{field_name: key} ));
			// toast.error(strings.BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS);
		});

		return bFlag;
	}

	const registerDevices = ( ) => {
		let column = null;
		
		let column_num = 0;
		showProgress(0, "");
		const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;
		
		const user =  batchState.user;
		let regData =  batchState.pageData;


		//const table = regData.data;
        if(regData && regData['data']) {
			const selectedDataRows = regData['data'].map((row) => {
				if(selectedRowIds && selectedRowIds.findIndex((selectedRow) => selectedRow['deveui'] == row[0]) == -1){
					return false
				} else return true;
			})
			// copy header to lower case for easier checking
			const inputHeader = [];
			for (const h of regData['data'][0]) {
				inputHeader.push(h.toLowerCase());
			}
			
			const valid = inputHeader.every(property => {
				column_num++;
				//console.log('property',property, allowedProperties.includes(property));
				column = property;
				return allowedProperties.includes(property);
			});

			const bHasAllMandatoryFields = checkMandatoryFields(inputHeader, validatorProperties);

			//console.log('valid',valid);
			//console.log('bHasAllMandatoryFields',bHasAllMandatoryFields);

			if (!bHasAllMandatoryFields) {
				return;
			}
			
			console.log("user", user);
			if (regData['total'] - registered_devices > user.max_num_devices) {

				toast.error(strings.BATCH_REGISTER_MAX_DEVICES);
				
				return;
			}
			
			actionOn = true;
			
			regDevProgressCurrent = 0;
			
			regDevProgressErrors  = 0;

			if (!valid && column === "") {
				toast.error(translate(strings.BATCH_REGISTER_DEVICES_ERROR1,{ column_num }));
				// toast.error(strings.BATCH_REGISTER_DEVICES_ERROR1);
				return;
			} else if (!valid && !deviceProfiles(inputHeader)) {
				toast.error(strings.BATCH_REGISTER_DEVICES_ERROR2);
				return;
			} else if (!deviceProfiles(inputHeader)) {
				toast.error(strings.BATCH_REGISTER_DEVICES_ERROR3);
				return;
			}

			for (let i = 1; i < selectedDataRows.length; i++) {

				// if (regData['selected'][i] === true) {
					const errors = checkDataType(regData['data'][0].splice(1), selectedDataRows[i], validators, validatorProperties);
					
					console.log(errors);

					if (errors.length > 0) {
						errors.forEach((error) => {
							toast.error(strings[error]);
						});
						toast.error(`${errors.length}${strings.BATCH_ERROR_ROW}${i}${strings.BATCH_ERROR}`);
						return;
					}
			
			} 
			registerDevicesService({ selected: selectedDataRows}).then(
				(response) => {
					if (response.data.status === 400) {
						
						var index = response.data.error_registering_index;
						
						let message_error = response.data.message_error;

						toast.error(translate(strings.BATCH_REGISTER_DEVICES_SERVER_ERROR, { index,  message_error}));
					}
					loadRegData();
					
						actionOn = false;
                        console.log(response.data)
						const completed_devices = response.data?.completed;
						const skipped_devices = response.data?.skipped;
						const error_devices = response.data?.errors;
						const registered_device = (response.data?.total - (skipped_devices + error_devices))
						
						let regDevProgressCurrent = completed_devices + skipped_devices;
						
						console.log("response.data",  response.data);

						const processedPercent = regDevProgressCurrent / response.data.total * 100;

						const msg = `Selected ${completed_devices} of ${response.data?.total} ( Registered: ${registered_device}, Error: ${error_devices} )`

						showProgress(processedPercent, msg);

                        if(error_devices === 0) {
							toast.success(`Proccessed the selected ${completed_devices} of ${response.data?.total}, ${registered_device} registered succesfully and ${error_devices} finished in error `);
						} else {
							toast.error(`Proccessed the selected ${completed_devices} of ${response.data?.total}, ${registered_device} registered succesfully and ${error_devices} finished in error `);
						}
						console.log("Got reponce from register_devices");
				},
				(response) => {

					toast.error(strings.BATCH_ERROR_REGISTERING);
					console.log("Failed register_devices");
				}
			);
		} else {
			toast.error("No Devices to Register") // Fixme should like strings.XX
		}
	}


	const updateDevices = ( ) => {

		showProgress(0, "");
		const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;
		let regData =  batchState.pageData;

		if (regData && regData['data']) {

			const selectedDataRows = regData['data'].map((row) => {
				if(selectedRowIds && selectedRowIds.findIndex((selectedRow) => selectedRow['deveui'] == row[0]) == -1){
					return false
				} else return true;
			})

			for (let i = 1; i < selectedDataRows.length; i++) {

					const errors = checkDataType(regData['data'][0].splice(1), selectedDataRows[i], validators, validatorProperties);
					if (errors.length > 0) {

						errors.forEach((error) => {
							toast.error(strings[error]);
						});

						toast.error(`${errors.length}${strings.BATCH_ERROR_ROW}${i}${strings.BATCH_ERROR}`);
					
					}
			}

			updateDevicesService({ selected: selectedDataRows }).then(
				(response) => {
					
					if (response.data.status >= 400) {

						const index = response.data.error_registering_index;
						const message_error = response.data.message_error;
						toast.error(translate(strings.BATCH_UPDATE_DEVICES_SERVER_ERROR,{index,message_error}));
					}

					
					
					actionOn = false;

					const completed_devices = response.data?.completed;
					const skipped_devices = response.data?.skipped;
					const error_devices = response.data?.errors;
					const updated_devices = (response.data?.total - (skipped_devices + error_devices))
					
					let regDevProgressCurrent = completed_devices + skipped_devices;
					
					const processedPercent = regDevProgressCurrent / response.data.total * 100;

					const msg = `Selected ${completed_devices} of ${response.data?.total} ( Updated: ${updated_devices}, Error: ${error_devices} )`

					showProgress(processedPercent, msg);

					loadRegData();

					console.log("Got reponce from update_devices");
				},
				(response) => {
					toast.error(strings.BATCH_ERROR_UPDATE);
				}
			);
		} else {
			toast.error(" No Devices to Update ") // Fixme should like strings.XX
		}
	};


	const deleteDevices = ( ) => {
	
		const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;
		
		showProgress(0, "");
		let regData =  batchState.pageData;

		if (regData && regData["data"] ) {
			const selectedDataRows = regData['data'].map((row) => {
				if(selectedRowIds && selectedRowIds.findIndex((selectedRow) => selectedRow['deveui'] == row[0]) == -1){
					return false
				} else return true;
			})
			
			deleteDevicesService({ selected: selectedDataRows }).then(
				(response) => {
					loadRegData();

					const completed_devices = response.data?.completed;
					const skipped_devices = response.data?.skipped;
					const error_devices = response.data?.errors;
					const deleted_devices = (response.data?.total - (skipped_devices + error_devices))

					let regDevProgressCurrent = completed_devices + skipped_devices;
					
					const processedPercent = regDevProgressCurrent / response.data.total * 100;

					const msg = `Selected ${completed_devices} of ${response.data?.total} ( Deleted: ${deleted_devices}, Error: ${error_devices} )`

					showProgress(processedPercent, msg);

					if (error_devices === 0) {
						toast.success(`Proccessed the selected ${completed_devices} of ${response.data?.total}, ${deleted_devices} deleted succesfully and ${error_devices} finished in error `);
					} else {
						toast.error(`Proccessed the selected ${completed_devices} of ${response.data?.total}, ${deleted_devices} deleted succesfully and ${error_devices} finished in error `);
					}

					console.log("Got response from delete_devices");
				},
				(response) => {
					console.log("Failed delete_devices");
				}
			);
		} else {
			toast.error("No Devices to Delete") // Fixme should like strings.XX
		}
	};

	const clearDevices = () => {
		SpinnerShow()
		showProgress(0, '');

		clearDevicesService().then(
			function (response) {

            if( response.data !== null) {
				setBatchState(prevState => {
					let dataTableOptions = prevState.dataTableOptions;
					let nameIndex = prevState.nameIndex;
					++nameIndex;
					return {...prevState, nameIndex:nameIndex,pageData:[],regData:[],selectedRows:{},page:0,totalPage:0, dataTableOptions:{...dataTableOptions, data:[]}}
				});
				
				showProgress(100, 'cleared ');
				SpinnerHide()
				console.log("Got reponce from delete_devices");

			} else { 
				showProgress(100, "cleared"); 
				SpinnerHide()
				console.log(`${response.status} --> No data to clear`)
			}
			},
			function (response) {
				console.log("Failed delete_devices");
			}
		);
		registerDisable = false;
	};





	// const hideProgress = () => {
	// 	ReactDOM.render(<ProgressBarComponent value={0} />, document.getElementById("progress-bar"));
	// 	var element = document.getElementById("progress-bar");
	// 	element.classList.add("d-none");
	// }

	const showProgress = ( complete, caption  = 'uploading') => {
		var element = document.getElementById("progress-bar");
		if(element){
			element.classList.remove("d-none");
			ReactDOM.render(<ProgressBarComponent value={complete} caption={`${caption}`} />, document.getElementById("progress-bar"));
		}
		// if(complete >= 100) {
		// 	setTimeout(() => {
		// 		hideProgress();
		// 	},1000)
		// }
	} 

	// const getActions = React.useCallback(() => {
	// 	return [];
	// },[])

	const uploadFile = ( file ) => {

		const allowedFormats = ['csv', 'xls', 'xlsx', 'txt']; // Allowed file formats
		const fileExtension = file.name.split('.').pop().toLowerCase();

  		if (!allowedFormats.includes(fileExtension)) {
    		toast.error('Only CSV, XLS, XLSX, and TXT files format are allowed.');
    		return;
  		}

		var url = '/upload_registration_file'; // File upload web service path
	
		var formData = new FormData();
		formData.append("file", file);
	  
		var xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		
		xhr.setRequestHeader("x-csrf-token", constants._csrf);
		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				var complete = (event.loaded / event.total * 100 | 0);
				showProgress(complete, 'uploading')
				
			}
		};
	  
		xhr.onload = function () {
			if(xhr.status === 200) {
			console.log('DONE', xhr.status);
			SpinnerShow();
			refreshDevices();
			} else {
			  toast.error(xhr.response);
			  clearDevices();
			  SpinnerHide();
			}
		};
		
		//xhr.setRequestHeader('authorization', this.state.token);
		xhr.send(formData);
		registerDisable = true;
	}
	
	let display = 'block';
	
	return (
		!loggedInUserId ? <div/> :
		<div className="child-table-wrapper" >
			<div className="page-wrapper" > 
				<DataTableWrapper display={display}>
						<PageHeader name={`batch-registration-${batchState.nameIndex}`} breadCrumbArr={batchState.breadCrumbArr}  pageButtons={getPageButtons()} countLabel={`Devices`} />
					<div className="mx-0 d-lg-flex border-bottom border-2 mb-2 " >
						<div className="d-flex w-100 align-items-center justify-content-center p-2" >
							<FileDropZone uploadFile={uploadFile}/>
						</div>
				</div> 
				<div id="progress-bar" className="d-none px-2" style={{height:'1rem'}}></div>
				{batchState.hasOwnProperty("dataTableOptions") &&
					<DataTable 
						name={`batch-registration-${batchState.nameIndex}`}
						dataTableOption={initDataTable()} 
						display={display} countLabel={`Devices`} />
				}
				</DataTableWrapper>
			</div>
		</div>
	)
}

export default BatchRegistration;


const FileDropZone = ( props ) => {

	const [selectedFiles, setSelectedFile] = React.useState([]);

	const onDrop = React.useCallback((acceptedFiles) => {

		setSelectedFile(acceptedFiles.map((file) => {
			props.uploadFile(file);

			return Object.assign(file, {
				preview:URL.createObjectURL(file)
			})
		}))
	}, [])

	const {getRootProps, getInputProps} = useDropzone({onDrop})

	const selected_files = selectedFiles?.map( (file, index) => {
		return (<div key={index}>{file.path}</div>)
	})
	
	return (
		<div className="d-flex flex-1 w-100">
			<div className="d-flex align-items-center justify-content-center w-100" style={{border:'2px dashed'}}>
				<div {...getRootProps()} className="d-flex flex-column align-items-center justify-content-center w-100 py-3" >
					<input {...getInputProps()} className="w-100 h-100"></input>
					<div style={{paddingBottom:5}} > 
						<FontAwesomeIcon size="4x" icon={faCloudArrowUp} />
					</div>
					<div style={{paddingBottom:5}}>
						<Button variant="outline-dark">{`Choose CSV files to upload`}</Button><br/>
					</div>
					<div style={{paddingBottom:5}}>
						{selected_files.length > 0 && selected_files}
						{selected_files.length === 0 && `or drag and drop theme here`}
					</div>
					
				</div>
			</div>
			
		</div>		
	)
}
