import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { faRefresh }  from '@fortawesome/free-solid-svg-icons';
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { appBaseUrl, getAppBase } from '../../utils/consts';
import PageContent from "../PageContent";
import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";
import { dateTimeString } from "../../utils/filters";
import{ DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
// import { } from '../../datatypes/tabsconstants'

declare const constants;

// http://localhost:3000/nst/gateway-jobs/a33730ee-edc6-489c-9b92-b377a56e7112/job-details

interface IGatewayJobEvent {
    batchId: string;
    maxConcurrent: number;
    maxFailures: number;
    currentFailures: 0,
    batchJobType: string;
    startDate: string;
    endDate: string;
    handlingServerUuid: string;
    batchJobName: string;
    userLogin: string;
    tenantId: string;
    batchJobStatus: string;
}

interface IGatewayJobsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface IGatewayJobprops {
}


const GatewayJobs: React.FC<IGatewayJobprops> = (props) =>  {

    const { id } = useParams();
    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();

    type FilterOptionType = {
        label: string;
        value: string;
    }

    const stateInit: IGatewayJobsStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: "Gateway Jobs", url:''}],
        refresh:false
    };

    const [state, setState] = useState<IGatewayJobsStates>(stateInit)

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            } else {
                window.location.href = appBaseUrl + "/signin";
            }
        }
    },[])

    
    const detailPageNav = ( navigate, tab, batch_id, row) => {

        navigate(`${getAppBase()}/gateway-jobs/${batch_id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/gateway-jobs`}})
    }


    const  getActions = () => {

        const actions: ActionType[] = [];
        const bulkActions: BulkActionType[] = [];

        return { actions:actions, bulkActions:bulkActions };
    }



    const  initDataTable = () => {

        let actions;
        var pageButtons;

        actions = getActions();
       
        

        let filterStatusOptions: FilterOptionType[] = [
            { label: "Configuring",   value: "CONFIGURING" },
            { label: "Completed",   value: "COMPLETED" },
            { label: "Failed",       value: "FAILED" },
            { label: "Paused", value: "PAUSED" },
            { label: "Scheduled", value: "SCHEDULED" },
            { label: "Completed with Error", value: "COMPLETED_WITH_ERRORS" },
            { label: "Ready to schedule", value: "READY_TO_SCHEDULE" },
            { label: "Started", value: "STARTED" },
        ];

        let filterTypeOptions: FilterOptionType[] = [
            { label: "Firmware Update",   value: "FIRMWARE_UPDATE" },
            { label: "RSSI Scan",       value: "RSSI_SCAN" },
        ];

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 2,
                newCellWidth: "2%"
            },
            {
                key: "startDate",
                title: "Start Time",
                type: "text",
                newCellWidth: "180px",
                render: (x:IGatewayJobEvent) => dateTimeString(x.startDate),
                cellWidth: 15,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterable: true,
                filterField: "search_by_starttmst",
                filterType: 'date',
                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, "job-details", row.batchId, row ),
                filterParams: {
                    startField: "from_date",
                    endField: "to_date",
                    mapper: (x) => x && x.format()
                },
                // customClass: 'font-monospace nowarp'
            },

            {
                key: "batchJobType",
                type: "text",
                title: "Type",
                cellWidth: 10,
                render: (x:IGatewayJobEvent) => x.batchJobType === "FIRMWARE_UPDATE" ? "Firmware Update" : "RSSI Scan",
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: 'search_by_batch_job_type',
                filterType: 'select',
                sortable: true,
                sortKey: 'sort_by_batch_job_type',
                filterParams: {
                    mapper: (x) => x || undefined,
                    options: filterTypeOptions
                },
            },

            {
                key: "batchJobName",
                type: "text",
                title: "Description",
                cellWidth: 60,
                cellWidthType: "%",
                sortable: true,
                sortKey: 'sort_by_batch_job_name',
                filterable: true,
                filterField: 'search_by_batch_job_name',
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterType: 'text',
                filterParams: {
                    mapper: (x:IGatewayJobEvent) => x || undefined
                },
            },
            {
                key: "batchId",
                type: "text",
                title: "Batch ID",
                sortable: false,
                filterable: false,
                filterField: 'search_tenantName',
                filterType: 'text',
                render: (row) => {
                    if(row.batchId) {
                        return String(row.batchId).substring(0,16) + '...';
                    }else {
                        return '';
                    }
                    
                },
                render_tooltip:(row) => {
                    return row.batchId;
                },
                cellWidth: 10,
            },
            {
                key: "userid",
                type: "text",
                title: "Owner",
                cellWidth: 15,
                filterable: true,
                filterField: 'search_by_user',
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterType: 'text',
                filterParams: {
                    mapper: (x: IGatewayJobEvent) => x || undefined
                },
            }, 
            {
                key: "batchJobStatus",
                type: "text",
                title: "Status",
                cellWidth: 15,
                sortable: true,
                sortKey: 'sort_by_batch_job_status',
                filterable: true,
                filterField: 'search_by_batch_job_status',
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterType: 'select',
                filterParams: {
                    mapper: (x: IGatewayJobEvent) => x || undefined,
                    options: filterStatusOptions,
                },
                render: (x:IGatewayJobEvent) => filterStatusOptions.find(v => v.value === x.batchJobStatus).label
            },

        ];

        let options: OptionType = {
            url:'/uiapi/rest/omc/batches',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,
            serial_number: false,
            id_field: 'batchId',
            oboe_path: 'pages.*',
            available_key: 'userLogin',
            allowBulkActions: false,
        }

        return {...actions, columns:columns, ...options, ...pageButtons };
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }
    return (<PageContent
        name={`gateway-jobs`} 
        id={id} 
        tabname={'gateway-jobs`'}    
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Gateway Jobs State`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}

export default GatewayJobs;