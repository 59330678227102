import React, { useContext }  from "react";

import { Button, Dropdown } from 'react-bootstrap';

import { isMobile } from 'react-device-detect';

import { ColumnType } from './DataTable/DataTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageButtonType } from "src/datatypes/datatypes";
import { DataTableContext } from "../Common/DataTable/DataTableState";

import { DataTableChildContext } from "../Common/DataTable/DataTableChildState";

interface pageButtonProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    scope?: string;
}

const PageButtonsComponent: React.FC<pageButtonProps> = ({ pageButtons, columns, scope }) => {

    if(isMobile) {
        return <RenderPageButtonSmallScreen pageButtons={pageButtons} columns={columns} />;
    }else {
        return <RenderPageButton pageButtons={pageButtons}  columns={columns} scope={scope} />
    }

}

export { PageButtonsComponent }

// FIXME: fix the template

const RenderPageButton: React.FC<any> = ({ pageButtons, columns, scope }) => {

    // let visibleColumns = columns;
    // if (columns) {
    //     visibleColumns = columns.filter((item) => {
    //         return (item['enabledInColumn'] && item['enabledInColumn'] === true) ? true : false;
    //     })
    // }

    const buttonList = pageButtons.filter(pb => pb.visible ? pb.visible() : true).map(function(pageButton, index) {
        
        if(pageButton.type === 'button_with_icon') {
            return (
                <div key={index} style={{margin:'1px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="dark" onClick={pageButton.action}>
                    {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />}
                        {pageButton.title}
                    </Button>
                </div>
            );

        }else if(pageButton.type === 'button') {
            return (
                <div key={index} style={{margin:'1px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="outline-dark" onClick={pageButton.action}>
                    {pageButton.icon &&  <FontAwesomeIcon icon={pageButton.icon} />}
                    </Button>
                </div>
            );

        } else if(pageButton.type === 'button_with_text') {
            
            return (
                <div key={index} style={{margin:'1px', float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} variant="outline-dark" onClick={pageButton.action}>
                        {pageButton.title}
                    </Button>
                </div>
            );

        } else if(pageButton.type === 'column') {

            return ( <RenderColumnsButtons columns={columns} key={index} scope={scope}/>)

        }else if(pageButton.type === 'dropdown_only_icon') {
            
            return ( <RenderDropDownButton pageButton={pageButton}  key={index}  />)

        }else {
            return ( <RenderDropDownButton pageButton={pageButton}  key={index}/>)
        }
    })
    
    return buttonList;
}


interface RenderPageButtonSmallScreenProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    
}

const RenderPageButtonSmallScreen: React.FC<RenderPageButtonSmallScreenProps> = ({ pageButtons }) => {

    const buttonList = pageButtons.map(function(pageButton, index) {
        if(pageButton.type === 'button_with_icon' || pageButton.type === 'button') {
            return (
                <Dropdown.Item key={index} onClick={pageButton.action}> {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />} {pageButton.title}</Dropdown.Item>
            );
        } else if( pageButton.type === 'button_with_text') {
            return (
                <Dropdown.Item key={index} onClick={pageButton.action}>{pageButton.title}</Dropdown.Item>
            );
        }else {
            return null;
        }

    })


    const dropDownButtonList = pageButtons.map(function(pageButton, index) {

        if(pageButton.type !== 'button') {
            return (
                <div  key={index} style={{margin:'1px'}}>
                    <RenderDropDownButton pageButton={pageButton} />
                </div>
            );
        }else {
            return null;
        }

    })

    return (
            <div style={{display:'flex', flexDirection: 'row'}}>
                <div style={{margin:'2px'}}>
                    <Dropdown>
                        <Dropdown.Toggle  size='sm' variant="outline-dark" id="dropdown-basic">
                            More
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {buttonList}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    {dropDownButtonList}
                </div>
            </div>
            )
}


interface RenderColumnsButtonsProps {
    columns:  ColumnType[];
    setSearchState?:(prevSearchStates: any) => void;
    scope?:string;
}

const RenderColumnsButtons: React.FC<RenderColumnsButtonsProps> = ({ columns, setSearchState, scope }) => {
    
    //const [fields, setFields] = useState([]);
    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    let visibleColumns = DataTableContextObj.searchState.visibleColumns


    const toggleHideShow = ( columnKey ) => {

        let pos = visibleColumns.indexOf(columnKey);
        
        if(pos > -1) {
            if(visibleColumns.length > 1) {
                
                visibleColumns.splice(pos, 1);

                DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                    return  {...prevSearchState, visibleColumns:[...visibleColumns]};
                })
                
            }
        }else {

            DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                return  {...prevSearchState, visibleColumns:[...visibleColumns, columnKey ]};
            })

        }
    }
    if(!columns) {
        return null;
    }
  
    const filteredColumn = columns.filter((item) => {
        return (item.key !== 'bulk_action_checkbox' && item.key !== 'action_button' && item.hasOwnProperty('enabledInColumn'))
    })

    return (
        <Dropdown>
            <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic">
                Columns
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {filteredColumn.map(function(item, index) {
                    let checked = (visibleColumns.indexOf(item.key) > -1) ? true : false;
                    return (<div style={{fontWeight: 'bold', fontSize: '12px'}}  key={index} >
                            <div style={{display: 'flex',  flexDirection: 'row'}}>
                                <div style={{margin: 5}}>
                                    <input type="checkbox" name={item.key} value={item.key} checked={checked} onChange={() => { toggleHideShow(item.key)}} />
                                </div>
                                <div style={{margin: 5}}>
                                    {item.title}
                                </div>
                            </div>
                    </div>);
                    
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface RenderDropDownButtonProps {
    pageButton: PageButtonType;
    
}


const RenderDropDownButton: React.FC<RenderDropDownButtonProps> = ( props ) => {

    if(!props.pageButton.dropDownItems) return null;

    return (
        <div  style={{ float:'left', margin: '1px'}}>
            <Dropdown>
                <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic" title={props.pageButton.title}>
                    {props.pageButton.type === 'dropdown_only_icon' &&  <FontAwesomeIcon icon={props.pageButton.icon} />}
                    {props.pageButton.type === 'dropdown' && props.pageButton.title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.pageButton.dropDownItems.map(function(item, index) {
                        if (!Object.keys(item).includes("visible")) {
                            return (<Dropdown.Item onClick={ () => { item.action() } } key={index}>{item.title}</Dropdown.Item>); 
                        } else if (item.visible) {
                            return (<Dropdown.Item onClick={ () => { item.action() } } key={index}>{item.title}</Dropdown.Item>); 
                        } else { 
                            return null
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

