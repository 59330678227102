import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { faRefresh }  from '@fortawesome/free-solid-svg-icons';
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { appBaseUrl } from '../../utils/consts';
import PageContent from "../PageContent";
import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";
import { dateTimeString } from "../../utils/filters";
import { getAppBase } from "./../../utils/consts";
import { TAB_GATEWAY_CONFIGURATION } from '../../datatypes/tabsconstants'
import { GenericDassQuery } from "./../../services/BasicDassQueries";
import { dialog } from '../../components/Common';
import { IConfirmDialogeSettings } from "../../components/Common/ConfirmDialog"

declare const constants;
import{ DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { faInfoCircle}  from '@fortawesome/pro-regular-svg-icons';

interface IGatewayAuditTrailEvent {
    gatewayUuid: string;
    tenantId: string;
    timestamp: string;
    auditEvent: string;
    eventId: string;
    userLogin: string;
}


interface IGatewayAuditTrailStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
}

interface IGatewayAuditTrailProps {}

const GatewayAuditTrail: React.FC<IGatewayAuditTrailProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);

    const stateInit: IGatewayAuditTrailStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: "Gateway Audit Trail State", url:''}],
        refresh:false
    };

    const [state, setState] = useState<IGatewayAuditTrailStates>(stateInit)


    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])


    const detailPageNav = async ( navigate, tab, id, row ) => {
        if (id) {
            try {
                const gatewayStatus = await GenericDassQuery(`/rest/omc/gateways/${id}`)
                if (gatewayStatus.status === 200) {
                    navigate(`${getAppBase()}/omc-gateways/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/gateway-audit-trail`}})
                }
            } catch (e) {
                if(e.status === 404) {
                    // toast.error(`Gateway-ID ${id} is been removed from network`)
                    const confirmDialogeSettings: IConfirmDialogeSettings = {
                        title:  "Info",
                        description: `It appears that the gateway ${id} has been removed from the system.`,
                        actionLabel: 'OK',
                        cancelLabel: "",
                        faIcon: faInfoCircle
                        
                };
                await dialog(confirmDialogeSettings)
                }
                console.error(e.status);
            }
        } 
    }

    const  getActions = () => {

        const actions: ActionType[] = [];
        const bulkActions: BulkActionType[] = [];

        return { actions, bulkActions };
    }



    const  initDataTable = () => {

        let actions;
        var pageButtons;

        actions = getActions();
       
        // default filter 
        const emptyDate = {
            startDate:  new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
            endDate: new Date(Date.now()).toISOString(),
            key: 'selection'
        }

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 2,
                newCellWidth: "2%"
            },
            {
                key: "timestamp",
                title: "Time",
                type: "text",
                render: (x) => dateTimeString(x.timestamp),
                cellWidth: 35,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterable: true,
                newCellWidth: "180px",
                filterField: "from_date",
                filterType: 'daterange',
                defaultFilterValue:[emptyDate],
                filterParams: {
                    startField: "from_date",
                    endField: "to_date",
                    mapper: (x) => x && x.format()
                },
            },

            {
                key: "userLogin",
                type: "text",
                title: "User",
                cellWidth: 35,
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: 'search_user',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                customClass: 'font-monospace nowarp'
            },


            {
                key: "gatewayUuid",
                type: "text",
                title: "Gateway",
                cellWidth: 35,
                filterable: true,
                filterField: 'search_gateway',
                detailLink:true,
                detailPageNav: (row: IGatewayAuditTrailEvent) => detailPageNav(navigate, TAB_GATEWAY_CONFIGURATION, row.gatewayUuid, row),
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                customClass: 'font-monospace nowarp'
            },
            {
                key: "auditEvent",
                title: "Event",
                type: "text",
                cellWidth: 100,
                cellWidthType: "%",
                filterable: false,
                htmlRender: (x: IGatewayAuditTrailEvent) => x.auditEvent,
            },

        ];

        let options: OptionType = {
            url:'/uiapi/rest/omc/gateway-audittrail',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,
            serial_number: false,
            id_field: 'eventId',
            oboe_path: 'pages.*',
            available_key: 'userLogin',
            allowBulkActions: false,
        }

        // Object.assign(options, {
        //     extraSearchProp: {key:'search_tags', label:'', filterType: 'tags', populateUrl:'/uiapi/rest/omc/tags'}
        // });

        return {...actions, columns:columns, ...options, ...pageButtons };
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }
    return (<PageContent
        name={`gateway-audit-trail`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`GatewayAuditTrail State`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}

export default GatewayAuditTrail;