import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
//import { Outlet  } from 'react-router-dom';

import { faRefresh }  from '@fortawesome/free-solid-svg-icons';

import { faAdd, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog } from '../../components/Common';

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";

//import { AlertModal } from "./../../components/Modals/AlertModal";

import { appBaseUrl, getAppBase } from '../../utils/consts';


import { TAB_ADD_JOINSERVER, TAB_EDIT_JOINSERVER } from '../../datatypes/tabsconstants'
import PageContent from "../PageContent";

import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "../../../src/components/Common/DataTable/DataTypes";

declare const constants;


export const pageTitle = "Join Servers"
export const pageUrlPart = "join-servers";



export interface IRowType {

    joineui_range: string;
    name: string;
    type: "loracloud",

    url: string;
    private_cert: string;
    private_key: string;
    joinserver_cert: string;

    auth_string: string;
    ignore_joinserver_cert: boolean;
}




type FilterOptionType = {
    label: string;
    value: string;
}

interface IJoinServersStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    categoryEnum: FilterOptionType[];
}

interface IJoinServersProps {
    registry: "global" | "customer";
}




const JoinServersOrg: React.FC<IJoinServersProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);


    const stateInit: IJoinServersStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: pageTitle, url:''}],
        refresh:false,
        categoryEnum: [],
    };

    const [state, setState] = useState<IJoinServersStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])




    const detailPageNav = (tab: string, row?: IRowType) => {
        const id = row?.joineui_range;
        const part = (props.registry === "global" ? "sys-" : "") + pageUrlPart;
        if(id) {
            navigate(`${getAppBase()}/${part}/${id}/${tab}`, {state: {row: row, prevPageUrl:`${getAppBase()}/${part}`}});
        } else {
            navigate(`${getAppBase()}/${part}/${tab}`, {state: {row: row, prevPageUrl:`${getAppBase()}/${part}`}});
        }
    }


    const deleteRow = async ( param: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a Join server",
            description: "Delete '" + param.name + "' join server?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/joinservers/" + param.joineui_range + "?registry=" + props.registry, { method: "DELETE" });
                toast.success("Join server deleted");
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }

    const deleteRows =  async ( selectedRows: IRowType[] ) => {
        const jsIds = selectedRows.map((row: IRowType) => {
            return row.joineui_range;
        })
        if(jsIds && jsIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the selected join server(s)`,
                description: jsIds.join(", ").substring(0,67) + '...',
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(jsIds.map((range) => {
                        return GenericDassQuery("/rest/joinservers/" + range + "?registry=" + props.registry, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Join servers deleted succesfully");
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }







    const  getActions = () => {

        const actions: ActionType<IRowType>[] = [
            {
                type: "action",
                text: "Delete join server",
                icon: faTrashAlt,
                action: (tag) => deleteRow(tag),
                visible: (tag) => true,
            }
        ];
        const bulkActions: BulkActionType<IRowType>[] = [
            {
                type: "action",
                text: "Delete join servers",
                icon: faTrashAlt,
                action: (selectedRows) =>  deleteRows(selectedRows),
            }
        ];

        return { actions, bulkActions };
    }



    const  initDataTable = () => {

        const { actions, bulkActions } = getActions();
        const columns: ColumnType<IRowType>[] = [
            {
                key: "joineui_range",
                title: "Join EUI range",
                type: "text",
                sortKey: "sort_by_joineui_range",
                sortable: false,
                filterable: true,
                filterField: 'search_joineui_range',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
                detailLink: true,
                detailPageNav: (row) => detailPageNav(TAB_EDIT_JOINSERVER, row),
                customClass: "font-monospace"
            },

            {
                key: "name",
                title: "Name",
                type: "text",
                sortKey: "sort_by_name",
                sortable: false,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
            },
            {
                key: "type",
                title: "Type",
                type: "text",
                filterable: false,
            },
            {
                key: "url",
                title: "URL",
                type: "text",
                filterable: false,
            },
        ];

        const options: DataTableOption<IRowType> = {
            url: "/uiapi/rest/joinservers",
            query_param: {
                get_pages: true,
                limit: 20,
                stream: 'progress',
                registry: props.registry,
            } as any,
            serial_number: false,
            id_field: "joineui_range",
            oboe_path: "pages.*",
            available_key: "joineui_range",
            columns,
            actions,
            bulkActions
        }

        return options;
	}

    const refreshTable = () => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [

            {
                title: "Join Server",
                action: () => detailPageNav(TAB_ADD_JOINSERVER),
                type: 'button_with_icon',
                icon: faAdd,
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }

    return (<PageContent
        name={props.registry}
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`join servers`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)


}

export const JoinServers: React.FC<{}> = () => JoinServersOrg({ registry: "customer" });
export const GlobalJoinServers: React.FC<{}> = () => JoinServersOrg({ registry: "global" });
