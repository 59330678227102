import React, { /*useContext*/ } from "react";
import ReactDOM from 'react-dom';
//import AppContext from '../../context/AppContext'

import { Spinner } from 'react-bootstrap';

const ReactSpinner = React.memo(() => {
    return (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
            </Spinner>    
        </div>
    )
})

export { ReactSpinner  }

const SpinnerHide = () => {

    //ReactDOM.unmountComponentAtNode(document.getElementById("react-spinner"))

    let elem:any = [];
    elem = document.getElementsByClassName("react-spinner");
    if(elem) {
        for (var j = elem.length - 1; j >= 0; j--) {
            ReactDOM.unmountComponentAtNode(elem[j])
        };
    }


}
  
export { SpinnerHide }

const SpinnerShow =  () => {

    let elem:any = [];
    elem = document.getElementsByClassName("react-spinner");
    
    if(elem) {
        for (var j = elem.length - 1; j >= 0; j--) {
            ReactDOM.render(<ReactSpinner />, elem[j]);
        };
    }

    //ReactDOM.render(<ReactSpinner />, document.getElementById("react-spinner"));

 }
  
 export { SpinnerShow }


 
const TotalRecordRender = (props) => {

    return (<small><span className="text-info">All</span> ({props.totalRecords})</small>);
}



const TotalRecordHide = () => {

    ReactDOM.unmountComponentAtNode(document.getElementById("total-record-container"))

}
  
export { TotalRecordHide }

const TotalRecordShow =  (totalRecords, countLabel, name) => {

    let id = (name) ? name + '-record-count' : 'data-table-record-count';
    
    if(countLabel) {
        
        ReactDOM.render(<TotalRecordRender totalRecords={totalRecords} countLabel={countLabel} />, document.getElementById(id));
    }else {
        //ReactDOM.unmountComponentAtNode(document.getElementById(id))
    }
    
 }
  
 export { TotalRecordShow }

 const TotalWarningMessageRender = (props) => {
    const {rowData} = props;
    return (
        <div className="alert alert-info" role="alert">
        <h4 className="alert-heading">Sorting has been disabled !</h4>
        <p className="mb-0">{`Listing only first ${rowData?.total} devices, as there are ${rowData?.recordTotal} in total. `}<br/>
            <small  className="font-italic">{`Device count last refreshed at ${new Date(rowData?.recordsTotalUpdateTime)?.toLocaleTimeString()}, Data last refreshed at ${new Date(rowData?.recordsFilteredUpdateTime)?.toLocaleTimeString()}`} </small></p>
        </div>
);
}

 const TotalWarningMessage = (rowData) => {
    ReactDOM.render(<TotalWarningMessageRender rowData={rowData} />, document.getElementById("warning-message"));
 }
 

 export {TotalWarningMessage}