import React, {useContext, useLayoutEffect} from "react";
//import ReactDOM from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome } from '@fortawesome/free-solid-svg-icons';

import { BreadCrumbType } from '../../datatypes/datatypes';

import { Dropdown } from 'react-bootstrap';

import AppContext from '../../context/AppContext'
import { DataTableContext } from "./DataTable/DataTableState";
import { DataTableChildContext } from "./DataTable/DataTableChildState";

import { elipsis } from '../../../src/utils/filters';
import { BulkActionType } from "./DataTable/DataTypes";

interface BreadcrumbComponentProps {
    breadCrumbArr: BreadCrumbType[];
    showMain?:() =>  void;
    countLabel?: string;
    name?:string;
    isChildTable?: boolean | undefined;
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ( props ) => {
    const { isChildTable } = props;
    const AppContextObj = useContext(AppContext);
    let DataTableContextObj = useContext(DataTableContext);

    if(isChildTable) {
        DataTableContextObj = useContext(DataTableChildContext);
    } else {
        DataTableContextObj = useContext(DataTableContext);
    }
    var len = (props.breadCrumbArr.length - 1);
    const navigate = useNavigate();
    useLayoutEffect(() => {
        
        AppContextObj.setCountLabel(props.countLabel)

    },[props.countLabel])
    const { id, tabname  } =  useParams();
    const clearSelection = () => {
        DataTableContextObj.setSearchState(prev => {
            return {...prev, selectedRowIds :[] }
        })  
    }
    let recordCounterId = (props.name) ? props.name : 'data-table';
    const isNst = (String(location.pathname).indexOf('/nst'));
    const redirection = isNst != -1 ? "/nst/network_map" : "/app";
    const selectedRowIds = DataTableContextObj.searchState.selectedRowIds;

    const isDisplaySelectedRowsData = () => {
        if(DataTableContextObj.searchState?.allowBulkActions && 
             DataTableContextObj.searchState?.dataTableOption?.bulkActions?.length > 0 && 
             ((!id && !tabname) || (tabname &&  tabname == "view-data" || tabname == "job-details" || tabname == "group-activity"))){
            return true;
        }
        return false;
    }
    const hideBulkActions = DataTableContextObj.searchState.dataTableOption.hideBulkActions;
    const totalData = DataTableContextObj.searchState.dataTableOption.data;
            //below code will execute when there are no records but pagestate is there.
            return (
        <div className="d-flex flex-column flex-sm-row justify-content-start align-items-start" style={{ paddingTop:'10'}}>
            <div className="breadcrumb">
                <div className="active cursor-pointer" style={{cursor: "pointer"}}>
                    <FontAwesomeIcon icon={faHome} onClick={() => navigate(redirection)}/>
                </div>
                {props.breadCrumbArr.map(function(item, index) {
                    if(len ===  index && AppContextObj.pageState.totalRecords > 0 && props.countLabel ) {
                        return null
                        // return (<BreadcrumbComponentItem item={item} index={index}  key={index} child={<small>({AppContextObj.pageState.totalRecords} {props.countLabel})</small>} />)
                    }else {
                        return (<BreadcrumbComponentItem item={item} index={index}  key={index} />)
                    }
                })}
            </div>
            <div className="d-flex">
                {location.pathname == '/app/mydevice-register' ?
                <div className="ms-2 mt-1">
                    <small><span className="text-info">All</span> ({totalData ? totalData?.length : 0})</small>              
                </div> : 
                <div className="ms-2 mt-1" id={`${recordCounterId}-record-count`}></div>}
                {isDisplaySelectedRowsData() &&  selectedRowIds && selectedRowIds.length > 0 &&
                <>
                    <div className="ms-2 mt-1"><small>|&nbsp;
                        <span className="text-info">Selected</span> ({selectedRowIds?.length || 0}) &nbsp;| &nbsp; 
                        <a style={{cursor : "pointer"}} className="btn-link text-info" onClick={clearSelection}>Clear</a>
                    </small>
                    </div>
                    {!hideBulkActions &&
                    <div className="ms-2 mt-1 custom-dropdown d-flex">
                        |&nbsp;
                        <RenderBulkActionDropDown 
                            bulkActions={DataTableContextObj.searchState.dataTableOption.bulkActions} 
                            DataTableContextObj={DataTableContextObj}
                            selectedRowsData={selectedRowIds}
                        />
                    </div>
                    }
                </>
            }
            </div>
            <div style={{marginLeft:'5px'}}>
                <div className="react-spinner"></div>
            </div>
        </div>
    )
}

    interface RenderFontAwesomeIconProps {
        icon: any;
        size?: any;
        color?: any;
    }

    const RenderFontAwesomeIcon: React.FC<RenderFontAwesomeIconProps> = ( props ) => {
        try {
            let size = (props.size) ? props.size : 'sm';
            if(props.icon.prefix != null) {
                return (<FontAwesomeIcon icon={props.icon} size={size} />)
            }else {
                return null;
            }
            
        }catch (e) {
            return null;
        }
    }


    interface RenderBulkActionDropDownProps {
        bulkActions?: BulkActionType[];
        DataTableContextObj: any;
        selectedRowsData?: [];
    }

    const RenderBulkActionDropDown: React.FC<RenderBulkActionDropDownProps> =  React.memo(({ bulkActions, DataTableContextObj, selectedRowsData}) => {

        if(bulkActions && bulkActions.length > 0 ) {

            const list = bulkActions.map(function(action:BulkActionType, index: number) {
                if(action.type === 'action' && ((action.hasOwnProperty('visible') &&  action.visible() === true ) || action.hasOwnProperty('visible') === false )) {
                    return (<Dropdown.Item disabled={selectedRowsData && selectedRowsData.length > 0 ? false : true} key={index} eventKey={index} onClick={() => { return (action.action) ? action.action(selectedRowsData) : undefined }}>
                    <div style={{display: 'flex', flexDirection :'row', marginTop: 5, marginBottom: 5}}>
                        {action.icon &&  <div style={{ marginRight: 5, marginLeft: 5}}><RenderFontAwesomeIcon icon={action.icon} /></div> }
                        {action.text}
                    </div>
                </Dropdown.Item>)
                }else if(action.type === 'separator') {
                    return (<Dropdown.Divider key={index} />)
                }else {
                    return null;
                }
            }) 

                return (
                    <Dropdown   autoClose="outside">
                        <Dropdown.Toggle variant="dark" size="sm" id="builk-action-dropdown-components">
                            Bulk Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu  align="end" title="Dropdown end" id="dropdown-menu-align-end" >
                            {list}
                        </Dropdown.Menu>
                    </Dropdown>
                )

        }else {
        return (<div>Actions</div>);
        }
    
    })

interface BreadcrumbComponentItemProps {
    item:any;
    index:number;
    child?:JSX.Element;
}

const BreadcrumbComponentItem: React.FC<BreadcrumbComponentItemProps> = ( props ) => {
    const { item , index } = props;

    const navigate = useNavigate();
    
    let nav =  (item.url) ? (item.url === '-1') ? () => { navigate(-1) } : () => { navigate(item.url,{replace: false}) } : () => {};
    
    const comment = elipsis(item.comment, 50);

    return (
        <div onClick={  nav  } style={{cursor: 'pointer'}} className="active cursor-pointer" key={index}>
            {item.label} 
            {props.child}
            {item.comment && <small>{comment}</small>} 
        </div>
    )
}

export { BreadcrumbComponent };


